import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {Direccion} from '../../../../../modelos/Direccion';
import {Muestra} from '../../../../../modelos/Muestra';
import {CrmService} from '../../../../../servicios/crm.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {UsuariosComponent} from '../../../../usuarios/usuarios.component';
import {CajaMuestras} from '../../../../../modelos/CajaMuestras';
import {MediaMatcher} from '@angular/cdk/layout';

@Component({
  selector: 'app-detalle-muestra',
  templateUrl: './detalle-muestra.component.html',
  styleUrls: ['./detalle-muestra.component.css']
})
export class DetalleMuestraComponent implements OnInit {

  mobileQuery: MediaQueryList;
  private readonly _mobileQueryListener: () => void;

  public direccion: Direccion;
  public cajaSeleccionada: CajaMuestras;
  public muestra: Muestra;
  public muestraDireccion: number;

  constructor(public crmservice: CrmService,
              public snackBar: MatSnackBar,
              public dialogRef: MatDialogRef<UsuariosComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: MatDialog,
              changeDetectorRef: ChangeDetectorRef,
              media: MediaMatcher) {

    this.mobileQuery = media.matchMedia('(max-width: 750px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.direccion = this.data.direccion;
    this.cajaSeleccionada = this.data.cajaSeleccionada;
    this.muestra = this.data.muestra;

    this.muestraDireccion = this.cajaSeleccionada.muestras.findIndex(e => e.muestra._id === this.muestra._id);
  }

  ngOnInit() {
  }

}
