<div *ngIf="!clienteCargado || !crmservice.datosCargados" class="spinner-container">
  <mat-spinner class="spinner"></mat-spinner>
</div>

<div *ngIf="guardandoDatos" class="spinner-container">
  <mat-spinner class="spinner"></mat-spinner>
</div>

<div *ngIf="clienteCargado && crmservice.datosCargados" class="m-xl-5 m-lg-5 m-md-3 m-sm-3 m-2 mt-4">
  <div *ngIf="cliente != null">
    <h2 class="text-center text-md-start">
      [<span class="class-char" [ngStyle]="{ color: getColorClasificacion(cliente.clasificacion) }">{{
        getClasificacion(cliente.clasificacion)
      }}</span
      >] {{ cliente.razonSocial }}
    </h2>
    <div
      class="alert alert-danger text-center text-md-start"
      *ngIf="alertasCliente != null && alertasCliente.length && alertasCargadas && crmservice.userLogin.rol !== 'comercial'"
    >
      <button mat-button class="text-danger" (click)="alertasClienteDetalle()">
        <mat-icon class="me-1">warning</mat-icon>
        <strong>HAY {{ alertasCliente.length }} {{ alertasCliente.length == 1 ? "ALERTA QUE REQUIRE" : "ALERTAS QUE REQUIEREN" }} ATENCIÓN</strong>
      </button>
    </div>
    <div
      class="alert alert-info"
      *ngIf="!alertasCargadas && crmservice.userLogin.rol !== 'comercial' && crmservice.userLogin.rol !== 'influencer'"
      [ngClass]="mobileQuery.matches ? 'text-center' : ''"
    >
      <span class="spinner-border spinner-border-sm"></span> Comprobando Alertas
    </div>
    <mat-tab-group
      #menuVertical
      dynamicHeight
      class="tab-group"
      [ngClass]="!mobileQuery.matches ? '' : 'custom-mat-tab-group'"
      [selectedIndex]="abrirTab()"
    >
      <!-- CLIENTE -->
      <mat-tab label="Cliente">
        <ng-template mat-tab-label>
          <mat-icon *ngIf="!mobileQuery.matches" class="me-1">work</mat-icon>
          Cliente
        </ng-template>

        <div class="tab-content">
          <div class="contenedor-cliente">
            <div class="row mb-4" [ngClass]="!mobileQuery.matches ? 'seccion-ficha-direccion' : ''">
              <div class="col-12 mb-5">
                <h4 class="m-0 text-secondary">GENERAL</h4>
              </div>

              <div class="col-md-6 col-12">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Tipo*</mat-label>
                  <label class="w-100 m-0">
                    <mat-select [(ngModel)]="cliente.tipoCliente.tipo" placeholder="Tipo*">
                      <mat-option *ngFor="let item of crmservice.tiposCliente" [value]="item.id">{{ item.nombre }}</mat-option>
                    </mat-select>
                  </label>
                </mat-form-field>
              </div>

              <div class="col-md-6 col-12">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Categoria*</mat-label>
                  <label class="w-100 m-0">
                    <mat-select [(ngModel)]="cliente.tipoCliente.categoria" placeholder="Categoría*">
                      <mat-option *ngFor="let item of crmservice.categoriasCliente" [value]="item.id">{{ item.nombre }}</mat-option>
                    </mat-select>
                  </label>
                </mat-form-field>
              </div>

              <div class="col-md-6 col-12">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Razón social*</mat-label>
                  <label class="w-100 m-0">
                    <input [disabled]="!formControlCliente('razonSocial')" [(ngModel)]="cliente.razonSocial" matInput placeholder="Razón social*" />
                  </label>
                </mat-form-field>
              </div>

              <div class="col-lg-3 col-md-6 col-12">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>CIF</mat-label>
                  <label class="w-100 m-0">
                    <input [disabled]="!formControlCliente('cif')" [(ngModel)]="cliente.cif" matInput placeholder="CIF" />
                  </label>
                </mat-form-field>
              </div>

              <div class="col-lg-3 col-12">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>¿Es extranjero?</mat-label>
                  <mat-select
                    [disabled]="!formControlCliente('extrangero')"
                    [(ngModel)]="cliente.esExtranjero"
                    (ngModelChange)="comprobacionesCIF(cliente, cliente.cif)"
                  >
                    <mat-option value="{{ procedencia[1].esExtranjero }}">{{ procedencia[1].estado }}</mat-option>
                    <mat-option value="{{ procedencia[0].esExtranjero }}">{{ procedencia[0].estado }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-12">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Observaciones</mat-label>
                  <label class="w-100 m-0">
                    <textarea
                      [(ngModel)]="cliente.observaciones"
                      matInput
                      matTextareaAutosize
                      matAutosizeMinRows="2"
                      placeholder="Observaciones"
                    ></textarea>
                  </label>
                </mat-form-field>
              </div>
            </div>

            <div
              *ngIf="!crmservice.rol('comercial') && !crmservice.rol('influencer')"
              class="row mb-4"
              [ngClass]="!mobileQuery.matches ? 'seccion-ficha-direccion' : ''"
            >
              <div class="col-12 mb-4 pr-0">
                <h4 class="m-0 text-secondary">
                  CÓDIGOS DE CLIENTE
                  <span *ngIf="crmservice.rol('admin')" class="btn-config-licencias" (click)="vincularIpdorma()">
                    <mat-icon class="text-secondary">add_circle</mat-icon>
                  </span>
                </h4>
              </div>
              <div *ngIf="!ipdormas.length" class="col-12">
                <span class="text-secondary">No hay códigos de cliente asociados.</span>
              </div>
              <div *ngIf="ipdormas.length" class="col-12">
                <table class="table text-secondary table-hover">
                  <thead>
                    <tr>
                      <th style="white-space: nowrap; width: 1%">Código</th>
                      <th style="white-space: nowrap; width: 1%">CIF</th>
                      <th style="white-space: nowrap; width: 1%">Razón Social</th>
                      <th style="white-space: nowrap; width: 1%">Nombre Comercial</th>
                      <th style="white-space: nowrap; width: 1%">Punto Compra</th>
                      <th></th>
                      <th style="white-space: nowrap; width: 1%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let ipdorma of ipdormas">
                      <td style="white-space: nowrap; width: 1%">{{ ipdorma.codigo }}</td>
                      <td style="white-space: nowrap; width: 1%">{{ ipdorma.cif }}</td>
                      <td style="white-space: nowrap; width: 1%">{{ ipdorma.razonSocial }}</td>
                      <td style="white-space: nowrap; width: 1%">{{ ipdorma.nombreComercial }}</td>
                      <td style="white-space: nowrap; width: 1%">{{ ipdorma.puntoCompra }}</td>
                      <td style="white-space: nowrap; width: 1%">
                        <div *ngIf="ipdorma.clientes.length > 1">
                          <span class="text-danger">¡Este código ya está asignada en otro cliente!</span>
                          <ng-container *ngFor="let idCliente of ipdorma.clientes">
                            <div *ngIf="idCliente !== this.cliente._id">
                              <button class="btn btn-link" (click)="btnIpdormaRepetido(idCliente)">
                                {{ getCliente(idCliente).cif }} - {{ getCliente(idCliente).razonSocial }}
                              </button>
                            </div>
                          </ng-container>
                        </div>
                      </td>
                      <td class="text-end align-middle">
                        <mat-icon *ngIf="crmservice.rol('admin')" class="user-select-none" role="button" (click)="desvincularIpdorma(ipdorma)"
                          >cancel</mat-icon
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <span class="col-12 text-secondary mt-2 mb-2" style="font-size: 12px">
                *Los datos de facturación y consumo del CMI se extraen de los códigos de cliente asociados a la ficha del CRM.
              </span>
            </div>

            <div *ngIf="infoLicencias != null" class="row mb-4" [ngClass]="!mobileQuery.matches ? 'seccion-ficha-direccion' : ''">
              <!-- Licencias 3D -->
              <div class="col-12 mb-4 pr-0">
                <h4 class="m-0 text-secondary">
                  <span>LICENCIAS</span>
                  <img alt="" src="../../../assets/img/otros/3d.png" class="mb-1 ms-1" id="icono-licencias-3d" />
                  <span class="text-secondary ms-3 fs-6"> ({{ cliente.licenciasDisponibles.fijo ? "MANUAL" : "AUTOMÁTICO" }}) </span>

                  <span
                    *ngIf="crmservice.userLogin.rol == 'admin' || crmservice.userLogin.rol == 'supervisor' || crmservice.userLogin.rol == 'pedidos'"
                    class="btn-config-licencias"
                    (click)="configLicenciasCli()"
                  >
                    <mat-icon class="text-secondary">settings</mat-icon>
                  </span>

                  <span *ngIf="mostrarHistorialConfigLicencias(cliente)" class="btn-config-licencias pe-1" (click)="historialConfigLicenciasCli()">
                    <mat-icon class="text-secondary">history</mat-icon>
                  </span>
                </h4>
              </div>
              <div class="col-lg-6 col-12 mb-3">
                <div class="bg-light rounded p-3">
                  <table class="table table-sm m-0">
                    <tbody>
                      <tr>
                        <td>
                          Máximo de Licencias:
                          <span class="float-end">
                            <span class="text-secondary fs-6">
                              ({{ cliente.licenciasDisponibles.cantidad }} + {{ cliente.licenciasDisponibles.adicionales }})</span
                            >
                            {{ cliente.licenciasDisponibles.cantidad + cliente.licenciasDisponibles.adicionales }}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Licencias Utilizadas:
                          <span class="float-end">{{ getLicenciasUtilizadas() }}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Licencias Sin Utilizar:
                          <span
                            class="float-end"
                            [ngStyle]="{
                              color:
                                cliente.licenciasDisponibles.cantidad + cliente.licenciasDisponibles.adicionales - getLicenciasUtilizadas() > 0
                                  ? 'green'
                                  : 'brown'
                            }"
                          >
                            {{ cliente.licenciasDisponibles.cantidad + cliente.licenciasDisponibles.adicionales - getLicenciasUtilizadas() }}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-lg-6 col-12 mb-3">
                <div class="bg-light rounded p-3">
                  <span *ngIf="infoLicencias.motivo != ''" style="white-space: pre-line">{{ infoLicencias.motivo }}</span>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 text-center mb-3" *ngIf="mensajeErrorCliente != ''">
                <strong class="text-danger">{{ mensajeErrorCliente }}</strong>
              </div>

              <div class="col-12 text-end">
                <button color="primary" (click)="guardarClienteDireccion()" mat-raised-button>Guardar</button>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>

      <!-- DIRECCIONES -->
      <mat-tab label="Direcciones">
        <ng-template mat-tab-label>
          <mat-icon *ngIf="!mobileQuery.matches" class="me-1">place</mat-icon>
          Direcciones
        </ng-template>
        <div class="tab-content">
          <div class="row mb-3">
            <div class="col-12 text-end">
              <button color="primary" (click)="nuevaDireccion()" mat-button>
                <mat-icon>add_to_photos</mat-icon>
                Nueva dirección
              </button>
            </div>
          </div>

          <mat-accordion>
            <mat-expansion-panel
              *ngFor="let direccion of direcciones"
              [expanded]="expandirDireccion(direccion)"
              [ngClass]="{ dirDelete: direccion.desactivado }"
            >
              <mat-expansion-panel-header>
                <div class="col-12 mt-3" *ngIf="!mobileQuery.matches">
                  <app-equipo [equipo]="direccion.equipo" [estado]="direccion.estado"></app-equipo>
                  <strong>{{ direccion.nombreComercial }}</strong>
                  <span>&nbsp;({{ direccion.poblacion }})</span>
                  <span *ngIf="direccion.tipoDir == 5">&nbsp;Almacén</span>
                </div>

                <div class="col-12 p-1" *ngIf="mobileQuery.matches">
                  <app-equipo [equipo]="direccion.equipo" [estado]="direccion.estado"></app-equipo>
                  <b>{{ direccion.nombreComercial }}&nbsp;</b>({{ direccion.poblacion }})
                </div>
              </mat-expansion-panel-header>

              <div class="mt-3">
                <!-- GENERAL -->
                <div class="row mb-4" [ngClass]="!mobileQuery.matches ? 'seccion-ficha-direccion' : ''">
                  <div class="col-12 mb-5">
                    <h4 class="m-0 text-secondary">
                      GENERAL
                      <button class="float-end" mat-icon-button [matMenuTriggerFor]="menuDireccion">
                        <mat-icon>more_vert</mat-icon>
                      </button>
                    </h4>
                    <mat-menu #menuDireccion="matMenu">
                      <button (click)="fotografias(direccion)" mat-menu-item>
                        <mat-icon>photo</mat-icon>
                        <span>Ver Fotografías</span>
                      </button>
                      <button (click)="ubicacion(direccion)" mat-menu-item>
                        <mat-icon>place</mat-icon>
                        <span>Ver Ubicación</span>
                      </button>
                      <button
                        (click)="irInforme(1, direccion.perfilTCLA, null)"
                        *ngIf="direccion.perfilTCLA != '' && direccion.perfilTCLA != undefined"
                        mat-menu-item
                      >
                        <mat-icon>assessment</mat-icon>
                        <span>Conexiones TECLA</span>
                      </button>
                      <button
                        (click)="irInforme(2, direccion.perfilTCLA, null)"
                        *ngIf="direccion.perfilTCLA != '' && direccion.perfilTCLA != undefined"
                        mat-menu-item
                      >
                        <mat-icon>line_style</mat-icon>
                        <span>Promociones TECLA</span>
                      </button>
                      <button
                        *ngIf="crmservice.userLogin.rol != 'asesor' && direccion.desactivado != true"
                        (click)="erDireccion(direccion, true)"
                        mat-menu-item
                      >
                        <mat-icon color="warn">delete</mat-icon>
                        <span>Eliminar dirección</span>
                      </button>
                      <button
                        *ngIf="crmservice.userLogin.rol != 'asesor' && direccion.desactivado == true"
                        (click)="erDireccion(direccion, false)"
                        mat-menu-item
                      >
                        <mat-icon>undo</mat-icon>
                        <span>Recuperar dirección</span>
                      </button>
                    </mat-menu>
                  </div>
                  <div
                    *ngIf="direccion.imagenes.length > 0"
                    class="col-lg-3 col-12 mb-3 foto"
                    [ngClass]="mobileQuery.matches ? 'text-center' : 'text-start'"
                  >
                    <img
                      [src]="direccion.imagenes.length > 0 ? crmservice.urlCRM + '/img/contactos/' + direccion.imagenes[0] : ''"
                      width="100%"
                      alt="IMAGEN DIRECCIÓN"
                      class="rounded shadow border border-secondary"
                    />
                  </div>

                  <div [ngClass]="direccion.imagenes.length > 0 && !mobileQuery.matches ? 'col-lg-9 col-12 p-0' : 'col-12 p-0'">
                    <div class="row">
                      <div class="col-xl-6 col-12">
                        <mat-form-field appearance="outline" class="w-100">
                          <mat-label>Nombre Comercial*</mat-label>
                          <label class="w-100 m-0">
                            <input
                              [disabled]="!formControlDireccion(direccion, 'nombreComercial')"
                              matInput
                              placeholder="Nombre Comercial*"
                              [(ngModel)]="direccion.nombreComercial"
                          /></label>
                        </mat-form-field>
                      </div>

                      <div class="col-xl-3 col-sm-6 col-12">
                        <mat-form-field appearance="outline" class="w-100">
                          <mat-label>Estado*</mat-label>

                          <mat-select [disabled]="!formControlDireccion(direccion, 'estado')" [(ngModel)]="direccion.estado" placeholder="Estado*">
                            <mat-option *ngFor="let item of crmservice.estados" value="{{ item.id }}">{{ item.nombre }} </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>

                      <div
                        *ngIf="direccion.perfilesSecundarios == null || direccion.perfilesSecundarios.length === 0"
                        class="col-xl-3 col-sm-6 col-12"
                      >
                        <mat-form-field appearance="outline" class="w-100">
                          <mat-label>{{ damePlaceholder("perfilTCLA", direccion) }}</mat-label>
                          <label class="w-100 m-0">
                            <input
                              [disabled]="!formControlDireccion(direccion, 'perfilTCLA')"
                              matInput
                              placeholder="{{ damePlaceholder('perfilTCLA', direccion) }}"
                              [(ngModel)]="direccion.perfilTCLA"
                              (ngModelChange)="inputTCLA(direccion)"
                            />
                          </label>
                          <mat-icon matSuffix role="button" class="text-secondary" (click)="detallesPerfilTCLA(direccion.perfilTCLA, direccion)"
                            >search</mat-icon
                          >
                        </mat-form-field>
                      </div>

                      <div class="col-xl-3 col-sm-6 col-12">
                        <mat-form-field appearance="outline" class="w-100">
                          <mat-label> Equipo*</mat-label>
                          <mat-select [disabled]="!formControlDireccion(direccion, 'equipo')" [(ngModel)]="direccion.equipo" placeholder="Equipo*">
                            <mat-option *ngFor="let item of crmservice.equipos" value="{{ item.id }}">{{ item.id }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>

                      <div class="col-xl-3 col-sm-6 col-12">
                        <mat-form-field appearance="outline" class="w-100">
                          <mat-label>Tipo de dirección*</mat-label>
                          <mat-select
                            [disabled]="!formControlDireccion(direccion, 'tipoDir')"
                            [(ngModel)]="direccion.tipoDir"
                            (ngModelChange)="tipoDirCambiado(direccion)"
                            placeholder="Tipo de dirección*"
                          >
                            <mat-option *ngFor="let item of crmservice.tiposDir" value="{{ item.id }}">{{ item.nombre }} </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>

                      <div class="col-xl-3 col-sm-6 col-12">
                        <mat-form-field appearance="outline" class="w-100">
                          <mat-label>Ver en Mapa*</mat-label>
                          <mat-select
                            [disabled]="!formControlDireccion(direccion, 'verMapa')"
                            [(ngModel)]="direccion.verMapa"
                            placeholder="Ver en Mapa*"
                          >
                            <mat-option [value]="true">Si</mat-option>
                            <mat-option [value]="false">No</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>

                      <div class="col-xl-3 col-sm-6 col-12">
                        <mat-form-field appearance="outline" class="w-100">
                          <mat-label>¿Realizar Seguimiento?</mat-label>
                          <mat-select
                            [disabled]="!formControlDireccion(direccion, 'seguimiento')"
                            [(ngModel)]="direccion.seguimiento"
                            placeholder="¿Realizar Seguimiento?"
                          >
                            <mat-option [value]="true">Si</mat-option>
                            <mat-option [value]="false">No</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-6 col-sm-6 col-12">
                        <mat-form-field appearance="outline" class="w-100">
                          <mat-label>¿Permite Videollamadas?</mat-label>
                          <mat-select [(ngModel)]="direccion.permiteVideollamadas">
                            <mat-option [value]=""></mat-option>
                            <mat-option [value]="false">No</mat-option>
                            <mat-option [value]="true">Si</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-xl-6 col-sm-6 col-12">
                        <mat-form-field appearance="outline" class="w-100">
                          <mat-label>¿Tiene Montadores?</mat-label>
                          <mat-select [(ngModel)]="direccion.tieneMontadores">
                            <mat-option [value]=""></mat-option>
                            <mat-option [value]="false">No</mat-option>
                            <mat-option [value]="true">Si</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-xl-6 col-sm-6 col-12" *ngIf="direccion.tieneMontadores">
                        <mat-form-field appearance="outline" class="w-100">
                          <mat-label>Tipo de Montadores</mat-label>
                          <mat-select [(ngModel)]="direccion.tipoMontadores">
                            <mat-option [value]="''"></mat-option>
                            <mat-option [value]="'propios'">Propios</mat-option>
                            <mat-option [value]="'subcontratados'">Subcontratados</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-6 col-sm-6 col-12">
                        <mat-form-field appearance="outline" class="w-100">
                          <mat-label>¿Tiene SmartTV?</mat-label>
                          <mat-select [(ngModel)]="direccion.tieneSmartTV">
                            <mat-option [value]=""></mat-option>
                            <mat-option [value]="false">No</mat-option>
                            <mat-option [value]="true">Si</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>

                      <div class="col-xl-6 col-sm-6 col-12" *ngIf="direccion.tieneSmartTV">
                        <mat-form-field appearance="outline" class="w-100">
                          <mat-label>Cuantas SmartTVs</mat-label>
                          <input type="number" matInput [(ngModel)]="direccion.numSmartTV" />
                        </mat-form-field>
                      </div>
                      <div class="col-xl-6 col-sm-6 col-12">
                        <mat-form-field appearance="outline" class="w-100">
                          <mat-label>¿Maneja redes sociales?</mat-label>
                          <mat-select [(ngModel)]="direccion.manejaRRSS">
                            <mat-option [value]=""></mat-option>
                            <mat-option [value]="false">No</mat-option>
                            <mat-option [value]="true">Si</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>

                      <div class="col-xl-6 col-sm-6 col-12" *ngIf="direccion.manejaRRSS">
                        <mat-form-field appearance="outline" class="w-100">
                          <mat-label>¿Nos sigue?</mat-label>
                          <mat-select [(ngModel)]="direccion.seguidorRRSS">
                            <mat-option [value]=""></mat-option>
                            <mat-option [value]="false">No</mat-option>
                            <mat-option [value]="true">Si</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-xl-12 col-sm-12 col-12">
                        <mat-form-field appearance="outline" class="w-100">
                          <mat-label>¿Informado sobre comisión de Presupuestos y Nini?</mat-label>
                          <mat-select [(ngModel)]="direccion.informadoComision">
                            <mat-option [value]=""></mat-option>
                            <mat-option [value]="false">No</mat-option>
                            <mat-option [value]="true">Si</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- TECLA INFO -->
                <div
                  *ngIf="direccion.perfilesSecundarios != null && direccion.perfilesSecundarios.length > 0"
                  class="row mb-4"
                  [ngClass]="!mobileQuery.matches ? 'seccion-ficha-direccion' : ''"
                >
                  <div class="col-12 mb-5">
                    <h4 class="m-0 text-secondary">TECLA - PERFIL PRINCIPAL</h4>
                  </div>

                  <div class="col-xl-3 col-sm-6 col-12 mb-4">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>{{ damePlaceholder("perfilTCLA", direccion) }}</mat-label>
                      <label class="w-100 m-0">
                        <input
                          [disabled]="!formControlDireccion(direccion, 'perfilTCLA')"
                          matInput
                          placeholder="{{ damePlaceholder('perfilTCLA', direccion) }}"
                          [(ngModel)]="direccion.perfilTCLA"
                          (ngModelChange)="inputTCLA(direccion)"
                        />
                      </label>
                      <mat-icon matSuffix role="button" class="text-secondary" (click)="detallesPerfilTCLA(direccion.perfilTCLA, direccion)"
                        >search</mat-icon
                      >
                    </mat-form-field>
                  </div>

                  <div class="col-12 mb-4">
                    <h5 class="border-bottom m-0 text-secondary">SECUNDARIOS</h5>
                  </div>

                  <ng-container *ngFor="let perfil of direccion.perfilesSecundarios">
                    <div class="col-12 col-sm-6">
                      <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Perfil</mat-label>
                        <label class="w-100 m-0">
                          <input matInput placeholder="Perfil" [(ngModel)]="perfil.perfil" />
                        </label>
                        <mat-icon matSuffix role="button" class="text-secondary" (click)="detallesPerfilTCLA(perfil.perfil, direccion)"
                          >search
                        </mat-icon>
                      </mat-form-field>
                    </div>

                    <div class="col-12 col-sm-6">
                      <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Email</mat-label>
                        <label class="w-100 m-0">
                          <input matInput placeholder="Perfil" [(ngModel)]="perfil.email" />
                        </label>
                      </mat-form-field>
                    </div>
                  </ng-container>
                </div>

                <!-- DIRECCIÓN Y CONTACTO -->
                <div class="row mb-4" [ngClass]="!mobileQuery.matches ? 'seccion-ficha-direccion' : ''">
                  <div class="col-12 mb-5">
                    <h4 class="m-0 text-secondary">Dirección y Contacto</h4>
                  </div>

                  <div class="col-lg-2 col-sm-6 col-12">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Código postal*</mat-label>
                      <label class="w-100 m-0">
                        <input
                          (change)="cambiaCP(direccion)"
                          matInput
                          placeholder="Código postal*"
                          [disabled]="!formControlDireccion(direccion, 'codigoPostal')"
                          [(ngModel)]="direccion.codigoPostal"
                        />
                      </label>
                    </mat-form-field>
                  </div>

                  <div class="col-lg-5 col-sm-6 col-12">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Provincia*</mat-label>
                      <mat-select
                        [disabled]="!formControlDireccion(direccion, 'provincia')"
                        [(ngModel)]="direccion.provincia"
                        placeholder="Provincia*"
                      >
                        <mat-option *ngFor="let item of crmservice.provincias" value="{{ item.nombre }}">{{ item.nombre }} </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="col-lg-5 col-sm-6 col-12">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Población*</mat-label>
                      <label class="w-100 m-0">
                        <input
                          matInput
                          placeholder="Población*"
                          [disabled]="!formControlDireccion(direccion, 'poblacion')"
                          [(ngModel)]="direccion.poblacion"
                        />
                      </label>
                    </mat-form-field>
                  </div>

                  <div class="col-lg-2 col-sm-6 col-12">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Vía*</mat-label>
                      <mat-select [disabled]="!formControlDireccion(direccion, 'tipoVia')" [(ngModel)]="direccion.tipoVia" placeholder="Vía*">
                        <mat-option *ngFor="let item of crmservice.vias" value="{{ item }}"
                          >{{ item[0].toUpperCase() + item.substr(1).toLowerCase() }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="col-lg-5 col-12">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Dirección*</mat-label>
                      <label class="w-100 m-0">
                        <input
                          matInput
                          placeholder="Dirección*"
                          [disabled]="!formControlDireccion(direccion, 'direccion')"
                          [(ngModel)]="direccion.direccion"
                        />
                      </label>
                    </mat-form-field>
                  </div>

                  <div class="col-lg-5 col-12">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Detalle</mat-label>
                      <label class="w-100 m-0">
                        <input
                          matInput
                          placeholder="Detalle"
                          [disabled]="!formControlDireccion(direccion, 'detalleDir')"
                          [(ngModel)]="direccion.direccionDetalle"
                        />
                      </label>
                    </mat-form-field>
                  </div>

                  <div class="col-lg-2 col-12">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>{{ damePlaceholder("telefono", direccion) }}</mat-label>
                      <label class="w-100 m-0">
                        <input matInput placeholder="{{ damePlaceholder('telefono', direccion) }}" [(ngModel)]="direccion.telefono" />
                      </label>
                    </mat-form-field>
                  </div>

                  <div class="col-lg-5 col-sm-6 col-12">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>{{ damePlaceholder("email", direccion) }}</mat-label>
                      <label class="w-100 m-0">
                        <input matInput placeholder="{{ damePlaceholder('email', direccion) }}" [(ngModel)]="direccion.email" />
                      </label>
                    </mat-form-field>
                  </div>

                  <div class="col-lg-5 col-sm-6 col-12">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Web</mat-label>
                      <label class="w-100 m-0">
                        <input matInput placeholder="Web" [(ngModel)]="direccion.webEmpresa" />
                      </label>
                    </mat-form-field>
                  </div>

                  <div class="col-12">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Observaciones</mat-label>
                      <label class="w-100 m-0">
                        <textarea
                          [(ngModel)]="direccion.observaciones"
                          matInput
                          matTextareaAutosize
                          matAutosizeMinRows="2"
                          placeholder="Observaciones"
                        ></textarea>
                      </label>
                    </mat-form-field>
                  </div>

                  <div class="col-12" *ngIf="direccion.estado == 'proscrito'">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Motivo proscrito</mat-label>
                      <label class="w-100 m-0">
                        <textarea
                          [(ngModel)]="direccion.motivo"
                          matInput
                          [ngModelOptions]="{ standalone: true }"
                          matTextareaAutosize
                          matAutosizeMinRows="2"
                          placeholder="Motivo proscrito*"
                        ></textarea>
                      </label>
                    </mat-form-field>
                  </div>
                </div>

                <!-- HITOS -->
                <div
                  *ngIf="direccion.hitosObtenidos != null && direccion.hitosObtenidos.length"
                  class="row mb-4"
                  [ngClass]="!mobileQuery.matches ? 'seccion-ficha-direccion' : ''"
                >
                  <div class="col-12 mb-5">
                    <h4 class="m-0 text-secondary">Hitos</h4>
                  </div>

                  <div class="hitoOn col-md-2 col-4" *ngIf="mostrarHito(direccion, 'TCLA', false)">
                    <img src="../../../assets/img/hitos/TCLA_borrado.png" class="img-hito" alt="" /><br />
                    <span>{{ dameHitoDir(direccion, "TCLA", false).fecha | date : "dd/MM/yy" }}</span
                    ><br />
                    {{ getUsuarioHitos(dameHitoDir(direccion, "TCLA", false).usuario) }}
                  </div>

                  <div class="hitoOn col-md-2 col-4" *ngIf="mostrarHito(direccion, 'TCLA', true) && direccion.perfilTCLAActivo">
                    <img src="../../../assets/img/hitos/TCLA.png" class="img-hito" alt="" /><br />
                    <span>{{ dameHitoDir(direccion, "TCLA", true).fecha | date : "dd/MM/yy" }}</span
                    ><br />
                    {{ getUsuarioHitos(dameHitoDir(direccion, "TCLA", true).usuario) }}
                  </div>

                  <div class="hitoOn col-md-2 col-4" *ngIf="mostrarHito(direccion, 'TCLA', true) && !direccion.perfilTCLAActivo">
                    <img src="../../../assets/img/hitos/TCLA_off.png" class="img-hito" alt="" /><br />
                    <span>{{ dameHitoDir(direccion, "TCLA", true).fecha | date : "dd/MM/yy" }}</span
                    ><br />
                    {{ getUsuarioHitos(dameHitoDir(direccion, "TCLA", true).usuario) }}
                  </div>

                  <div class="hitoOn col-md-2 col-4" *ngIf="mostrarHito(direccion, 'CLASS', false)">
                    <img src="../../../assets/img/hitos/class_borrado.png" class="img-hito" alt="" /><br />
                    <span>{{ dameHitoDir(direccion, "CLASS", false).fecha | date : "dd/MM/yy" }}</span
                    ><br />
                    {{ getUsuarioHitos(dameHitoDir(direccion, "CLASS", false).usuario) }}
                  </div>

                  <div class="hitoOn col-md-2 col-4" *ngIf="mostrarHito(direccion, 'CLASS', true)">
                    <img src="../../../assets/img/hitos/class.png" class="img-hito" alt="" /><br />
                    <span>{{ dameHitoDir(direccion, "CLASS", true).fecha | date : "dd/MM/yy" }}</span
                    ><br />
                    {{ getUsuarioHitos(dameHitoDir(direccion, "CLASS", true).usuario) }}
                  </div>

                  <div class="hitoOn col-md-2 col-4" *ngIf="mostrarHito(direccion, '3D', false)">
                    <img src="../../../assets/img/hitos/3d_borrado.png" class="img-hito" alt="" /><br />
                    <span>{{ dameHitoDir(direccion, "3D", false).fecha | date : "dd/MM/yy" }}</span
                    ><br />
                    {{ getUsuarioHitos(dameHitoDir(direccion, "3D", false).usuario) }}
                  </div>

                  <div class="hitoOn col-md-2 col-4" *ngIf="mostrarHito(direccion, '3D', true)">
                    <button class="buttonContrato" (click)="administrarLicencias3D(direccion)" mat-button>
                      <img *ngIf="licenciaActualizada(direccion)" src="../../../assets/img/hitos/3d.png" class="img-hito" alt="" />
                      <img *ngIf="!licenciaActualizada(direccion)" src="../../../assets/img/hitos/3d_off.png" class="img-hito" alt="" />
                      <br />
                      <span>{{ dameHitoDir(direccion, "3D", true).fecha | date : "dd/MM/yy" }}</span
                      ><br />
                      {{ getUsuarioHitos(dameHitoDir(direccion, "3D", true).usuario) }}
                    </button>
                  </div>

                  <div class="hitoOn col-md-2 col-4" *ngIf="mostrarHito(direccion, 'MUESTRAS', false)">
                    <img src="../../../assets/img/hitos/muestras_borrado.png" class="img-hito" alt="" /><br />
                    <span>{{ dameHitoDir(direccion, "MUESTRAS", false).fecha | date : "dd/MM/yy" }}</span
                    ><br />
                    {{ getUsuarioHitos(dameHitoDir(direccion, "MUESTRAS", false).usuario) }}
                  </div>

                  <div class="hitoOn col-md-2 col-4" *ngIf="mostrarHito(direccion, 'MUESTRAS', true)">
                    <button class="buttonContrato" (click)="administrarMuestras(direccion)" mat-button>
                      <img src="../../../assets/img/hitos/muestras.png" class="img-hito" alt="" /><br />
                      <span>{{ dameHitoDir(direccion, "MUESTRAS", true).fecha | date : "dd/MM/yy" }}</span
                      ><br />
                      {{ getUsuarioHitos(dameHitoDir(direccion, "MUESTRAS", true).usuario) }}
                    </button>
                  </div>

                  <div class="hitoOn col-md-2 col-4" *ngIf="mostrarHito(direccion, 'REVISTAS', false)">
                    <img src="../../../assets/img/hitos/revistas_borrado.png" class="img-hito" alt="" /><br />
                    <span>{{ dameHitoDir(direccion, "REVISTAS", false).fecha | date : "dd/MM/yy" }}</span
                    ><br />
                    {{ getUsuarioHitos(dameHitoDir(direccion, "REVISTAS", false).usuario) }}
                  </div>

                  <div class="hitoOn col-md-2 col-4" *ngIf="mostrarHito(direccion, 'REVISTAS', true)">
                    <img src="../../../assets/img/hitos/revistas.png" class="img-hito" alt="" /><br />
                    <span>{{ dameHitoDir(direccion, "REVISTAS", true).fecha | date : "dd/MM/yy" }}</span
                    ><br />
                    {{ getUsuarioHitos(dameHitoDir(direccion, "REVISTAS", true).usuario) }}
                  </div>

                  <div class="hitoOn col-md-2 col-4" *ngIf="mostrarHito(direccion, 'WV', false)">
                    <img src="../../../assets/img/hitos/wallviewer_borrado.png" class="img-hito" alt="" /><br />
                    <span>{{ dameHitoDir(direccion, "WV", false).fecha | date : "dd/MM/yy" }}</span
                    ><br />
                    {{ getUsuarioHitos(dameHitoDir(direccion, "WV", false).usuario) }}
                  </div>

                  <div class="hitoOn col-md-2 col-4" *ngIf="mostrarHito(direccion, 'WV', true)">
                    <button class="buttonContrato" (click)="contratoWV(direccion)" mat-button>
                      <img src="../../../assets/img/hitos/wallviewer.png" class="img-hito" alt="" /><br />
                      <span>{{ dameHitoDir(direccion, "WV", true).fecha | date : "dd/MM/yy" }}</span
                      ><br />
                      {{ getUsuarioHitos(dameHitoDir(direccion, "WV", true).usuario) }}
                    </button>
                  </div>

                  <div class="hitoOn col-md-2 col-4" *ngIf="mostrarHito(direccion, 'IDEHABITA', false)">
                    <img src="../../../assets/img/hitos/ctm_borrado.png" class="img-hito" alt="" /><br />
                    <span>{{ dameHitoDir(direccion, "IDEHABITA", false).fecha | date : "dd/MM/yy" }}</span
                    ><br />
                    {{ getUsuarioHitos(dameHitoDir(direccion, "IDEHABITA", false).usuario) }}
                  </div>

                  <div class="hitoOn col-md-2 col-4" *ngIf="mostrarHito(direccion, 'IDEHABITA', true)">
                    <button class="buttonContrato" (click)="contratoIDE(direccion)" mat-button [disabled]="!cpCargados">
                      <img src="../../../assets/img/hitos/ctm.png" class="img-hito" alt="" /><br />
                      <span *ngIf="cpCargados"
                        >{{ dameHitoDir(direccion, "IDEHABITA", true).fecha | date : "dd/MM/yy" }}<br />
                        {{ getUsuarioHitos(dameHitoDir(direccion, "IDEHABITA", true).usuario) }}</span
                      >
                      <span *ngIf="!cpCargados" class="spinner-border text-primary" style="font-size: 0.85em" role="status">
                        <span class="sr-only"></span>
                      </span>
                    </button>
                  </div>

                  <div class="hitoOn col-md-2 col-4" *ngIf="mostrarHito(direccion, 'PRE_DVIRTUAL', true)">
                    <button class="buttonContrato" (click)="contratoPreDV(direccion)" mat-button [disabled]="!cpCargados">
                      <img src="../../../assets/img/hitos/pre_dvirtual.png" class="img-hito" alt="" /><br />
                      <span
                        >{{ dameHitoDir(direccion, "PRE_DVIRTUAL", true).fecha | date : "dd/MM/yy" }}<br />
                        {{ getUsuarioHitos(dameHitoDir(direccion, "PRE_DVIRTUAL", true).usuario) }}</span
                      >
                      <span *ngIf="!cpCargados" class="spinner-border text-primary" style="font-size: 0.85em" role="status">
                        <span class="sr-only"></span>
                      </span>
                    </button>
                  </div>

                  <div class="hitoOn col-md-2 col-4" *ngIf="mostrarHito(direccion, 'BannerCreamostumueble', true)">
                    <button class="buttonContrato" (click)="contratoBannerCtm(direccion)" mat-button [disabled]="!cpCargados">
                      <img src="../../../assets/img/hitos/banner_ctm.png" class="img-hito" alt="" /><br />
                      <span
                        >{{ dameHitoDir(direccion, "BannerCreamostumueble", true).fecha | date : "dd/MM/yy" }}<br />
                        {{ getUsuarioHitos(dameHitoDir(direccion, "BannerCreamostumueble", true).usuario) }}</span
                      >
                      <span *ngIf="!cpCargados" class="spinner-border text-primary" style="font-size: 0.85em" role="status">
                        <span class="sr-only"></span>
                      </span>
                    </button>
                  </div>

                  <div class="hitoOn col-md-2 col-4" *ngIf="mostrarHito(direccion, 'BannerCreamostumueble', false)">
                    <img src="../../../assets/img/hitos/banner_ctm_borrado.png" class="img-hito" alt="" /><br />
                    <span>{{ dameHitoDir(direccion, "BannerCreamostumueble", false).fecha | date : "dd/MM/yy" }}</span
                    ><br />
                    {{ getUsuarioHitos(dameHitoDir(direccion, "BannerCreamostumueble", false).usuario) }}
                  </div>

                  <div class="hitoOn col-md-2 col-4" *ngIf="mostrarHito(direccion, 'DVIRTUAL', false)">
                    <img src="../../../assets/img/hitos/Idehabita_borrado.png" class="img-hito" alt="" /><br />
                    <span>{{ dameHitoDir(direccion, "DVIRTUAL", false).fecha | date : "dd/MM/yy" }}</span
                    ><br />
                    {{ getUsuarioHitos(dameHitoDir(direccion, "DVIRTUAL", false).usuario) }}
                  </div>

                  <div class="hitoOn col-md-2 col-4" *ngIf="mostrarHito(direccion, 'DVIRTUAL', true)">
                    <button class="buttonContrato" (click)="contratoAmueblando(direccion)" mat-button [disabled]="!cpCargados">
                      <img src="../../../assets/img/hitos/Idehabita.png" class="img-hito" alt="" /><br />
                      <span *ngIf="cpCargados"
                        >{{ dameHitoDir(direccion, "DVIRTUAL", true).fecha | date : "dd/MM/yy" }}<br />
                        {{ getUsuarioHitos(dameHitoDir(direccion, "DVIRTUAL", true).usuario) }}</span
                      >
                      <span *ngIf="!cpCargados" class="spinner-border text-primary" style="font-size: 0.85em" role="status">
                        <span class="sr-only"></span>
                      </span>
                    </button>
                  </div>

                  <div class="hitoOn col-md-2 col-4" *ngIf="mostrarHito(direccion, 'DOSIER_PRESENTACION', false)">
                    <img src="../../../assets/img/hitos/dosier_presentacion_off.png" class="img-hito" alt="" /><br />
                    <span>{{ dameHitoDir(direccion, "DOSIER_PRESENTACION", false).fecha | date : "dd/MM/yy" }}</span
                    ><br />
                    {{ getUsuarioHitos(dameHitoDir(direccion, "DOSIER_PRESENTACION", false).usuario) }}
                  </div>

                  <div class="hitoOn col-md-2 col-4" *ngIf="mostrarHito(direccion, 'DOSIER_PRESENTACION', true)">
                    <img src="../../../assets/img/hitos/dosier_presentacion.png" class="img-hito" alt="" /><br />
                    <span>{{ dameHitoDir(direccion, "DOSIER_PRESENTACION", true).fecha | date : "dd/MM/yy" }}</span
                    ><br />
                    {{ getUsuarioHitos(dameHitoDir(direccion, "DOSIER_PRESENTACION", true).usuario) }}
                  </div>
                </div>

                <!-- HORARIO -->
                <div class="row mb-4" [ngClass]="!mobileQuery.matches ? 'seccion-ficha-direccion' : ''">
                  <div class="col-12 mb-5">
                    <h4 class="m-0 text-secondary">Horario</h4>
                  </div>

                  <div class="col-12">
                    <ng-container *ngFor="let horario of direccion.horarios">
                      <div class="row" *ngIf="horario.estado">
                        <div class="col-md-4">
                          <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Días</mat-label>
                            <mat-select [(ngModel)]="horario.dias" placeholder="Días" multiple>
                              <mat-option *ngFor="let item of crmservice.dias" value="{{ item }}">{{ item }} </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class="col-md-3">
                          <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Título</mat-label>
                            <label class="w-100 m-0">
                              <input [(ngModel)]="horario.titulo" matInput placeholder="Título" />
                            </label>
                          </mat-form-field>
                        </div>
                        <div class="col-md-2">
                          <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Hora de apertura</mat-label>
                            <mat-select [(ngModel)]="horario.horaInicio" placeholder="Hora de apertura">
                              <mat-option *ngFor="let item of crmservice.horas" value="{{ item.hora }}">{{ item.hora }} </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class="col-md-2">
                          <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Hora de cierre</mat-label>
                            <mat-select [(ngModel)]="horario.horaFinal" placeholder="Hora de cierre">
                              <mat-option *ngFor="let item of crmservice.horas" value="{{ item.hora }}">{{ item.hora }} </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class="col-md-1 borrarHorario">
                          <button color="warn" (click)="borrarHorario(horario)" mat-icon-button>
                            <mat-icon>delete</mat-icon>
                          </button>
                        </div>
                      </div>
                    </ng-container>
                  </div>

                  <div class="col-12">
                    <button (click)="adirHorario(direccion)" mat-button>Añadir horario</button>
                  </div>
                </div>

                <!-- BOTÓN -->
                <div class="row mb-3">
                  <div
                    class="col-12 mt-3 text-center"
                    *ngIf="dameMensajeErrorDireccion(direccion._id) != null && dameMensajeErrorDireccion(direccion._id).mensajeError != ''"
                  >
                    <strong class="text-danger">{{ dameMensajeErrorDireccion(direccion._id).mensajeError }}</strong>
                  </div>

                  <div class="col-12 text-end">
                    <button color="primary" (click)="guardarClienteDireccion()" mat-raised-button>Guardar</button>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </mat-tab>

      <!-- CONTACTOS -->
      <mat-tab label="Personas">
        <ng-template mat-tab-label>
          <mat-icon *ngIf="!mobileQuery.matches" class="me-1">person</mat-icon>
          Contactos
        </ng-template>
        <div class="tab-content">
          <div class="row mb-3">
            <div class="col-12 text-end">
              <button (click)="nuevaPersona()" color="primary" mat-button>
                <mat-icon>add_to_photos</mat-icon>
                Nuevo contacto
              </button>
            </div>
          </div>

          <ng-container *ngFor="let direccion of direcciones">
            <ng-container *ngIf="direccion.personas.length">
              <div class="mt-4 p-4 rounded shadow">
                <div class="border-bottom border-secondary pb-1 mb-3">
                  <span>
                    {{ direccion.nombreComercial }}
                    <span class="text-secondary">({{ direccion.poblacion }} , {{ direccion.tipoVia.toUpperCase() }} {{ direccion.direccion }})</span>
                  </span>
                </div>

                <mat-accordion>
                  <mat-expansion-panel
                    *ngFor="let persona of direccion.personas"
                    [ngClass]="{ dirDelete: personaDesactivada(getPersona(persona), direccion) }"
                    [expanded]="expandirPersona(persona)"
                  >
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <div class="d-flex">
                          <mat-icon
                            class="text-success me-2"
                            *ngIf="getPersona(persona).diploma != null && getPersona(persona).diploma.trim().length"
                          >
                            school
                          </mat-icon>
                          <span>
                            <strong>
                              {{ getPersona(persona).nombre }}&nbsp;{{
                                getPersona(persona).apellidos != null && getPersona(persona).apellidos.trim().length
                                  ? getPersona(persona).apellidos
                                  : ""
                              }}
                            </strong>
                          </span>
                        </div>
                      </mat-panel-title>
                    </mat-expansion-panel-header>

                    <ng-template matExpansionPanelContent>
                      <div class="row">
                        <div class="col-12 text-end cabecera">
                          <button mat-icon-button [matMenuTriggerFor]="menuContacto">
                            <mat-icon>more_vert</mat-icon>
                          </button>
                        </div>
                      </div>

                      <mat-menu #menuContacto="matMenu">
                        <!-- QUE NO SEA TRUE, es decir FALSE or NULL -->
                        <button
                          *ngIf="!personaDesactivada(getPersona(persona), direccion)"
                          (click)="erPersona(getPersona(persona), direccion._id)"
                          mat-menu-item
                        >
                          <mat-icon color="warn">delete</mat-icon>
                          <span>Eliminar persona</span>
                        </button>
                        <button
                          *ngIf="personaDesactivada(getPersona(persona), direccion)"
                          (click)="erPersona(getPersona(persona), direccion._id)"
                          mat-menu-item
                        >
                          <mat-icon>undo</mat-icon>
                          <span>Recuperar persona</span>
                        </button>
                        <button
                          *ngIf="personaDesactivada(getPersona(persona), direccion)"
                          (click)="desasignarPersonaDireccion(getPersona(persona), direccion._id)"
                          mat-menu-item
                        >
                          <mat-icon color="warn">close</mat-icon>
                          <span>Eliminar Rastro</span>
                        </button>
                      </mat-menu>

                      <div class="col-12 mb-3" *ngIf="getPersona(persona).perfil != null">
                        <img
                          width="100%"
                          class="img-persona"
                          alt="AVATAR PERSONA"
                          [src]="getPersona(persona).perfil != null ? crmservice.urlCRM + '/doc/ficherosPersonas/' + getPersona(persona).perfil : ''"
                        />
                      </div>

                      <div class="row">
                        <div class="col-12 col-md-6">
                          <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Nombre*</mat-label>
                            <label class="w-100 m-0">
                              <input [(ngModel)]="getPersona(persona).nombre" matInput placeholder="Nombre*" />
                            </label>
                          </mat-form-field>
                        </div>

                        <div class="col-12 col-md-6">
                          <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Apellidos</mat-label>
                            <label class="w-100 m-0">
                              <input [(ngModel)]="getPersona(persona).apellidos" matInput placeholder="Apellidos" />
                            </label>
                          </mat-form-field>
                        </div>

                        <div class="col-12 col-md-4">
                          <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Fecha de nacimiento</mat-label>
                            <label class="w-100 m-0">
                              <input
                                [matDatepicker]="nacimiento"
                                [(ngModel)]="getPersona(persona).nacimiento"
                                matInput
                                placeholder="Fecha de nacimiento"
                              />
                            </label>
                            <mat-datepicker-toggle matSuffix [for]="nacimiento"></mat-datepicker-toggle>
                            <mat-datepicker startView="multi-year" #nacimiento></mat-datepicker>
                          </mat-form-field>
                        </div>

                        <div class="col-12 col-md-4">
                          <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Tratamiento*</mat-label>
                            <mat-select [(ngModel)]="getPersona(persona).tratamiento" placeholder="Tratamiento*">
                              <mat-option *ngFor="let item of crmservice.tratamientos" value="{{ item.id }}">{{ item.nombre }} </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>

                        <div class="col-12 col-md-4">
                          <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Cargo*</mat-label>
                            <mat-select [(ngModel)]="getPersona(persona).cargo" placeholder="Cargo*">
                              <mat-option *ngFor="let item of crmservice.cargos" value="{{ item.id }}">{{ item.nombre }} </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>

                        <div class="col-12 col-md-4">
                          <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Email</mat-label>
                            <label class="w-100 m-0">
                              <input [(ngModel)]="getPersona(persona).email" matInput placeholder="Email" />
                            </label>
                          </mat-form-field>
                        </div>

                        <div class="col-12 col-md-4">
                          <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Teléfono</mat-label>
                            <label class="w-100 m-0">
                              <input [(ngModel)]="getPersona(persona).telefono" matInput placeholder="Teléfono" />
                            </label>
                          </mat-form-field>
                        </div>

                        <div class="col-12 col-md-4">
                          <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Móvil</mat-label>
                            <label class="w-100 m-0">
                              <input [(ngModel)]="getPersona(persona).movil" matInput placeholder="Móvil" />
                            </label>
                          </mat-form-field>
                        </div>

                        <div class="col-12 col-md-8">
                          <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Observaciones*</mat-label>
                            <label class="w-100 m-0">
                              <textarea
                                [(ngModel)]="getPersona(persona).observaciones"
                                matInput
                                placeholder="Observaciones*"
                                matTextareaAutosize
                                matAutosizeMinRows="1"
                              ></textarea>
                            </label>
                          </mat-form-field>
                        </div>

                        <div class="col-12">
                          <button class="btn btn-secondary" (click)="formacionPersona(getPersona(persona), direccion)">FORMACIÓN</button>
                        </div>

                        <div
                          class="col-12 mt-3 text-center"
                          *ngIf="dameMensajeErrorPersona(persona) != null && dameMensajeErrorPersona(persona).mensajeError != ''"
                        >
                          <strong class="text-dander">{{ dameMensajeErrorPersona(persona).mensajeError }}</strong>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12 text-end">
                          <button color="primary" (click)="guardarPersona(getPersona(persona))" mat-raised-button>Guardar</button>
                        </div>
                      </div>
                    </ng-template>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </mat-tab>

      <!-- ACCIONES -->
      <mat-tab label="Acciones">
        <ng-template mat-tab-label>
          <mat-icon *ngIf="!mobileQuery.matches" class="me-1">event_available</mat-icon>
          <span *ngIf="!mobileQuery.matches" [matBadge]="accionesSinfinalizar()" matBadgeOverlap="false"> Acciones </span>
          <span *ngIf="mobileQuery.matches"> Acciones </span>
        </ng-template>
        <div class="tab-content">
          <div class="row mb-3">
            <div class="col-12 ps-2 pe-2 text-end">
              <button (click)="historialAcciones()" color="primary" mat-button>
                <mat-icon>history</mat-icon>
                Histórico
              </button>
              <button *ngIf="crmservice.userLogin.rol != 'asesor' || !crmservice.modoPruebas" (click)="nuevaAccion()" color="primary" mat-button>
                <mat-icon>add_to_photos</mat-icon>
                Nueva acción
              </button>
            </div>
          </div>

          <mat-accordion>
            <ng-container *ngFor="let accion of acciones">
              <mat-expansion-panel *ngIf="accion.estado != 0" [expanded]="accion._id == route.snapshot.params.id">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <mat-icon color="primary" *ngIf="accion.estado == 2">update</mat-icon>
                    <mat-icon color="primary" *ngIf="accion.estado == 1">check_box</mat-icon>
                    &nbsp;<b>{{ getHito(accion.hito) }}</b
                    >&nbsp;-&nbsp;{{ getObjetivo(accion.objetivo) }}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                  <div class="row mb-2">
                    <div class="col-12">
                      <mat-chip-list>
                        <div *ngFor="let usuario of getUsuarios(accion)">
                          <mat-chip *ngIf="usuario._id == accion.de.toString()" class="m-1 p-3" style="background: #b2b8ff">
                            <img *ngIf="usuario.imagen == null" class="imagenRedonda" src="../../../assets/img/otros/person.png" alt="" />
                            <img *ngIf="usuario.imagen != null" class="imagenRedonda" [src]="usuario.imagen" alt="" />
                            <span class="ms-4">{{ usuario.nombre }} {{ usuario.apellidos }} (adquirida)</span>
                          </mat-chip>

                          <mat-chip *ngIf="usuario._id != accion.de.toString()" class="m-1 p-3">
                            <img *ngIf="usuario.imagen == null" class="imagenRedonda" src="../../../assets/img/otros/person.png" alt="" />
                            <img *ngIf="usuario.imagen != null" class="imagenRedonda" [src]="usuario.imagen" alt="" />
                            <span class="ms-4">{{ usuario.nombre }} {{ usuario.apellidos }}</span>
                          </mat-chip>
                        </div>
                      </mat-chip-list>
                    </div>
                  </div>

                  <div class="contenedor-desc-accion">
                    <div *ngIf="!mobileQuery.matches" class="d-flex justify-content-between">
                      <span>{{ accion.fechaPrevista | date : "dd/MM/yyyy HH:mm" }}</span>
                      <span>{{ getDireccion(accion.direccion) }}, {{ getContacto(accion.contactos) }}</span>
                    </div>

                    <div *ngIf="mobileQuery.matches">
                      <p class="mb-1">
                        <strong>{{ getDireccion(accion.direccion) }}, {{ getContacto(accion.contactos) }}</strong>
                      </p>
                      <p class="mb-0">{{ accion.fechaPrevista | date : "dd/MM/yyyy HH:mm" }}</p>
                    </div>

                    <hr class="mt-0" />

                    <p class="observacionesAccion">{{ accion.observaciones }}</p>
                    <p>
                      <strong>{{ getResultado(accion.resultado) }}</strong>
                    </p>
                  </div>

                  <br />
                  <div class="row">
                    <div
                      class="col-12"
                      *ngIf="(accion.estado == '2' && crmservice.userLogin.rol != 'asesor') || (!crmservice.modoPruebas && accion.estado == '2')"
                    >
                      <button (click)="realizarAccion(accion)" mat-button color="primary">
                        <mat-icon>edit</mat-icon>
                        Realizar acción
                      </button>
                    </div>
                    <div
                      class="col-12"
                      *ngIf="(accion.estado == '2' && crmservice.userLogin.rol != 'asesor') || (!crmservice.modoPruebas && accion.estado == '2')"
                    >
                      <button (click)="posponerEnviar(accion)" mat-button color="primary">
                        <mat-icon>assignment_ind</mat-icon>
                        Enviar a
                      </button>
                    </div>
                    <div
                      class="col-12"
                      *ngIf="(accion.estado == '2' && crmservice.userLogin.rol != 'asesor') || (!crmservice.modoPruebas && accion.estado == '2')"
                    >
                      <button (click)="posponerAccion(accion)" mat-button color="primary">
                        <mat-icon>event_busy</mat-icon>
                        Posponer acción
                      </button>
                    </div>
                    <div class="col-12" *ngIf="accion.estado == '1'">
                      <button (click)="adquirirAccion(accion)" mat-button color="primary">
                        <mat-icon>done</mat-icon>
                        Adquirir acción
                      </button>
                    </div>
                    <div class="col-12">
                      <button mat-button color="primary" (click)="historialAcciones(accion)">
                        <mat-icon>history</mat-icon>
                        Histórico
                      </button>
                    </div>
                  </div>
                </ng-template>
              </mat-expansion-panel>
            </ng-container>
          </mat-accordion>

          <div class="alert alert-info mt-4" [hidden]="!alertInfoAcciones">
            <span class="float-end" role="button" (click)="alertInfoAcciones = false">
              <mat-icon>close</mat-icon>
            </span>

            <h4 class="alert-heading">Atención</h4>
            <p class="mb-1">
              Los hitos <strong>TECLA</strong>, <strong>Muestras</strong>, <strong>Revistas</strong>, <strong>Wallviewer</strong> y
              <strong>Análisis</strong> están disponibles en todas las direcciones.
            </p>

            <p class="mb-1">
              Para obtener el hito <strong>3D</strong>, es necesario tener el hito TECLA previamente y además, el perfil TECLA debe estar indicado en
              los datos de la dirección.
            </p>

            <p class="mb-1">
              Los hitos <strong>#YomequedoencasaAMUEBLANDO</strong>, <strong>Colaborador#AMUEBLANDO</strong> y <strong>CLASS</strong> dejan de estar
              disponibles.
            </p>

            <p class="mb-1">El hito <strong>Diseñador Virtual</strong> solo se puede seleccionar en:</p>
            <ul>
              <li>Direcciones que tengan el hito <strong>TECLA</strong>.</li>
            </ul>

            <p>
              En dircciones <strong>vituales</strong> y <strong>'gancho'</strong> se puede seleccionar cualquier hito
              <strong>sin restricciones</strong>.
            </p>

            <p class="mb-1"><strong>Finalización de acciones: </strong></p>
            <ul>
              <li>
                Los usuarios con rol <strong>COMERCIAL</strong>, <strong>PEDIDOS</strong> y <strong>SUPERVISOR</strong>, pueden finalizar cualqueir
                tipo de acción desde CRM.
              </li>
              <li>
                Los usuarios con rol <strong>ASESOR</strong>, deben realizar y finalizar acciones desde el <strong>GESTOR DE TIEMPOS</strong>. No
                podrán cerrar acciones de formación.
              </li>
            </ul>
          </div>
        </div>
      </mat-tab>

      <!-- INFORMES -->
      <mat-tab class="not-disable" label="Informes" disabled>
        <ng-template mat-tab-label>
          <span *ngIf="mobileQuery.matches" class="not-disable" [matMenuTriggerFor]="menuInformes"> Informes </span>

          <button *ngIf="!mobileQuery.matches" mat-button [matMenuTriggerFor]="menuInformes" class="not-disable">
            <mat-icon *ngIf="!mobileQuery.matches">timeline</mat-icon>
            Informes
          </button>

          <mat-menu #menuInformes="matMenu">
            <button (click)="irInforme(4, null, cliente._id)" mat-menu-item>
              <mat-icon>show_chart</mat-icon>
              <span>Evolución de consumo</span>
            </button>
            <button (click)="irInforme(5, null, cliente._id)" mat-menu-item>
              <mat-icon>bar_chart</mat-icon>
              <span>Comparativa de consumo</span>
            </button>
            <button (click)="irInforme(6, null, cliente._id)" mat-menu-item>
              <mat-icon>list_alt</mat-icon>
              <span>Cartera</span>
            </button>
            <button *ngIf="crmservice.userLogin.rol === 'admin'" (click)="regenerarAlertas()" mat-menu-item>
              <mat-icon>error</mat-icon>
              <span>Regenerar Alertas</span>
            </button>
          </mat-menu>
        </ng-template>
      </mat-tab>

      <!-- -->
      <mat-tab
        label="?"
        *ngIf="
          (cliente._id === '5e317b0e75ca7ceeeda99381' || cliente._id === '5ea282b465fa4d3016d5edb4') &&
          (crmservice.rol('admin') || crmservice.rol('pedidos')) &&
          crmservice.userLogin._id !== '5b081585cb13ba000a000008' &&
          crmservice.userLogin._id !== '5b0816decb13ba000a00000a' &&
          crmservice.userLogin._id !== '5b14f9e0ebc036b02100008f'
        "
      >
        <ng-template mat-tab-label class="text-start">
          <mat-icon *ngIf="!mobileQuery.matches" class="me-1 smile">emoji_emotions</mat-icon>
        </ng-template>
        <div class="tab-content">
          <app-thing2></app-thing2>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>

  <div *ngIf="cliente == null">
    <div class="alert alert-danger text-center">
      <span><strong>No se ha podido encontra el cliente que estás buscando.</strong></span>
    </div>
  </div>
</div>
