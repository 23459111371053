import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {CrmService} from '../../servicios/crm.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Cliente} from '../../modelos/Cliente';
import {Direccion} from '../../modelos/Direccion';
import {Persona} from '../../modelos/Persona';
import {Accion} from '../../modelos/Accion';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabGroup } from '@angular/material/tabs';
import {NuevaDireccionFormularioComponent} from './formularios/nueva-direccion-formulario/nueva-direccion-formulario.component';
import {NuevaPersonaFormularioComponent} from './formularios/nueva-persona-formulario/nueva-persona-formulario.component';
import {FotografiasDireccionComponent} from './formularios/fotografias-direccion/fotografias-direccion.component';
import {UbicacionDireccionComponent} from './formularios/ubicacion-direccion/ubicacion-direccion.component';
import {HistoricoAccionesComponent} from './formularios/historico-acciones/historico-acciones.component';
import {NuevaAccionFormularioComponent} from './formularios/nueva-accion-formulario/nueva-accion-formulario.component';
import {RealizarAccionFormularioComponent} from './formularios/realizar-accion-formulario/realizar-accion-formulario.component';
import {PosponerAccionFormularioComponent} from './formularios/posponer-accion-formulario/posponer-accion-formulario.component';
import {ContratoWallviewerFormularioComponent} from './formularios/contrato-wallviewer-formulario/contrato-wallviewer-formulario.component';
import {ContratoIdehabitaFormularioComponent} from './formularios/contrato-idehabita-formulario/contrato-idehabita-formulario.component';
import {AdminMuestrasFormularioComponent} from './formularios/admin-muestras-formulario/admin-muestras-formulario.component';
import {PosponerEnviarAccionFormularioComponent} from './formularios/posponer-enviar-accion-formulario/posponer-enviar-accion-formulario.component';
import {Licencias3DFormularioComponent} from './formularios/licencias3-d-formulario/licencias3-d-formulario.component';
import {Licencia} from '../../modelos/Licencia';
import {MediaMatcher} from '@angular/cdk/layout';
import {FormacionPersonaComponent} from './formularios/formacion-persona/formacion-persona.component';
import {ContratoAmueblandoComponent} from './formularios/contrato-amueblando/contrato-amueblando.component';
import {ContratoPresentacionDVComponent} from './formularios/contrato-presentacion-dv/contrato-presentacion-dv.component';
import {ContratoBannerCreamostumuebleComponent} from './formularios/contrato-banner-creamostumueble/contrato-banner-creamostumueble.component';
import {Usuario} from '../../modelos/Usuario';
import {DetallesPerfilTCLAComponent} from './formularios/detalles-perfil-tcla/detalles-perfil-tcla.component';
import {ConfigLicenciasCliComponent} from './formularios/config-licencias-cli/config-licencias-cli.component';
import {HistorialConfigLicenciasCliComponent} from './formularios/historial-config-licencias-cli/historial-config-licencias-cli.component';
import {AlertaCliente} from '../../modelos/AlertaCliente';
import {AlertasClienteDetalleComponent} from './formularios/alertas-cliente-detalle/alertas-cliente-detalle.component';
import {BusquedaIpdormaComponent} from './formularios/busqueda-ipdorma/busqueda-ipdorma.component';
import {ClienteCIFRazonSocial} from '../../modelos/Interfaces';
import {MapaserviceService} from '../../servicios/mapaservice.service';

@Component({
  selector: 'app-ficha-cliente',
  templateUrl: './ficha-cliente.component.html',
  styleUrls: ['./ficha-cliente.component.css']
})
export class FichaClienteComponent implements OnInit {

  mobileQuery: MediaQueryList;
  private readonly _mobileQueryListener: () => void;

  public ruta: string;

  public clienteCargado: boolean;
  public cpCargados: boolean;
  public alertasCargadas: boolean;
  public guardandoDatos: boolean;

  public alertInfoAcciones: boolean;

  public infoLicencias: {
    cantidad: number,
    motivo: string,
    inputAdicionales: boolean
  };

  public cliente: Cliente;
  public direcciones: Direccion[];
  public personas: Persona[];
  public acciones: Accion[];
  public licencias: Licencia[];
  public alertasCliente: AlertaCliente[];
  public ipdormas: any[];

  public coeficientesDVDir: {
    _id: string,
    coeficienteSobreVenta: number
  }[] = [];

  public mensajeErrorCliente: string;
  public mensajeErrorDireccion: {_id: string, mensajeError: string, tclaValido: boolean}[];
  public mensajeErrorPersonas: {_id: string, mensajeError: string}[];

  @ViewChild('menuVertical') menuVertical: MatTabGroup;
  public direccionMal: string;

  public procedencia: {estado: string, esExtranjero: string}[] = [
    {estado: 'SI', esExtranjero: 'extranjero'},
    {estado: 'NO', esExtranjero: 'noExtranjero'}
  ];

  constructor(changeDetectorRef: ChangeDetectorRef,
              media: MediaMatcher,
              public crmservice: CrmService,
              public mapaservice: MapaserviceService,
              public router: Router,
              public route: ActivatedRoute,
              public dialog: MatDialog,
              public snackBar: MatSnackBar) {

    console.log('FichaCliente');

    this.clienteCargado = false;
    this.cpCargados = false;
    this.alertasCargadas = false;
    this.guardandoDatos = false;
    this.alertInfoAcciones = true;
    this.ipdormas = [];

    this.mensajeErrorCliente = '';
    this.mensajeErrorDireccion = [];
    this.mensajeErrorPersonas =  [];

    this.direccionMal = '';

    this.mobileQuery = media.matchMedia('(max-width: 750px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.ruta = this.router.url.indexOf('direccion') > -1 ? 'direccion' : 'accion';

    if (this.ruta === 'direccion') {
      this.crmservice.getClienteCompletoN(this.route.snapshot.params.id).subscribe(value => {
        this.cliente = new Cliente(value[0]);
        this.direcciones = value[1].map(v => new Direccion(v));
        this.acciones = value[2];
        this.personas = value[3];
        this.licencias = value[4];
        this.ipdormas = value[5];
        crmservice.direccionSeleccionada[1] = this.route.snapshot.params.id;
        crmservice.direccionSeleccionada[2] = value[0].razonSocial;
        crmservice.direccionSeleccionada[3] = value[0].cif;
        crmservice.direccionSeleccionada[0] = true;
        this.clienteCargado = true;
        this.generarFormGroups();

        // Obteniendo coeficientes sobre venta de DV
        for (const direccion of this.direcciones) {
          this.coeficientesDVDir.push({
            _id: direccion._id,
            coeficienteSobreVenta: null
          });

          if (direccion.perfilTCLA !== null) {
            this.crmservice.getFactorConversion(direccion.perfilTCLA).subscribe(val => {
              direccion.perfilTCLAActivo = val[0].activo;
            }, () => {
              direccion.perfilTCLAActivo = false;
            });
          }

        }

        // Asignar el valor por defecto, si no lo tiene, a permiteVideollamadas en cada dirección.
        // for (const direccion of this.direcciones) {
        //   if (!direccion.permiteVideollamadas) {
        //     direccion.permiteVideollamadas = false;
        //   }
        // }

        this.crmservice.recalcularLicenciasDisponibles(this.cliente._id).subscribe(infoLicencias => {
          this.infoLicencias = infoLicencias;
        }, errorInfoLicencias => {
          console.error(errorInfoLicencias);
        });

        this.alertasCliente = this.crmservice.alertasClientes.filter(e => e.cliente === this.cliente._id);
        this.alertasCargadas = true;
        let abrirAlertasDetalle = false;
        this.alertasCliente.forEach(alerta => {
          const pos = alerta.visto.findIndex(e => e === this.crmservice.userLogin._id);
          if (pos === -1) {
            abrirAlertasDetalle = true;
          }
        });
        if (abrirAlertasDetalle && crmservice.userLogin.rol !== 'comercial' && crmservice.userLogin.rol !== 'influencer') {
          this.alertasClienteDetalle();
        }
      }, error => {
        console.error(error);
        this.cliente = null;
        this.clienteCargado = true;
      });
    }

    if (this.ruta === 'accion') {

      this.crmservice.getClienteCompletoAccionN(this.route.snapshot.params.id).subscribe(value => {
        this.cliente = new Cliente(value[0]);
        this.direcciones = value[1].map(v => new Direccion(v));
        this.acciones = value[2];
        this.personas = value[3];
        this.licencias = value[4];
        crmservice.direccionSeleccionada[1] = this.direcciones[0]._id;
        crmservice.direccionSeleccionada[3] = this.route.snapshot.params.id;
        crmservice.direccionSeleccionada[2] = value[0].razonSocial;

        crmservice.direccionSeleccionada[0] = true;
        this.clienteCargado = true;
        this.generarFormGroups();

        // Obteniendo coeficientes sobre venta de DV
        for (const direccion of this.direcciones) {
          this.coeficientesDVDir.push({
            _id: direccion._id,
            coeficienteSobreVenta: null
          });

          if (direccion.perfilTCLA !== null) {
            this.crmservice.getFactorConversion(direccion.perfilTCLA).subscribe(val => {
              direccion.perfilTCLAActivo = val[0].activo;
            }, () => {
              direccion.perfilTCLAActivo = false;
            });
          }
        }

        // Asignar el valor por defecto, si no lo tiene, a permiteVideollamadas en cada dirección.
        // for (const direccion of this.direcciones) {
        //   if (!direccion.permiteVideollamadas) {
        //     direccion.permiteVideollamadas = false;
        //   }
        // }

        this.crmservice.recalcularLicenciasDisponibles(this.cliente._id).subscribe(infoLicencias => {
          this.infoLicencias = infoLicencias;
        }, errorInfoLicencias => {
          console.error(errorInfoLicencias);
        });

        this.alertasCliente = this.crmservice.alertasClientes.filter(e => e.cliente === this.cliente._id);
        this.alertasCargadas = true;
        let abrirAlertasDetalle = false;
        this.alertasCliente.forEach(alerta => {
          const pos = alerta.visto.findIndex(e => e === this.crmservice.userLogin._id);
          if (pos === -1) {
            abrirAlertasDetalle = true;
          }
        });
        if (abrirAlertasDetalle && crmservice.userLogin.rol !== 'comercial'  && crmservice.userLogin.rol !== 'influencer') {
          this.alertasClienteDetalle();
        }

      }, error1 => {
        console.error(error1);
        this.cliente = null;
        this.clienteCargado = true;
      });
    }

  }

  ngOnInit() {

    if (this.crmservice.codigosPostales == null || !this.crmservice.codigosPostales.length) {
      this.crmservice.getAllCP().subscribe(valueCP => {
          this.crmservice.codigosPostales = valueCP;
          this.cpCargados = true;
      }, error1 => {
        console.error(error1);
        this.cpCargados = true;
      });
    } else {
      this.cpCargados = true;
    }

    if (this.crmservice.clientesCIFRazonSocial == null || !this.crmservice.clientesCIFRazonSocial.length) {
      this.crmservice.dameClientesCIFRazonSocial().subscribe(value => this.crmservice.clientesCIFRazonSocial = value);
    }
  }

  public abrirTab(): number {

    if (this.ruta.lastIndexOf('direccion') === -1) {
      return 3;
    } else {
      if (this.route.snapshot.params.persona == null) {
        return 1;
      } else {
        return 2;
      }
    }
  }

  /** GETTERS **/
  public getObjetivo(objetivo: string): string {
    const val = this.crmservice.objetivos.find(e => e.id === objetivo);
    return val ? val.nombre : 'Sin objetivo';
  }
  public getUsuarioHitos(idUser: string): string {
    if (idUser != null) {
      const val = this.crmservice.usuarios.find(e => e._id === idUser);
      return val ? val.nombreUsuario : 'Sin asignar';
    } else {
      return 'Sin asignar';
    }
  }
  public getResultado(resultado: string): string {
    if (resultado) {
      const val = this.crmservice.resultados.find(e => e.id === resultado);
      return val ? val.nombre : '';
    } else {
      return '';
    }
  }
  public getHito(hito: string): string {
    const val = this.crmservice.hitos.find(e => e.id === hito);
    return val ? val.nombre : 'Sin hito';
  }
  public getUsuarios(accion: Accion): Usuario[] {
    const usuarios = [];

    for (const usuarioId of accion.para) {
      const pos = this.crmservice.usuarios.findIndex(e => e._id === usuarioId);
      usuarios.push(this.crmservice.usuarios[pos]);
    }

    if (accion.estado !== '1') {
      let pos = this.crmservice.usuarios.findIndex(e => e._id === accion.de[0]);

      if (pos === -1 && accion.de != null) {
        pos = this.crmservice.usuarios.findIndex(e => e._id === accion.de.toString());
      }

      if (usuarios.indexOf(this.crmservice.usuarios[pos]) === -1) {
        usuarios.push(this.crmservice.usuarios[pos]);
      }
    }

    return usuarios;
  }
  public getDireccion(direccion: string) {
    const val = this.direcciones.find(e => e._id === direccion);
    return val ? (val.poblacion + ', ' + val.direccion) : '';
  }
  public getContacto(contactos: string[]) {
    if (contactos) {
      const val = this.personas.find(e => e._id === contactos[0]);
      return val ? val.nombre : 'Sin Contacto';
    } else {
      return 'Sin Contacto';
    }
  }
  public getPersona(id: string): Persona {
    return this.personas.find(e => e._id === id);
  }
  public getClasificacion(clasificacion: string): string {
    return (clasificacion === 'B1' || clasificacion === 'B2') ? 'B' : clasificacion;
  }
  public getColorClasificacion(clasificacion: string): string {
    if (clasificacion === 'A') {
      return 'limegreen';
    }

    if (clasificacion === 'B1' || clasificacion === 'B2') {
      return 'gold';
    }

    if (clasificacion === 'C') {
      return 'brown';
    }

    return 'pink';
  }
  public getLicenciasDireccion(direccion: Direccion): Licencia {
    for (const licencia of this.licencias) {
      if (direccion._id === licencia.direccion) {
        return licencia;
      }
    }
    return null;
  }
  public getCliente(idCliente): ClienteCIFRazonSocial {
    return this.crmservice.clientesCIFRazonSocial.find(c => c._id === idCliente) || {_id: '', cif: 'desconocido', razonSocial: ''};
  }

  public mostrarHito(direccion: Direccion, hito: string, obtenido: boolean): boolean {
    // Se obtiene el último hito del tipo indicado.
    // Se devuelve si el último hito coincide con el obtenido.
    const hitos = direccion.hitosObtenidos.filter(e => e.hito === hito);
    if (!hitos.length) {
      return false;
    } else {
      hitos.sort((a, b) => {
        const aDate = new Date(a.fecha);
        const bDate = new Date(b.fecha);
        if (aDate.getTime() > bDate.getTime()) {
          return 1;
        } else if (aDate.getTime() < bDate.getTime()) {
          return -1;
        }
        return 0;
      });
      const masReciente = hitos[hitos.length - 1];
      return masReciente.obtenido === obtenido;
    }
  }
  public dameHitoDir(direccion: Direccion, hito: string, obtenido: boolean): { hito: string, obtenido: boolean, fecha: Date, usuario: String } {
    const hitos = direccion.hitosObtenidos.filter(e => e.hito === hito && e.obtenido === obtenido);
    if (!hitos.length) {
      return null;
    } else {
      hitos.sort((a, b) => {
        const aDate = new Date(a.fecha);
        const bDate = new Date(b.fecha);
        if (aDate.getTime() > bDate.getTime()) {
          return 1;
        } else if (aDate.getTime() < bDate.getTime()) {
          return -1;
        }
        return 0;
      });
      return hitos[hitos.length - 1];
    }
  }
  public mostrarHistorialConfigLicencias(cliente: Cliente): boolean {
    if (this.crmservice.userLogin.rol === 'admin' || this.crmservice.userLogin.rol === 'supervisor' || this.crmservice.userLogin.rol === 'pedidos') {
      return (cliente.licenciasDisponibles.cambiosConfig != null && cliente.licenciasDisponibles.cambiosConfig.length > 0);
    }
  }

  /** DIALOGS **/
  public irInforme(number: number, perfilTCLA: string, id: string): void {
    switch (number) {
      case 1:
        window.open(this.crmservice.urlCMI + '/accesos/' + perfilTCLA, '_blank');
        break;
      case 2:
        window.open(this.crmservice.urlCMI + '/informePromociones/' + perfilTCLA, '_blank');
        break;
      case 4:
        window.open(this.crmservice.urlCMI + '/informes/evolucion/' + id, '_blank');
        break;
      case 5:
        window.open(this.crmservice.urlCMI + '/informes/comparativa/' + id, '_blank');
        break;
      case 6:
        window.open(this.crmservice.urlCMI + '/informes/cartera/' + id, '_blank');
        break;
    }
  }

  public administrarMuestras(direccion: Direccion): void {
    this.dialog.open(AdminMuestrasFormularioComponent, {
      width: !this.mobileQuery.matches ? '90%' : '95%',
      height: !this.mobileQuery.matches ? '80%' : '95%',
      disableClose: true,
      autoFocus: false,
      panelClass: 'custom-dialog-container',
      data: {
        direccion: direccion,
      }
    });
  }
  public alertasClienteDetalle(): void {

    const width = this.mobileQuery.matches ? '95%' : '1000px';
    const heigth = this.mobileQuery.matches ? '95%' : '800px';

    this.dialog.open(AlertasClienteDetalleComponent, {
      width: width,
      height: heigth,
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      data: {
        alertasCliente: this.alertasCliente
      }
    });
  }
  public configLicenciasCli(): void {

    const height = !this.mobileQuery.matches ? '700px' : '95%';
    const width = !this.mobileQuery.matches ? '1000px' : '95%';

    this.dialog.open(ConfigLicenciasCliComponent, {
      height: height,
      width: width,
      disableClose: true,
      autoFocus: false,
      panelClass: 'custom-dialog-container',
      data: {
        cliente: this.cliente,
        infoLicencias: this.infoLicencias
      }
    });
  }
  public contratoIDE(direccion: Direccion): void {

    this.clienteCargado = false;

    this.crmservice.getFactorConversion(direccion.perfilTCLA).subscribe(
      perfil => {

        if (perfil[0] != null) {

          this.clienteCargado = true;

          const height = !this.mobileQuery.matches ? '800px' : '95%';
          const width = !this.mobileQuery.matches ? '1200px' : '95%';

          this.dialog.open(ContratoIdehabitaFormularioComponent, {
            height: height,
            width: width,
            disableClose: true,
            autoFocus: false,
            panelClass: 'custom-dialog-container',
            data: {
              direccion: direccion,
            }
          });

        } else {
          this.clienteCargado = true;
          this.snackBar.open('El perfil TECLA indicado no es correcto.', 'OK', {duration: 5000});
        }
      }, error => {
        console.error(error);
        this.clienteCargado = true;
        this.snackBar.open('Error al validar el perfil TECLA. Inténtalo más tarde.', 'OK', {duration: 5000});
      });
  }
  public contratoWV(direccion: Direccion): void {
    if (direccion.contratoWV == null) {
      direccion.contratoWV = {
        cobrado: null,
        devolucion: null,
        documento: null,
        fin: null,
        inicio: null,
        real: null
      };
    }

    const height = !this.mobileQuery.matches ? '300px' : '95%';
    const width = !this.mobileQuery.matches ? '700px' : '95%';

    this.dialog.open(ContratoWallviewerFormularioComponent, {
      height: height,
      width: width,
      disableClose: true,
      autoFocus: false,
      panelClass: 'custom-dialog-container',
      data: {
        direccion: direccion,
      }
    });
  }
  public contratoAmueblando(direccion: Direccion): void {

    this.clienteCargado = false;

    this.crmservice.getFactorConversion(direccion.perfilTCLA).subscribe(
      perfil => {

        if (perfil[0] != null) {

          this.clienteCargado = true;

          const height = !this.mobileQuery.matches ? '800px' : '95%';
          const width = !this.mobileQuery.matches ? '1200px' : '95%';

          this.dialog.open(ContratoAmueblandoComponent, {
            height: height,
            width: width,
            panelClass: 'custom-dialog-container',
            autoFocus: false,
            data: {
              direccion: direccion
            }
          });

        } else {
          this.clienteCargado = true;
          this.snackBar.open('El perfil TECLA indicado no es correcto.', 'OK', {duration: 5000});
        }
      }, error => {
        console.error(error);
        this.clienteCargado = true;
        this.snackBar.open('Error al validar el perfil TECLA. Inténtalo más tarde.', 'OK', {duration: 5000});
      });
  }

  public contratoPreDV(direccion: Direccion): void {

    this.clienteCargado = false;

    this.crmservice.getFactorConversion(direccion.perfilTCLA).subscribe(
      perfil => {

        if (perfil[0] != null) {

          this.clienteCargado = true;

          const height = !this.mobileQuery.matches ? '800px' : '95%';
          const width = !this.mobileQuery.matches ? '1200px' : '95%';

          this.dialog.open(ContratoPresentacionDVComponent, {
            height: height,
            width: width,
            panelClass: 'custom-dialog-container',
            autoFocus: false,
            data: {
              direccion: direccion
            }
          });

        } else {
          this.clienteCargado = true;
          this.snackBar.open('El perfil TECLA indicado no es correcto.', 'OK', {duration: 5000});
        }
      }, error => {
        console.error(error);
        this.clienteCargado = true;
        this.snackBar.open('Error al validar el perfil TECLA. Inténtalo más tarde.', 'OK', {duration: 5000});
      });
  }

  public contratoBannerCtm(direccion: Direccion): void {

    this.clienteCargado = false;

    this.crmservice.getFactorConversion(direccion.perfilTCLA).subscribe(
      perfil => {

        if (perfil[0] != null) {

          this.clienteCargado = true;

          const height = !this.mobileQuery.matches ? '800px' : '95%';
          const width = !this.mobileQuery.matches ? '1200px' : '95%';

          this.dialog.open(ContratoBannerCreamostumuebleComponent, {
            height: height,
            width: width,
            panelClass: 'custom-dialog-container',
            autoFocus: false,
            data: {
              direccion: direccion
            }
          });

        } else {
          this.clienteCargado = true;
          this.snackBar.open('El perfil TECLA indicado no es correcto.', 'OK', {duration: 5000});
        }
      }, error => {
        console.error(error);
        this.clienteCargado = true;
        this.snackBar.open('Error al validar el perfil TECLA. Inténtalo más tarde.', 'OK', {duration: 5000});
      });
  }

  public desvincularIpdorma(ipdorma: any): void {
    this.crmservice.popUpConfirmacion({textoPrincipal: '¿Quieres desvincular este código?', textoSecundario: [{texto: ipdorma.codigo + ' - ' + ipdorma.razonSocial}]})
      .afterClosed().subscribe(value => {
        if (value) {
          this.crmservice.unlinkIpdormaCliente(this.cliente._id, ipdorma._id).subscribe(() => {
              this.crmservice.countCliente_Ipdorma().subscribe(count => this.crmservice.numIpdormas = count || 0);
              const pos = this.ipdormas.findIndex(i => i._id === ipdorma._id);
              if (pos !== -1) {
                this.ipdormas.splice(pos, 1);
              }
              this.regenerarAlertas();
            });
        }
      });
  }
  public detallesPerfilTCLA(perfilTCLA: string, direccion: Direccion): void {
    const dialogRef = this.dialog.open(DetallesPerfilTCLAComponent, {
      height: !this.mobileQuery.matches ? '800px' : '95%',
      width: !this.mobileQuery.matches ? '1200px' : '95%',
      disableClose: true,
      autoFocus: false,
      panelClass: 'tcla-dialog-container',
      data: {
        direccion: direccion,
        cliente: this.cliente,
        perfilTCLA: perfilTCLA
      }
    });
    dialogRef.afterClosed().subscribe(() => this.regenerarAlertas());
  }
  public formacionPersona(persona: Persona, direccion: Direccion): void {
    const width = !this.mobileQuery.matches ? '1000px' : '95%';
    const height = !this.mobileQuery.matches ? '800px' : '95%';

    this.dialog.open(FormacionPersonaComponent, {
      width: width,
      height: height,
      panelClass: 'custom-dialog-container',
      disableClose: true,
      autoFocus: false,
      data: {
        persona: persona,
        personas: this.personas,
        direccion: direccion
      }
    });
  }
  public fotografias(direccion: Direccion): void {
    this.dialog.open(FotografiasDireccionComponent, {
      height: '600px',
      width: '700px',
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      data: {direccion: direccion, direcciones: this.direcciones}
    });
  }
  public historialAcciones(accion: Accion = null): void {

    const height = !this.mobileQuery.matches ? '600px' : '95%';
    const width = !this.mobileQuery.matches ? '700px' : '95%';

    if (accion != null) {
      this.dialog.open(HistoricoAccionesComponent, {
        height: height,
        width: width,
        panelClass: 'custom-dialog-container',
        autoFocus: false,
        data: {
          direcciones: this.direcciones,
          acciones: this.acciones,
          direccion: accion.direccion,
          hito: accion.hito,
          personas: this.personas
        }
      });
    } else {
      this.dialog.open(HistoricoAccionesComponent, {
        height: height,
        width: width,
        panelClass: 'custom-dialog-container',
        autoFocus: false,
        data: {
          direcciones: this.direcciones,
          acciones: this.acciones,
          personas: this.personas
        }
      });
    }
  }
  public historialConfigLicenciasCli(): void {

    const height = !this.mobileQuery.matches ? '600px' : '95%';
    const width = !this.mobileQuery.matches ? '700px' : '95%';

    this.dialog.open(HistorialConfigLicenciasCliComponent, {
      height: height,
      width: width,
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      data: {
        cliente: this.cliente,
      }
    });
  }
  public administrarLicencias3D(direccion: Direccion): void {

    const width = !this.mobileQuery.matches ? '1000px' : '95%';
    const height = !this.mobileQuery.matches ? '800px' : '95%';

    const dialogRef = this.dialog.open(Licencias3DFormularioComponent, {
      width: width,
      height: height,
      panelClass: 'custom-dialog-container',
      disableClose: true,
      autoFocus: false,
      data: {
        cliente: this.cliente,
        direccion: direccion,
        licencia: this.getLicenciasDireccion(direccion),
        licencias: this.licencias
      }
    });

    dialogRef.afterClosed().subscribe(
      value => {
        if (value != null) {
          const pos = this.licencias.findIndex(e => e._id === value._id);
          if (pos !== -1) {
            this.licencias[pos] = value;
          } else {
            this.licencias.push(value);
          }
        }
      }, error1 => {
        console.error(error1);
      });
  }
  public nuevaAccion(): void {
    const dialogRef = this.dialog.open(NuevaAccionFormularioComponent, {
      height: '600px',
      width: '700px',
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      data: {
        acciones: this.acciones,
        direcciones: this.direcciones,
        personas: this.personas
      }
    });
    dialogRef.afterClosed().subscribe(() => this.regenerarAlertas());
  }
  public nuevaDireccion(): void {
    const dialogRef = this.dialog.open(NuevaDireccionFormularioComponent, {
      height: !this.mobileQuery.matches ? '800px' : '100%',
      width: !this.mobileQuery.matches ? '700px' : '100%',
      maxWidth: '100%',
      maxHeight: '100%',
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      data: {
        cliente: this.cliente
      }
    });

    dialogRef.afterClosed().subscribe(
      value => {
        if (value != null) {
          this.direcciones.push(value.direccion);
          this.cliente = value.cliente;

          this.mensajeErrorDireccion.push({
            _id: value.direccion._id,
            mensajeError: '',
            tclaValido: true
          });
        }
      },
      error => {
        console.error(error);
        this.snackBar.open('Ex0003', 'OK', {duration: 2000});
      }
    );
  }
  public nuevaPersona(): void {
    const dialogRef = this.dialog.open(NuevaPersonaFormularioComponent, {
      height: !this.mobileQuery.matches ? '70%' : '95%',
      width: !this.mobileQuery.matches ? '800px' : '95%',
      maxHeight: '100%',
      maxWidth: '100%',
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      data: {
        direcciones: this.direcciones
      }
    });

    dialogRef.afterClosed().subscribe(value => {
      if (value != null) {

        this.mensajeErrorPersonas.push({
          _id: value.persona._id,
          mensajeError: ''
        });

        if (!value.nueva) {
          value.persona.direccion.push(value.direccion._id);
          value.persona.cliente.push(value.direccion.cliente);
        }

        const posPersona = this.personas.findIndex(e => e._id === value.persona._id);
        if (posPersona === -1) {
          this.personas.push(value.persona);
        } else {
          this.personas[posPersona] = value.persona;
        }

        const posPersonaDir = value.direccion.personas.findIndex(e => e === value.persona._id);
        if (posPersonaDir === -1) {
          value.direccion.personas.push(value.persona._id);
        } else {
          value.direccion.personas[posPersonaDir] = value.persona._id;
        }

        const pos = this.direcciones.findIndex(e => e._id === value.direccion._id);
        this.direcciones[pos] = value.direccion;

      }
      this.regenerarAlertas();
    }, error1 => {
      this.snackBar.open('Ex0003', 'OK', {duration: 2000});
      console.error(error1);
    });
  }
  public posponerAccion(accion: Accion): void {
    const dialogRef = this.dialog.open(PosponerAccionFormularioComponent, {
      height: !this.mobileQuery.matches ? '600px' : '95%',
      width: !this.mobileQuery.matches ? '700px' : '95%',
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      data: {
        accion: accion,
      }
    });
    dialogRef.afterClosed().subscribe(() => this.regenerarAlertas());
  }
  public posponerEnviar(accion: Accion): void {
    const dialogRef = this.dialog.open(PosponerEnviarAccionFormularioComponent, {
      height: !this.mobileQuery.matches ? '600px' : '95%',
      width: !this.mobileQuery.matches ? '700px' : '95%',
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      data: {
        accion: accion,
        direcciones: this.direcciones
      }
    });
    dialogRef.afterClosed().subscribe(() => this.regenerarAlertas());
  }
  public realizarAccion(accion: Accion): void {
    const dialogRef = this.dialog.open(RealizarAccionFormularioComponent, {
      height: '600px',
      width: '700px',
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      data: {
        acciones: this.acciones,
        accion: accion,
        direcciones: this.direcciones,
        personas: this.personas
      }
    });

    dialogRef.afterClosed().subscribe(value => {
      if (value != null) {
        const pos = this.acciones.findIndex(e => e._id === value._id);
        if (pos !== -1) {
          this.acciones[pos] = value;
        }
        this.regenerarAlertas();
      }
    }, () => {
      this.snackBar.open('Ex0001', 'OK', {duration: 2000});
    });
  }
  public vincularIpdorma(): void {
    const dialogRef = this.dialog.open(BusquedaIpdormaComponent, {
      width: !this.mobileQuery.matches ? '80%' : '95%',
      height: !this.mobileQuery.matches ? '80%' : '95%',
      panelClass: 'custom-dialog-container',
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(value => {
      if (value) {
        this.crmservice.linkIpdormaCliente(this.cliente._id, value).subscribe(() => {
          this.crmservice.getIpdormasClienteMin(this.cliente._id).subscribe(ipdormas => {
            this.crmservice.countCliente_Ipdorma().subscribe(count => this.crmservice.numIpdormas = count || 0);
            this.ipdormas = ipdormas;
            this.regenerarAlertas();
          });
        });
      }
    });
  }
  public ubicacion(direccion: Direccion): void {
    this.dialog.open(UbicacionDireccionComponent, {
      height: '800px',
      width: '900px',
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      data: {
        direccion: direccion
      }
    });
  }

  /** GUARDADO (FORMULARIO, NO EN DIALOG) **/
  public guardarClienteDireccion(): void {
    // Guardar todas las direcciones y finalmente, guardar al cliente
    for (const direccion of this.direcciones) {
      if (!this.comprobarFormularioDireccion(direccion)) {

        this.snackBar.open(
          direccion.nombreComercial + ': ' + ((this.dameMensajeErrorDireccion(direccion._id) != null) ? (this.dameMensajeErrorDireccion(direccion._id).mensajeError) : ('Error desconocido')),
          'OK',
          {duration: 5000}
          );

        this.menuVertical.selectedIndex = 1;

        this.direccionMal = direccion._id;
        return;
      }
    }

    this.direccionMal = '';

    if (!this.comprobarFormularioCliente(this.cliente)) {
      this.snackBar.open(this.mensajeErrorCliente, 'OK', {duration: 5000});
      this.menuVertical.selectedIndex = 0;
      return;
    } else {
      this.guardandoDatos = true;

      // Pasar a mayúsculas y eliminar guión del cif
      if (this.cliente.cif != null && this.cliente.cif.trim().length) {
        this.cliente.cif = this.cliente.cif.toUpperCase().replace(/[\s\-]+/g, '');
      }

      // Pasar el texto a mayúsculas y eliminar acentos
      this.cliente.razonSocial = this.normalizarTexto(this.cliente.razonSocial);
      this.cliente.observaciones = this.normalizarTexto(this.cliente.observaciones);

      for (const direccion of this.direcciones) {
        direccion.nombreComercial = this.normalizarTexto(direccion.nombreComercial);
        direccion.poblacion = this.normalizarTexto(direccion.poblacion);
        direccion.direccion = this.normalizarTexto(direccion.direccion);
        direccion.direccionDetalle = this.normalizarTexto(direccion.direccionDetalle);
        direccion.webEmpresa = this.normalizarTexto(direccion.webEmpresa);
        direccion.observaciones = this.normalizarTexto(direccion.observaciones);

        // Actualizar la tabla de direcciones
        const pos = this.crmservice.direcciones.findIndex(e => e._id === direccion._id);
        if (pos !== -1) {
          this.crmservice.direcciones[pos] = direccion;
          this.crmservice.direcciones[pos].cif = this.cliente.cif;
          this.crmservice.direcciones[pos].razonSocial = this.cliente.razonSocial;
        }
      }
      this.crmservice.updateClienteDirecciones(this.cliente, this.direcciones).subscribe(() => {
        Promise.all(this.direcciones.map(d => this.mapaservice.generarCoordenadas(d))).then(() => {
          this.guardandoDatos = false;
          this.snackBar.open('Datos del cliente guardados correctamente.', 'OK', {duration: 5000});
          this.regenerarAlertas();
        });
      }, error => {
        console.error(error);
        this.guardandoDatos = false;
        this.snackBar.open('Se ha producido un error al guardar el Cliente', 'OK', {duration: 5000});
      });
    }
  }
  public guardarPersona(persona: Persona): void {
    if (this.comprobarFormularioPersona(persona)) {
      this.guardandoDatos = true;

      this.crmservice.updatePersona(persona).subscribe(
        () => {
          this.guardandoDatos = false;
          this.snackBar.open('Persona de contacto guardada correctamente', 'OK', {duration: 2000});
          }, error1 => {
          console.error(error1);
          this.guardandoDatos = false;
          this.snackBar.open('Error al guardar la persona de contacto', 'OK', {duration: 2000});
        });
    }
  }

  public comprobarFormularioCliente(cliente: Cliente): boolean {

    // La Razón Social es obligatoria
    if (cliente.razonSocial == null || !cliente.razonSocial.trim().length) {
      this.mensajeErrorCliente = '¡La Razón Social es obligatoria!';
      return false;
    }

    // El CIF (igual que TCLA) es obligatorio se hay al menos una dirección como potencial, activo o idehábita.
    /*let mustCIF = false;

    for (const direccionId of cliente.direcciones) {
      const pos = this.direcciones.findIndex(e => e._id === direccionId);
      if (pos !== -1) {
        if (this.direcciones[pos].estado === 'potencial' || this.direcciones[pos].estado === 'activo' || this.direcciones[pos].estado === 'activoI') {
          mustCIF = true;
        }
      }
    }

    if (mustCIF) {
      if (cliente.cif == null || !cliente.cif.trim().length) {
        this.mensajeErrorCliente = '¡El CIF es obligatorio cuando hay direcciones potenciales, activas o idehábita!';
        return false;
      }
    }*/

    // Siempre que se haya introducido algo, debe ser correcto:
    // 1) Cumpla con las validaciones de un CIF.
    // 2) No esté repetido.
    // 3) Si está repetido, indicar un Codigo de Cliente.
    if (cliente.cif != null && !cliente.cif.trim().length) {
      this.cliente.cif = null;
    }


    if (cliente.cif != null) {
      const value = this.comprobacionesCIF(cliente, cliente.cif);
      if (value !== '') {
        this.mensajeErrorCliente = value;
        return false;
      }
    }

    this.mensajeErrorCliente = '';
    return true;
  }
  public comprobarFormularioDireccion(direccion: Direccion): boolean {
    const mensajeErrorDireccion = this.dameMensajeErrorDireccion(direccion._id);

    if (mensajeErrorDireccion == null) {
      return false;
    }

    if (direccion.nombreComercial == null || !direccion.nombreComercial.trim().length) {
      mensajeErrorDireccion.mensajeError = '¡El Nombre Comercial es obligatorio!';
      return false;
    }

    // La dirección es obligatoria salvo que sea virtual y no se muestre en el mapa
    if (direccion.direccion == null || !direccion.direccion.trim().length) {
      if (direccion.tipoDir !== '7' || (direccion.tipoDir === '7' && direccion.verMapa)) {
        mensajeErrorDireccion.mensajeError = '¡La dirección es obligatoria!';
        return false;
      }
    }

    // El código postal es obligatorio salvo que sea virtual y no se muestre en el mapa
    if (direccion.codigoPostal == null || !direccion.codigoPostal.trim().length) {
      if (direccion.tipoDir !== '7' || (direccion.tipoDir === '7' && direccion.verMapa)) {
        mensajeErrorDireccion.mensajeError = '¡El Código Postal es obligatorio!';
        return false;
      }
    }

    // La población es obligatorio salvo que sea virtual y no se muestre en el mapa
    if (direccion.poblacion == null || !direccion.codigoPostal.trim().length) {
      if (direccion.tipoDir !== '7' || (direccion.tipoDir === '7' && direccion.verMapa)) {
        mensajeErrorDireccion.mensajeError = '¡La población es obligatoria!';
        return false;
      }
    }
    
    // El tipo de montadores es obligatorio si se ha dicho que sí a "tiene montador"
    if (direccion.tieneMontadores) {
      if (direccion.tipoMontadores === '') {
        mensajeErrorDireccion.mensajeError = 'Si se contesta SÍ a Tiene Montadores hay que elegir un tipo de montador.';
        return false;
      }
    }
    
    // El número de smartTV es obligatoriamente mayor que 0 si se ha dicho que sí a "tiene SmartTV", y vicecersa, ajustar a 0 el numSmartTV si responde que no.
    if (direccion.tieneSmartTV) {
      if (direccion.numSmartTV < 1) {
        mensajeErrorDireccion.mensajeError = 'Si se contesta SÍ a Tiene SmartTV hay que introducir una cantidad mayor que 0 de SmartTVs.';
        return false;
      }
    } else {
      direccion.numSmartTV = 0;
    }

    // El email es obligatorio para potenciales, activos e idehábitas
    /*if (direccion.estado === 'potencial' || direccion.estado === 'activo' || direccion.estado === 'activoI') {
      if (direccion.email == null || !direccion.email.trim().length) {
        mensajeErrorDireccion.mensajeError = '¡El Email es obligatorio en potenciales, activos e idehábita!';
        return false;
      }
    }

    // El teléfono es obligatorio para potenciales, activos e idehábitas
    if (direccion.estado === 'potencial' || direccion.estado === 'activo' || direccion.estado === 'activoI') {
      if (direccion.telefono == null || !direccion.telefono.trim().length) {
        mensajeErrorDireccion.mensajeError = '¡El Teléfono es obligatorio en potenciales, activos e idehábita!';
        return false;
      }
    }*/

    mensajeErrorDireccion.mensajeError = '';
    return true;
  }
  public comprobarFormularioPersona(persona: Persona): boolean {

    const mensajeErrorPersona = this.dameMensajeErrorPersona(persona._id);

    if (mensajeErrorPersona == null) {
      return false;
    }

    if (persona.nombre == null || !persona.nombre.trim().length) {
      mensajeErrorPersona.mensajeError = '¡El nombre es obligatorio!';
      return false;
    }

    if (persona.cargo == null) {
      mensajeErrorPersona.mensajeError = '¡El cargo es obligatorio!';
      return false;
    }

    if (persona.tratamiento == null) {
      mensajeErrorPersona.mensajeError = '¡El tratamiento es obligatorio!';
      return false;
    }

    mensajeErrorPersona.mensajeError = '';
    return true;
  }

  public inputTCLA(direccion: Direccion): void {
    const mensajeErrorDireccion = this.dameMensajeErrorDireccion(direccion._id);
    if (mensajeErrorDireccion == null) {
      return;
    }
    this.crmservice.getFactorConversion(direccion.perfilTCLA).subscribe(
      value => {
        mensajeErrorDireccion.tclaValido = value[0] != null;
      }, error1 => {
        console.error(error1);
      });
  }
  public tipoDirCambiado(direccion: Direccion): void {
    direccion.verMapa = direccion.tipoDir === '0' || direccion.tipoDir === '6' || direccion.tipoDir === '8';
  }

  public formControlCliente(formControl: string): boolean {

    let permitirEditar = true;
    for (const direccion of this.direcciones) {
      if (direccion.tipoDir !== '7' && direccion.tipoDir !== '8') {
        permitirEditar = false;
      }
    }
    if (!permitirEditar) {
      switch (formControl) {
        case 'razonSocial':
          return this.crmservice.userLogin.rol === 'admin' || this.crmservice.userLogin.rol === 'supervisor' || this.crmservice.userLogin.rol === 'pedidos';
        case 'cif':
          return this.crmservice.userLogin.rol === 'admin' || this.crmservice.userLogin.rol === 'supervisor' || this.crmservice.userLogin.rol === 'pedidos';
        case 'extrangero':
          return this.crmservice.userLogin.rol === 'admin';
        default:
          return true;
      }
    } else {
      return true;
    }
  }
  public formControlDireccion(direccion: Direccion, formControl: string): boolean {
    switch (formControl) {
      case 'nombreComercial':
        if (direccion.tipoDir === '7' || direccion.tipoDir === '8') {
          return true;
        } else {
          return this.crmservice.userLogin.rol === 'admin' || this.crmservice.userLogin.rol === 'supervisor' || this.crmservice.userLogin.rol === 'pedidos';
        }
      case 'estado':
        return this.crmservice.userLogin.rol === 'admin' || this.crmservice.userLogin.rol === 'supervisor' || this.crmservice.userLogin.rol === 'pedidos';
      case 'ipyme':
        return this.crmservice.userLogin.rol === 'admin';
      case 'perfilTCLA':
        return this.crmservice.userLogin.rol === 'admin' || this.crmservice.userLogin.rol === 'supervisor';
      case 'verMapa':
        if (direccion.tipoDir === '7') {
          return true;
        } else {
          return this.crmservice.userLogin.rol === 'admin' || this.crmservice.userLogin.rol === 'supervisor';
        }
      case 'equipo':
        return this.crmservice.userLogin.rol === 'admin' || this.crmservice.userLogin.rol === 'supervisor' || this.crmservice.userLogin.rol === 'pedidos';
      case 'tipoDir':
        return this.crmservice.userLogin.rol === 'admin' || this.crmservice.userLogin.rol === 'supervisor' || this.crmservice.userLogin.rol === 'pedidos';
      case 'tipoVia':
        if (direccion.tipoDir === '7' || direccion.tipoDir === '8') {
          return true;
        } else {
          return this.crmservice.userLogin.rol === 'admin' || this.crmservice.userLogin.rol === 'supervisor' || this.crmservice.userLogin.rol === 'pedidos';
        }
      case 'direccion':
        if (direccion.tipoDir === '7' || direccion.tipoDir === '8') {
          return true;
        } else {
          return this.crmservice.userLogin.rol === 'admin' || this.crmservice.userLogin.rol === 'supervisor' || this.crmservice.userLogin.rol === 'pedidos';
        }
      case 'detalleDir':
        if (direccion.tipoDir === '7' || direccion.tipoDir === '8') {
          return true;
        } else {
          return this.crmservice.userLogin.rol === 'admin' || this.crmservice.userLogin.rol === 'supervisor' || this.crmservice.userLogin.rol === 'pedidos';
        }
      case 'codigoPostal':
        if (direccion.tipoDir === '7' || direccion.tipoDir === '8') {
          return true;
        } else {
          return this.crmservice.userLogin.rol === 'admin' || this.crmservice.userLogin.rol === 'supervisor' || this.crmservice.userLogin.rol === 'pedidos';
        }
      case 'poblacion':
        if (direccion.tipoDir === '7' || direccion.tipoDir === '8') {
          return true;
        } else {
          return this.crmservice.userLogin.rol === 'admin' || this.crmservice.userLogin.rol === 'supervisor' || this.crmservice.userLogin.rol === 'pedidos';
        }
      case 'provincia':
        if (direccion.tipoDir === '7' || direccion.tipoDir === '8') {
          return true;
        } else {
          return this.crmservice.userLogin.rol === 'admin' || this.crmservice.userLogin.rol === 'supervisor' || this.crmservice.userLogin.rol === 'pedidos';
        }
      case 'seguimiento':
        if (direccion.tipoDir === '7' || direccion.tipoDir === '8') {
          return true;
        } else {
          return this.crmservice.userLogin.rol === 'admin' || this.crmservice.userLogin.rol === 'supervisor' || this.crmservice.userLogin.rol === 'pedidos';
        }
      default:
        return true;
    }
  }

  public regenerarAlertas(): void {
    this.alertasCargadas = false;
    this.crmservice.revisarAlertasCliente(this.cliente._id).subscribe(() => {
      this.crmservice.crearAlertasCliente(this.cliente._id).subscribe(() => {
        this.crmservice.getAlertasClientes().subscribe(alertas => {
          this.crmservice.alertasClientes = alertas;
          this.alertasCargadas = true;
          this.alertasCliente = this.crmservice.alertasClientes.filter(e => e.cliente === this.cliente._id);

          let abrirAlertasDetalle = false;
          for (const alerta of this.alertasCliente) {
            if (alerta.visto.findIndex(e => e === this.crmservice.userLogin._id) === -1) {
              abrirAlertasDetalle = true;
            }
          }

          if (abrirAlertasDetalle) {
            this.alertasClienteDetalle();
          }
        });
      });
    });
  }


  /** FUNCIONES DE LOS FORMULARIOS **/
  public expandirDireccion(direccion: Direccion): boolean {
    if (this.direccionMal !== '' && direccion._id === this.direccionMal) {
      this.direccionMal = '';
      return true;
    } else if (direccion._id === this.route.snapshot.params.id && this.direccionMal === '') {
      return true;
    }
    return false;
  }
  public expandirPersona(personaID: string): boolean {
    return personaID === this.route.snapshot.params.persona;
  }
  public generarFormGroups(): void {
    for (const direccion of this.direcciones) {
      this.mensajeErrorDireccion.push({
        _id: direccion._id,
        mensajeError: '',
        tclaValido: true
      });
    }
    for (const persona of this.personas) {
      this.mensajeErrorPersonas.push({
        _id: persona._id,
        mensajeError: ''
      });
    }
  }
  public dameMensajeErrorDireccion(idDireccion: string): { _id: string, mensajeError: string, tclaValido: boolean } {
    const pos = this.mensajeErrorDireccion.findIndex(e => e._id === idDireccion);
    if (pos !== -1) {
      return this.mensajeErrorDireccion[pos];
    } else {
      return null;
    }
  }
  public dameMensajeErrorPersona(idPersona: string): { _id: string, mensajeError: string} {
    const pos = this.mensajeErrorPersonas.findIndex(e => e._id === idPersona);
    if (pos !== -1) {
      return this.mensajeErrorPersonas[pos];
    } else {
      return null;
    }
  }
  public cambiaCP(direccion: Direccion): void {
    if (direccion.codigoPostal.length === 5) {
      const pos = this.crmservice.codigosPostales.map((e) => {
        if (e.codigoPostal != null) {
          return this.crmservice.FormatNumberLength(e.codigoPostal, 5);
        }
      }).indexOf(direccion.codigoPostal);

      if (pos !== -1) {
        direccion.provincia = this.crmservice.codigosPostales[pos].provincia;
        direccion.poblacion = this.crmservice.codigosPostales[pos].poblacion;
      }
    }
  }
  public damePlaceholder(nameInput: string, direccion: Direccion): string {
    let placeholder = '';
    if ((direccion.estado === 'activo' || direccion.estado === 'potencial')) {
      switch (nameInput) {
        case 'perfilTCLA':
          placeholder = 'Perfil TECLA*';
          break;
        case 'telefono':
          placeholder = 'Teléfono*';
          break;
        case 'email':
          placeholder = 'Email*';
          break;
      }
    } else {
      switch (nameInput) {
        case 'perfilTCLA':
          placeholder = 'Perfil TECLA';
          break;
        case 'telefono':
          placeholder = 'Teléfono';
          break;
        case 'email':
          placeholder = 'Email';
          break;
      }
    }

    return placeholder;
  }
  public adirHorario(direccion: Direccion): void {
    direccion.horarios.push({
      titulo: '',
      estado: true,
      horaFinal: '',
      horaInicio: '',
      dias: ['']
    });
  }
  public borrarHorario(horario: { titulo: string; dias: [string]; horaInicio: string; horaFinal: string; estado: boolean }): void {
    horario.estado = false;
  }
  public accionesSinfinalizar(): string {
    let n = 0;

    for (const accion of this.acciones) {
      if (accion.estado !== '0') {
        n++;
      }
    }

    return n.toString(10);
  }
  public erDireccion(direccion: Direccion, accion: boolean): void {
    direccion.desactivado = accion;

    this.guardandoDatos = true;
    this.crmservice.updateDireccion(direccion).subscribe(
      () => {
        this.guardandoDatos = false;
      },
      () => {
        this.snackBar.open('Error al guardar la dirección', 'OK', {duration: 2000});
      },
      () => {
        this.snackBar.open('Dirección guardada correctamente', 'OK', {duration: 2000});
      });
  }
  public erPersona(persona: Persona, idDireccion: string): void {
    this.guardandoDatos = true;

    const pos = persona.direccion.findIndex(e => e === idDireccion);
    if (pos === -1) {
      persona.direccion.push(idDireccion);
    } else {
      persona.direccion.splice(pos, 1);
    }

    this.crmservice.updatePersona(persona).subscribe(
      () => {},
      error1 => {
        console.error(error1);
        this.snackBar.open('Error al guardar la persona de contacto', 'OK', {duration: 2000});
        this.guardandoDatos = false;
      },
      () => {
        this.snackBar.open('Persona de contacto guardada correctamente', 'OK', {duration: 2000});
        this.guardandoDatos = false;
      });
  }
  public desasignarPersonaDireccion(persona: Persona, idDireccion: string): void {
    this.guardandoDatos = true;
    this.crmservice.desasignarPersonaDireccion(persona._id, idDireccion).subscribe(
      () => {
        const pos = this.direcciones.findIndex(e => e._id === idDireccion);
        if (pos !== -1) {
          const direccion = this.direcciones[pos];

          const posPersona = direccion.personas.findIndex(e => e === persona._id);
          if (posPersona !== -1) {
            direccion.personas.splice(posPersona, 1);
          }
        }
        this.guardandoDatos = false;
      }, error1 => {
        console.error(error1);
        this.guardandoDatos = false;
      });
  }
  // Se considera que la persona está desactivada en la dirección si ésta no tiene el _id de la misma entre sus direcciones asociadas.
  public personaDesactivada(persona: Persona, direccion: Direccion): boolean {
    return persona.direccion.findIndex(e => e === direccion._id) === -1;
  }
  public licenciaActualizada(direccion: Direccion): boolean {
    // Sirve para saber si se debe mostrar el hito como dorado o como gris.
    const licencia = this.getLicenciasDireccion(direccion);
    if (licencia != null) {
      for (const equipo of licencia.equipos) {
        if (!this.fechaPasada(equipo)) {
          return true;
        }
      }
      return false;
    } else {
      return false;
    }
  }
  public getLicenciasUtilizadas(): number {
    let numLicencias = 0;

    for (const licenciasTienda of this.licencias) {
      for (const licencia of licenciasTienda.equipos) {
        if (!licencia.activaciones[licencia.activaciones.length - 1].caducada) {
          numLicencias++;
        }
      }
    }

    return numLicencias;
  }
  public comprobacionesCIF(cliente: Cliente, cif: string): string {
    if (cliente.esExtranjero == null || cliente.esExtranjero === 'noExtranjero') {
      cif = cif.toUpperCase().replace(/[\s\-]+/g, '');
      if (this.verificarCIF(cif)) {

/*        for (const clienteCIFRazonSocial of this.crmservice.clientesCIFRazonSocial) {
          if (clienteCIFRazonSocial != null
            && clienteCIFRazonSocial.cif.toUpperCase().replace(/[\s\-]+/g, '') === cif
            && cliente._id !== clienteCIFRazonSocial._id) {
            if (cliente.codigoCliente == null || !cliente.codigoCliente.trim().length) {
              return 'Ya hay otro cliente con este CIF (' + clienteCIFRazonSocial.razonSocial + '). ' +
                'Indica Código de Cliente para guardar utilizando éste CIF o indica un CIF distinto.';
            }
          }
        }*/
        return '';

      } else {
        return 'CIF incorrecto';
      }
    } else {
      return '';
    }
  }

  public adquirirAccion(accion: Accion): void {
    this.guardandoDatos = true;
    accion.estado = '2';
    accion.de = [this.crmservice.userLogin._id];
    this.crmservice.updateAccion(accion).subscribe(
      () => {

      }, () => {
        this.guardandoDatos = false;
        this.snackBar.open('Error al adquirir la acción', 'OK', {duration: 2000});
        accion.estado = '1';
      }, () => {
        this.snackBar.open('Acción adquirida correctamente', 'OK', {duration: 2000});
        this.guardandoDatos = false;
      });
  }
  public btnIpdormaRepetido(_idCli): void {
    const direccion = this.crmservice.direcciones.find(d => d._idCli === _idCli);
    if (direccion) {
      this.router.navigate(['/direcciones']).then(() => {
        this.router.navigate(['/direccion/' + direccion._id]);
      });
    }
  }

  /** FUNCIONES GENÉRICAS **/
  public fechaPasada(equipo): boolean {
    const fLimite = new Date(equipo.activaciones[equipo.activaciones.length - 1].fVencimiento);
    fLimite.setHours(0, 0, 0, 0);

    const hoy = new Date();
    hoy.setHours(0, 0, 0, 0);

    return fLimite.getTime() <= hoy.getTime();

  }

  public verificarCIF(nif: string): boolean {
    nif = nif.toUpperCase().replace(/[\s\-]+/g, '');

    // PARA DNI O NIE
    if (/^(\d|[XYZ])\d{7}[A-Z]$/.test(nif)) {
      let num = nif.match(/\d+/);
      // @ts-ignore
      num = (nif[0] != 'Z' ? nif[0] != 'Y' ? 0 : 1 : 2) + num;
      // @ts-ignore
      if (nif[8] == 'TRWAGMYFPDXBNJZSQVHLCKE'[num % 23]) {
        return true;
      }

      // PARA NIF - CIF
    } else if (/^[ABCDEFGHJKLMNPQRSUVW]\d{7}[\dA-J]$/.test(nif)) {
      let sum = 0;
      for (let i = 1; i < 8; ++i) {
        // @ts-ignore
        const num = nif[i] << i % 2;
        const uni = num % 10;
        sum += (num - uni) / 10 + uni;
      }
      const c = (10 - sum % 10) % 10;
      // @ts-ignore
      if (nif[8] == c || nif[8] == 'JABCDEFGHI'[c]) {
        return true;
      }
    }
    return false; // Si se introduce algo, debe ser correcto
  }
  public normalizarTexto(texto: string): string {
    if (texto != null) {
      return texto
        .normalize('NFD')
        .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, '$1$2')
        .normalize()
        .toUpperCase();
    }
    return null;
  }
}
