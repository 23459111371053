import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {CrmService} from '../../../../servicios/crm.service';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-busqueda-ipdorma',
  templateUrl: './busqueda-ipdorma.component.html',
  styleUrls: ['./busqueda-ipdorma.component.css']
})
export class BusquedaIpdormaComponent implements OnInit, AfterViewInit {

  public cargando: boolean;
  public textoBusqueda: string;
  public displayedColumns: string[];
  public dataSource: MatTableDataSource<any> = new MatTableDataSource();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.data = [];
  }

  constructor(public crmservice: CrmService,
              public dialogRef: MatDialogRef<BusquedaIpdormaComponent>) {
    this.cargando = false;
    this.textoBusqueda = '';
    this.displayedColumns = ['nombreComercial', 'codigo', 'razonSocial', 'cif', 'provincia', 'poblacion', 'codigoPostal'];
  }

  ngOnInit(): void {
  }

  public buscar(): void {
    this.cargando = true;
    this.crmservice.buscarIpdormas(this.textoBusqueda).subscribe(value => {
      this.dataSource.data = value;
      this.cargando = false;
    }, error1 => {
      console.error(error1);
      this.cargando = false;
    });
  }
  public seleccionarIpdorma(ipdorma: string): void {
    this.dialogRef.close(ipdorma);
  }
}
