<div class="cabecera-modal">
  <mat-icon class="icon-modal">store</mat-icon>
  <strong>BUSCAR IPDORMA</strong>
  <mat-icon class="cerrar-modal" [matDialogClose]>close</mat-icon>
</div>

<div class="content-modal p-3">
  <div class="input-group mb-3">
    <span class="input-group-text">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
      </svg>
    </span>
    <input [(ngModel)]="textoBusqueda" type="search" class="form-control" placeholder="Buscar..." (keydown.enter)="buscar()">
  </div>

  <div>
    <table class="overflow-auto w-100" mat-table #table [(dataSource)]="dataSource" matSortActive="codigo" matSortDirection="asc" matSort>
      <ng-container matColumnDef="nombreComercial">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre comercial</th>
        <td mat-cell *matCellDef="let cliente" class="nombreComercial">
          <b>{{cliente.nombreComercial}}</b>
        </td>
      </ng-container>

      <ng-container matColumnDef="codigo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Código cliente</th>
        <td mat-cell *matCellDef="let cliente">
          {{cliente.codigo}}
        </td>
      </ng-container>

      <ng-container matColumnDef="razonSocial">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Razón social</th>
        <td mat-cell *matCellDef="let cliente">
          {{cliente.razonSocial}}
        </td>
      </ng-container>

      <ng-container matColumnDef="cif">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> CIF</th>
        <td mat-cell *matCellDef="let cliente"> {{cliente.cif}}</td>
      </ng-container>

      <ng-container matColumnDef="poblacion">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Población</th>
        <td mat-cell *matCellDef="let cliente"> {{cliente.poblacion}}</td>
      </ng-container>

      <ng-container matColumnDef="provincia">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Provincia</th>
        <td mat-cell *matCellDef="let cliente"> {{cliente.provincia}}</td>
      </ng-container>

      <ng-container matColumnDef="codigoPostal">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Código postal</th>
        <td mat-cell *matCellDef="let cliente">
          {{cliente.codigoPostal}}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="seleccionarIpdorma(row._id)"></tr>
    </table>
  </div>

  <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 7, 10]"
                 [showFirstLastButtons]="true">
  </mat-paginator>
</div>

<div *ngIf="cargando" class="position-absolute start-50 top-50 translate-middle">
  <mat-spinner></mat-spinner>
</div>
