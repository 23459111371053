import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {CrmService} from '../../../servicios/crm.service';
import {Muestra} from '../../../modelos/Muestra';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {MediaMatcher} from '@angular/cdk/layout';
import {NuevaMuestraComponent} from './nueva-muestra/nueva-muestra.component';
import {Router} from '@angular/router';

@Component({
  selector: 'app-config-muestras',
  templateUrl: './config-muestras.component.html',
  styleUrls: ['./config-muestras.component.css']
})
export class ConfigMuestrasComponent implements OnInit {

  mobileQuery: MediaQueryList;
  private readonly _mobileQueryListener: () => void;

  public muestras: Muestra[];
  public tipoSeleccionado: string;

  public cargandoDatos: boolean;

  constructor(changeDetectorRef: ChangeDetectorRef,
              media: MediaMatcher,
              public crmservice: CrmService,
              private snackBar: MatSnackBar,
              public dialog: MatDialog,
              private router: Router) {

    this.cargandoDatos = true;

    this.mobileQuery = media.matchMedia('(max-width: 750px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    if (this.crmservice.userLogin == null || this.crmservice.userLogin.rol !== 'admin' && this.crmservice.userLogin.rol !== 'supervisor') {
      this.router.navigate(['agenda']).then();
    }

    this.tipoSeleccionado = 'grande';

    this.crmservice.dameMuestras().subscribe(
      muestras => {
        this.muestras = muestras;
      }, error => {
        console.error(error);
        this.snackBar.open('Error al cargar las muestras', 'OK', {duration: 3000});
      }, () => {
        this.cargandoDatos = false;
      });

  }

  ngOnInit() {
  }

  public dameNombreTipo(): string {
    const pos = this.crmservice.tiposMuestras.findIndex(e => e.id === this.tipoSeleccionado);
    if (pos !== -1) {
      return this.crmservice.tiposMuestras[pos].nombre;
    } else {
      return 'Desconocido';
    }
  }

  public setDescontinuada(muestra: Muestra): void {
    this.cargandoDatos = true;
    muestra.descontinuada = !muestra.descontinuada;
    this.crmservice.updateMuestra(muestra).subscribe(
      () => {
      }, error => {
        console.error(error);
        this.snackBar.open('Error al actualizar la muestra', 'OK', {duration: 3000});
        muestra.descontinuada = !muestra.descontinuada;
      }, () => {
        this.cargandoDatos = false;
      });
  }

  public nuevaMuestra(): void {
    let width: string;
    let height: string;

    if (!this.mobileQuery.matches) {
      width = '800px';
      height = '600px';
    } else {
      width = '95%';
      height = '95%';
    }

    const dialogRef = this.dialog.open(NuevaMuestraComponent, {
      width: width,
      height: height,
      panelClass: 'custom-dialog-container',
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data != null) {
        this.muestras.push(data);
      }
    });
  }
}
