import {Component, Inject, OnInit} from '@angular/core';
import {AlertaClienteConfig} from '../../../modelos/AlertaClienteConfig';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {CrmService} from '../../../servicios/crm.service';

interface ExcepcionRow {
  _idDir: string;
  _idCli: string;
  nombreComercial: string;
  razonSocial: string;
  clasificacion: string;
  equipo: string;
  estado: string;
}

@Component({
  selector: 'app-excepciones-alertas',
  templateUrl: './excepciones-alertas.component.html',
  styleUrls: ['./excepciones-alertas.component.css']
})
export class ExcepcionesAlertasComponent implements OnInit {

  public config: AlertaClienteConfig;
  public cargandoDatos: boolean;

  public dataSourceExcepciones: MatTableDataSource<ExcepcionRow> = new MatTableDataSource();
  public filtroExcepciones: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public crmservice: CrmService) {
    this.config = this.data.config;

    this.iniciarDataSource();

    this.cargandoDatos = false;
  }

  ngOnInit() {
  }

  public iniciarDataSource(): void {
    for (const excepcion of this.config.excepciones) {
      let pos: number;

      switch (this.config.ambito) {
        case 'direccion':
          pos = this.crmservice.direcciones.findIndex(e => e._id === excepcion);
          break;
        case 'cliente':
          pos = this.crmservice.direcciones.findIndex(e => e._idCli === excepcion);
          break;
        default:
          pos = -1;
      }

      if (pos !== -1) {
        const direccion = this.crmservice.direcciones[pos];

        this.dataSourceExcepciones.data.push({
          _idDir: direccion._id,
          _idCli: direccion._idCli,
          razonSocial: direccion.razonSocial,
          nombreComercial: direccion.nombreComercial,
          clasificacion: direccion.clasificacion,
          estado: direccion.estado,
          equipo: direccion.equipo
        });
      } else {
        this.dataSourceExcepciones.data.push({
          _idDir: this.config.ambito === 'direccion' ? excepcion : '',
          _idCli: this.config.ambito === 'cliente' ? excepcion : '',
          razonSocial: 'Desconocido',
          nombreComercial: 'Desconocido',
          clasificacion: 'Desconocido',
          estado: 'Desconocido',
          equipo: 'Desconocido'
        });
      }

    }
  }
  public applyFilter(): void {
    this.dataSourceExcepciones.filter = this.filtroExcepciones;
  }

  public getClasificacion(excepcionRow: ExcepcionRow): string {
    if (excepcionRow.clasificacion === 'A' || excepcionRow.clasificacion === 'C') {
      return excepcionRow.clasificacion;
    } else if (excepcionRow.clasificacion === 'B1' || excepcionRow.clasificacion === 'B2') {
      return 'B';
    } else {
      return '';
    }
  }
  public getColorClasificacion(clasificacion: string) {
    if (clasificacion === 'A') {
      return 'limegreen';
    }

    if (clasificacion === 'B1' || clasificacion === 'B2') {
      return 'gold';
    }

    if (clasificacion === 'C') {
      return 'brown';
    }

    return 'pink';
  }
  public getIcono(excepcionRow: ExcepcionRow): string {
    if (excepcionRow.estado != null && excepcionRow.equipo != null) {
      return '../../assets/img/markers/' + excepcionRow.equipo + '/' + excepcionRow.estado + '.png';
    } else {
      return 'Desconocido';
    }
  }

  public abrirCliente(excepcionRow: ExcepcionRow): void {
    window.open(this.crmservice.urlCRM2 + '/direccion/' + excepcionRow._idDir);
  }

  public removeExcepcion(excepcion: string): void {
    return;
  }
}
