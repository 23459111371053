import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {CrmService} from '../../../../servicios/crm.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {UsuariosComponent} from '../../../usuarios/usuarios.component';
import {Direccion} from '../../../../modelos/Direccion';
import {MediaMatcher} from '@angular/cdk/layout';

@Component({
  selector: 'app-contrato-wallviewer-formulario',
  templateUrl: './contrato-wallviewer-formulario.component.html',
  styleUrls: ['./contrato-wallviewer-formulario.component.css']
})
export class ContratoWallviewerFormularioComponent implements OnInit {

  mobileQuery: MediaQueryList;
  private readonly _mobileQueryListener: () => void;

  public guardandoDatos: boolean;
  public direccion: Direccion;

  public docToUpload: File = null;

  constructor(public crmservice: CrmService,
              public snackBar: MatSnackBar,
              public dialogRef: MatDialogRef<UsuariosComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              changeDetectorRef: ChangeDetectorRef,
              media: MediaMatcher) {

    this.mobileQuery = media.matchMedia('(max-width: 750px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.guardandoDatos = false;
    this.direccion = this.data.direccion;
  }

  ngOnInit() {
  }

  public verContrato(): void {
    window.open(this.crmservice.urlCRM + '/doc/contratos/' + this.direccion.contratoWV.documento, '_blank');
    // return this.sanitizer.bypassSecurityTrustResourceUrl();
  }

  public abrirInput(): void {
    document.getElementById('contratoWV').click();
  }

  public subirPDF(files: FileList): void {
    this.guardandoDatos = true;
    this.docToUpload = files.item(0);

    const name = Math.random().toString(36).substr(2) + '.' + this.docToUpload.name.split('.').pop();

    this.crmservice.subirContrato(this.docToUpload, name).subscribe(
      () => {
        this.direccion.contratoWV.documento = name;
      }, () => {
        this.guardandoDatos = false;
        this.snackBar.open('Error al subir contrato', 'OK', {duration: 2000});

      }, () => {
        this.guardarContrato();
      }
    );
  }

  public borrarContrato(): void {
    this.guardandoDatos = true;

    this.direccion.contratoWV.documento = null;
    this.guardarContrato();
  }

  public guardarContrato(): void {
    this.guardandoDatos = true;
    this.crmservice.updateDireccion(this.direccion).subscribe(
      () => {

      }, () => {
        this.guardandoDatos = false;
        this.snackBar.open('Error al guardar contrato', 'OK', {duration: 2000});
      }, () => {
        this.guardandoDatos = false;
        this.snackBar.open('Contrato guardado correctamente', 'OK', {duration: 2000});
      }
    );
  }
}
