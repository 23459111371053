import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { Usuario } from '../modelos/Usuario';
import { Direccion } from '../modelos/Direccion';
import { Accion } from '../modelos/Accion';
import { CodigoPostal } from '../modelos/CodigoPostal';
import { MatTableDataSource } from '@angular/material/table';
import { Licencia } from '../modelos/Licencia';
import { Cliente } from '../modelos/Cliente';
import { ConfiguracionLicencias } from '../modelos/ConfiguracionLicencias';
import { Actualizacion } from '../modelos/Actualizacion';
import { ConfigDNIeTienda } from '../modelos/ConfigDNIeTienda';
import { Muestra } from '../modelos/Muestra';
import { AlertaCliente } from '../modelos/AlertaCliente';
import { AlertaClienteConfig } from '../modelos/AlertaClienteConfig';
import { ClienteCIFRazonSocial, ClientesIpdormasCIF, ConfigPopUpConfirmacion } from '../modelos/Interfaces';
import { BBDD } from '../json/BBDD';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogConfirmacionComponent } from '../componentes/dialog-confirmacion/dialog-confirmacion.component';

@Injectable()
export class CrmService {

  public readonly version = 'v2.10.18';

  public userCheckActualizacion: boolean;
  public datosCargados = false;

  // Atributos de la tabla de direcciones. Se guardan aquí para
  // que estén presentes cuando se vuelva a la tabla.
  public filtroClientes = '';
  public equipoMostrarClientes: string;
  public clasificacionMostrarClientes: string;
  public estadoMostrarClientes: string;
  public tipoMostrarClientes: string;
  public categoriaMostrarClientes: string;

  public paginatorPage: number;
  public matSortActive = 'ultimaAccion';
  public matSortDirection = 'desc';

  public userLogin: Usuario;

  public numPreguntas: number;
  public numIpdormas: number;

  // Para desarrollo local
  // public urlCRM = 'https://localhost:3008';  // CRM Backend
  // public urlCRM2 = 'https://localhost:4400'; //CRM Frontend
  // public urlCMI = 'https://localhost:3009';
  // public urlBalder = 'https://localhost:3010';
  // public urlTCLA = 'https://localhost:3006'; 

  public modoPruebas: boolean; // Ahora mismo sólo se usa para activar o desactivar el gestor de tiempos

  // Para producción
  public urlCRM = 'https://mueblesdormacrm.com:3008';
  public urlCRM2 = 'https://mueblesdormacrm.com'; // Modicado por el cambio de servidor. Ahora se usará el puerto 443. Anteriormente https://mueblesdormacrm.com:444
  public urlCMI = 'https://mueblesdormacrm.com:3009';
  public urlTCLA = 'https://catalogotecla.com';
  public urlBalder = 'https://mueblesdormacrm.com:3010';
  
  public dataSourceUsuarios: any;
  public dataSourceDirecciones: any;

  public usuarios: Usuario[];
  public direcciones = [];
  public acciones: Accion[];
  public codigosPostales: CodigoPostal[];
  public clientesCIFRazonSocial: ClienteCIFRazonSocial[] = [];
  public clientesIpdormaCif: ClientesIpdormasCIF[] = [];
  public alertasClientes: AlertaCliente[];

  public rankingMostrar = 0;

  public direccionSeleccionada = [];
  public dataSource: MatTableDataSource<Direccion> = new MatTableDataSource();

  public readonly bloquesFormacion3D = BBDD.BloquesFormacion;
  public readonly cargos = BBDD.Cargos;
  public readonly dias = BBDD.Dias;
  public readonly equipos = BBDD.Equipos;
  public readonly estados = BBDD.Estados;
  public readonly hitos = BBDD.Hitos;
  public readonly horas = BBDD.Horas;
  public readonly medios = BBDD.Medios;
  public readonly motivosPosponer = BBDD.MotivosPosponer;
  public readonly objetivos = BBDD.Objetivos;
  public readonly objetivosTienda = BBDD.ObjetivosTienda;
  public readonly objetivosDV = BBDD.ObjetivosDV;
  public readonly opcionesRankingMostrar = BBDD.OpcionesRankingMostrar;
  public readonly provincias = BBDD.Provincias;
  public readonly provinciasEquipos = BBDD.ProvinciasEquipos;
  public readonly resultados = BBDD.Resultados;
  public readonly roles = BBDD.Roles;
  public readonly tiposCliente = BBDD.TiposCliente;
  public readonly categoriasCliente = BBDD.CategoriasCliente;
  public readonly tiposDir = BBDD.TiposDir;
  public readonly tiposDirNCli = BBDD.TiposDirNCli;
  public readonly tiposMuestras = BBDD.TiposMuestras;
  public readonly tratamientos = BBDD.Tratamientos;
  public readonly vias = BBDD.Vias;

  constructor(public http: HttpClient,
    public dialog: MatDialog) {

    console.log('CrmService');

    this.userCheckActualizacion = true;
    this.direccionSeleccionada = [false];
    this.numPreguntas = 0;
    this.numIpdormas = 0;
  }

  // ACCIONES
  public setAccion(accion): Observable<any> {
    return this.http.post(this.urlCRM + '/acciones/setAccion', accion);
  }
  public getAccionesUser(usuario: string): Observable<any> {
    return this.http.post(this.urlCRM + '/acciones/getAccionesUser', { usuario: usuario });
  }
  public getAccionesPendientes(usuarioSeleccionadoAgenda: string): Observable<any> {
    return this.http.post(this.urlCRM + '/acciones/getAccionesPendientes/' + usuarioSeleccionadoAgenda, null);
  }
  public updateAccion(accion) {
    return this.http.post(this.urlCRM + '/acciones/updateAccion', accion);
  }
  public getAccionesFormacionPersona(idPersona: string): Observable<any> {
    return this.http.post(this.urlCRM + '/acciones/accionesFormacionPersona', { idPersona: idPersona });
  }
  public accionesFormacionAutom(accionTeoria: any, accionPractica: any): Observable<any> {
    return this.http.post(this.urlCRM + '/acciones/accionesFormacionAutom', { accionTeoria: accionTeoria, accionPractica: accionPractica });
  }
  public accionesAlertaClientes(): Observable<any> {
    return this.http.get(this.urlCRM + '/acciones/accionesAlertaClientes');
  }

  // ACTUALIZACIONES
  public getUserCheckedActualizacion(version: string, usuario: string): Observable<any> {
    return this.http.post(this.urlCRM + '/actualizaciones/getUserCheckedActualizacion', { version: version, usuario: usuario });
  }
  public setUserCheckedActualizacion(version: string, usuario: string): Observable<any> {
    return this.http.post(this.urlCRM + '/actualizaciones/setUserCheckedActualizacion', { version: version, usuario: usuario });
  }
  public getListVersiones(): Observable<any> {
    return this.http.get(this.urlCRM + '/actualizaciones/getListVersiones/' + this.version);
  }
  public nuevaActualizacion(actualizacion: Actualizacion): Observable<any> {
    return this.http.post(this.urlCRM + '/actualizaciones/nuevaActualizacion', { actualizacion: actualizacion });
  }

  // ALERTAS CLIENTES CONSUMO
  public getAlertasClientesConsumo(): Observable<any> {
    return this.http.get(this.urlCRM + '/alertaClienteConsumo/getAlertasClienteConsumo');
  }

  // ALERTAS CLIENTES
  public getAlertaClienteConfig(): Observable<any> {
    return this.http.get(this.urlCRM + '/alertaCliente/getAlertaClienteConfig');
  }
  public getAlertasClientes(): Observable<any> {
    return this.http.get(this.urlCRM + '/alertaCliente/getAlertasClientes');
  }
  public updateAlertaClienteConfig(configuracion: AlertaClienteConfig[]): Observable<any> {
    return this.http.post(this.urlCRM + '/alertaCliente/updateAlertaClienteConfig', { configs: configuracion });
  }
  public clearAlertasClientes(): Observable<any> {
    return this.http.get(this.urlCRM + '/alertaCliente/clearAlertasClientes');
  }
  public crearAlertasClientes(): Observable<any> {
    return this.http.get(this.urlCRM + '/alertaCliente/crearAlertasClientes');
  }
  public crearAlertasCliente(clienteId: string): Observable<any> {
    return this.http.post(this.urlCRM + '/alertaCliente/crearAlertasCliente', { clienteId: clienteId });
  }
  public addVistoAlertas(alertas: string[]): Observable<any> {
    return this.http.post(this.urlCRM + '/alertaCliente/addVistoAlertas', { alertas: alertas, idUser: this.userLogin._id });
  }
  public addGestionado(idAlerta: string): Observable<any> {
    return this.http.post(this.urlCRM + '/alertaCliente/addGestionado', { idAlerta: idAlerta, idUser: this.userLogin._id });
  }
  public removeGestionado(idAlerta: string): Observable<any> {
    return this.http.post(this.urlCRM + '/alertaCliente/removeGestionado', { idAlerta: idAlerta });
  }
  public revisarAlertasClientes(): Observable<any> {
    return this.http.get(this.urlCRM + '/alertaCliente/revisarAlertasClientes');
  }
  public revisarAlertasCliente(idCliente: string): Observable<any> {
    return this.http.post(this.urlCRM + '/alertaCliente/revisarAlertasCliente', { idCliente: idCliente });
  }
  public addExcepcionAlerta(idAlerta): Observable<any> {
    return this.http.post(this.urlCRM + '/alertaCliente/addExcepcion', { idAlerta: idAlerta });
  }

  // CAJAS DE MUESTRAS
  public getCajasMuestrasDireccion(direccion): Observable<any> {
    return this.http.get(this.urlCRM + '/cajaMuestras/getCajasMuestras/' + direccion._id);
  }
  public nuevaCajaMuestras(cajaMuestras): Observable<any> {
    return this.http.post(this.urlCRM + '/cajaMuestras/nuevaCajaMuestras', { cajaMuestras });
  }
  public updateCajaMuestras(cajaMuestras): Observable<any> {
    return this.http.post(this.urlCRM + '/cajaMuestras/updateCajaMuestras', { cajaMuestras });
  }

  // CLIENTE_IPDORMA
  public buscarIpdormas(dato: string): Observable<any> {
    return this.http.get(this.urlCRM + '/ipdorma/getCliMin/' + dato);
  }
  public countCliente_Ipdorma(): Observable<any> {
    return this.http.get(this.urlCRM + '/cliente_ipdorma/countCliente_Ipdorma');
  }
  public getAllCliente_Ipdorma(ignorar: boolean | null = null): Observable<any> {
    return this.http.get(this.urlCRM + '/cliente_ipdorma/getAllCliente_Ipdorma' + ((ignorar != null) ? ('?ignorar=' + ignorar) : ''));
  }
  public getCliente_Ipdorma(ipdorma: string): Observable<any> {
    return this.http.get(this.urlCRM + '/cliente_ipdorma/getCliente_Ipdorma/' + ipdorma);
  }
  public getIpdormasCliente(cliente: string): Observable<any> {
    return this.http.get(this.urlCRM + '/cliente_ipdorma/getIpdormasCliente/' + cliente);
  }
  public getIpdormasClienteMin(cliente: string): Observable<any> {
    return this.http.get(this.urlCRM + '/cliente_ipdorma/getIpdormasClienteMin/' + cliente);
  }
  public linkIpdormaCliente(cliente: string, ipdorma: string): Observable<any> {
    return this.http.post(this.urlCRM + '/cliente_ipdorma/linkIpdormaCliente', { cliente: cliente, ipdorma: ipdorma });
  }
  public unlinkIpdormaCliente(cliente: string, ipdorma: string): Observable<any> {
    return this.http.post(this.urlCRM + '/cliente_ipdorma/unlinkIpdormaCliente', { cliente: cliente, ipdorma: ipdorma });
  }
  public setCliente_IpdormaIgnorado(ipdorma: string, ignorar: boolean = false): Observable<any> {
    return this.http.get(this.urlCRM + '/cliente_ipdorma/setCliente_IpdormaIgnorado?ipdorma=' + ipdorma + '&ignorar=' + ignorar);
  }
  public getAllCifsClientesIpDorma(): Observable<any> {
    return this.http.get(this.urlCRM + '/cliente_ipdorma/getAllCifsClientesIpDorma');
  }
  public getClientesRelacionIpDormaById(id_ipDorma: string): Observable<any> {
    return this.http.post(this.urlCRM + '/cliente_ipdorma/getClientesRelacionIpDormaById', { data : id_ipDorma});
  }

  // CLIENTES
  public nuevoCliente(cliente: Cliente, direccion: Direccion): Observable<any> {
    return this.http.post(this.urlCRM + '/clientes/nuevoClienteN', { cliente: cliente, direccion: direccion });
  }
  public updateDireccion(datos): Observable<any> {
    return this.http.post(this.urlCRM + '/clientes/updateDireccion', datos);
  }
  public updateClienteDirecciones(cliente: Cliente, direcciones: Direccion[]): Observable<any> {
    return this.http.post(this.urlCRM + '/clientes/updateClienteDirecciones', { cliente: cliente, direcciones: direcciones });
  }
  public updatePersona(datos): Observable<any> {
    return this.http.post(this.urlCRM + '/clientes/updatePersona', datos);
  }
  public nuevaDireccion(direccion: Direccion): Observable<any> {
    return this.http.post(this.urlCRM + '/clientes/nuevaDireccionN', { direccion: direccion });
  }
  public nuevaPersona(datos): Observable<any> {
    return this.http.post(this.urlCRM + '/clientes/nuevaPersona', datos);
  }
  public getClienteCompletoN(idDireccion: string) {
    return this.http.get(this.urlCRM + '/clientes/getClienteCompletoN/' + idDireccion);
  }
  public getClienteCompletoAccionN(idAccion: string) {
    return this.http.get(this.urlCRM + '/clientes/getClienteCompletoAccionN/' + idAccion);
  }
  public getCliente(id: string) {
    return this.http.get(this.urlCRM + '/contacts/' + id);
  }
  public updateFicherosPersona(idPersona: string, ficheros: any): Observable<any> {
    return this.http.post(this.urlCRM + '/clientes/updateFicherosPersona', { idPersona: idPersona, ficheros: ficheros });
  }
  public updatePerfilPersona(personaId: string, ficheroId: string): Observable<any> {
    return this.http.post(this.urlCRM + '/clientes/updatePerfilPersona', { personaId: personaId, ficheroId: ficheroId });
  }
  public dameClientesCIFRazonSocial(): Observable<any> {
    return this.http.get(this.urlCRM + '/clientes/CIFRazonSocial');
  }
  public generarDiploma(persona): Observable<any> {
    return this.http.post(this.urlCRM + '/clientes/generarDiploma', { contacto: persona });
  }
  public buscarPersona(patron: string): Observable<any> {
    return this.http.post(this.urlCRM + '/clientes/buscarPersona', { patron: patron });
  }
  public asignarPersonaDireccion(idPersona: string, idDireccion: string, idCliente: string): Observable<any> {
    return this.http.post(this.urlCRM + '/clientes/asignarPersonaDireccion', { idPersona: idPersona, idDireccion: idDireccion, idCliente: idCliente });
  }
  public desasignarPersonaDireccion(idPersona: string, idDireccion: string): Observable<any> {
    return this.http.post(this.urlCRM + '/clientes/desasignarPersonaDireccion', { idPersona: idPersona, idDireccion: idDireccion });
  }
  public actualizarLicenciasDisponibles(cliente: Cliente): Observable<any> {
    return this.http.post(this.urlCRM + '/clientes/actualizarLicenciasDisponibles', { cliente: cliente });
  }
  public getPreguntasClientes(): Observable<any> {
    return this.http.get(this.urlCRM + '/clientes/getPreguntasClientes');
  }
  public getNumPreguntasPendientes(): Observable<any> {
    return this.http.get(this.urlCRM + '/clientes/getNumPreguntasPendientes');
  }
  public asignarPreguntaUsuarioCRM(idPregunta: string, idCRM: string): Observable<any> {
    return this.http.post(this.urlCRM + '/clientes/asignarPreguntaUsuarioCRM', { idPregunta: idPregunta, idCRM: idCRM });
  }
  public anadirIntervencionAPregunta(preguntaId: string, pregunta: string): Observable<any> {
    return this.http.post(this.urlCRM + '/clientes/anadirIntervencionAPregunta', { preguntaId: preguntaId, pregunta: pregunta });
  }

  // CODIGOS POSTALES
  public getAllCP(): Observable<any> {
    return this.http.get(this.urlCRM + '/codigoPostal/getAllCP');
  }
  public asignarCPFisico(codigosPostales: any, idDireccion: string, perfilTCLA: string): Observable<any> {
    return this.http.post(this.urlCRM + '/codigoPostal/asignarCPFisico', [codigosPostales, idDireccion, perfilTCLA]);
  }
  public desasignarCPFisico(codigosPostales: any, idDireccion: string): Observable<any> {
    return this.http.post(this.urlCRM + '/codigoPostal/desasignarCPFisico', [codigosPostales, idDireccion]);
  }
  public asignarCPVirtual(codigosPostales: any, idDireccion: string, perfilTCLA: string): Observable<any> {
    return this.http.post(this.urlCRM + '/codigoPostal/asignarCPVirtual', [codigosPostales, idDireccion, perfilTCLA]);
  }
  public desasignarCPVirtual(codigosPostales: any, idDireccion: string): Observable<any> {
    return this.http.post(this.urlCRM + '/codigoPostal/desasignarCPVirtual', [codigosPostales, idDireccion]);
  }
  public cambiarColorAMUEBLANDO(color: string, idDireccion: string): Observable<any> {
    return this.http.post(this.urlCRM + '/codigoPostal/cambiarColorAMUEBLANDO', { color: color, idDireccion: idDireccion });
  }
  public generarProtocoloDV(idDireccion: string): Observable<any> {
    return this.http.post(this.urlCRM + '/codigoPostal/generarProtocoloDV', { idDireccion: idDireccion });
  }
  public eliminarProtocoloDV(idDireccion: string): Observable<any> {
    return this.http.post(this.urlCRM + '/codigoPostal/eliminarProtocoloDV', { idDireccion: idDireccion });
  }

  // CONFIGIGURACIÓN DNIe TIENDAS
  public getAllConfigDNIeTienda(): Observable<any> {
    return this.http.get(this.urlCRM + '/configDNIeTiendas/getAllConfigDNIeTienda');
  }
  public nuevaConfigDNIeTienda(config: any): Observable<any> {
    return this.http.post(this.urlCRM + '/configDNIeTiendas/nuevaConfigDNIeTienda', { config: config });
  }
  public updateConfigDNIeTienda(config: ConfigDNIeTienda): Observable<any> {
    return this.http.post(this.urlCRM + '/configDNIeTiendas/updateConfigDNIeTienda', { config: config });
  }

  // CONFIG LICENCIAS
  public nuevaConfiguracionLicencias(config: ConfiguracionLicencias): Observable<any> {
    return this.http.post(this.urlCRM + '/configuracionLicencias/nuevaConfiguracionLicencias', { config: config });
  }
  public updateConfiguracionLicencias(config: ConfiguracionLicencias): Observable<any> {
    return this.http.post(this.urlCRM + '/configuracionLicencias/updateConfiguracionLicencias', { config: config });
  }
  public getConfiguracionLicencias(): Observable<any> {
    return this.http.get(this.urlCRM + '/configuracionLicencias/getConfiguracionLicencias');
  }


  // CONFIG RANKING
  public nuevaConfiguracionRanking(config): Observable<any> {
    return this.http.post(this.urlCRM + '/configuracionRanking/nuevaConfiguracionRanking', { config: config });
  }
  public getConfiguracionRanking(): Observable<any> {
    return this.http.get(this.urlCRM + '/configuracionRanking/getConfiguracionRanking');
  }
  public actualizarConfiguracionRanking(config): Observable<any> {
    return this.http.post(this.urlCRM + '/configuracionRanking/actualizarConfiguracionRanking', { config: config });
  }
  public recalcularRankings(): Observable<any> {
    return this.http.get(this.urlCRM + '/configuracionRanking/recalcularRankings');
  }
  public recalcularRanking(elemento): Observable<any> {
    return this.http.post(this.urlCRM + '/configuracionRanking/recalcularRanking', { elemento: elemento, userId: this.userLogin._id });
  }

  // DIRECCIONES
  public getInicioApp(): Observable<any> {
    return this.http.get(this.urlCRM + '/direcciones/iniciar');
  }
  public getDirecciones(id): Observable<any> {
    return this.http.get(this.urlCRM + '/direcciones/getDirecciones/' + id);
  }
  public getTodasDireccionesTiendaComercialTieneInfluencerConDatosCliente(): Observable<any> {
    return this.http.get(this.urlCRM + '/direcciones/getTodasDireccionesTiendaComercialTieneInfluencerConDatosCliente');
  }
  public correoHitoAMUEBLANDO(email: string): Observable<any> {
    return this.http.post(this.urlCRM + '/direcciones/correoHitoAMUEBLANDO', { email: email });
  }
  public subirImagenDireccion(fichero: File, nombre: string): Observable<any> {
    const fd = new FormData();
    fd.append('image', fichero, nombre);

    return this.http.post(this.urlCRM + '/direcciones/fileDireccion', fd);
  }
  public subirContrato(fichero: File, nombre: string): Observable<any> {
    const fd = new FormData();
    fd.append('contrato', fichero, nombre);

    return this.http.post(this.urlCRM + '/direcciones/fileContrato', fd);
  }

  // ELEMENTOS DEL RANKING
  public getAllElementosRanking(): Observable<any> {
    return this.http.get(this.urlCRM + '/elementosRanking/getAllElementosMostrar');
  }
  public getElementosAutom(): Observable<any> {
    return this.http.get(this.urlCRM + '/elementosRanking/getElementosAutomMostrar');
  }
  public getElementosManual(): Observable<any> {
    return this.http.get(this.urlCRM + '/elementosRanking/getElementosManualMostrar');
  }

  // JORNADA LABORAL
  public getJornadasLaboralesUsuario(userId): Observable<any> {
    return this.http.get(this.urlCRM + '/jornadasLaborales/getJornadasLaboralesUsuario/' + userId);
  }
  public getJornadasRango(usuario: string, inicio: Date, fin: Date): Observable<any> {
    return this.http.post(this.urlCRM + '/jornadasLaborales/getJornadasRango', { usuario: usuario, inicio: inicio, fin: fin });
  }

  // LICENCIAS
  public nuevaLicencia(licencia: Licencia): Observable<any> {
    return this.http.post(this.urlCRM + '/licencias/nuevaLicencia', { licencia: licencia });
  }
  public actualizarLicencia(licencia: Licencia): Observable<any> {
    return this.http.post(this.urlCRM + '/licencias/actualizarLicencia', { licencia: licencia });
  }
  public addReferenciaEquipo(idLicencia: string, aliasEquipo: string, referencia: string): Observable<any> {
    return this.http.post(this.urlCRM + '/licencias/addReferenciaEquipo', { idLicencia: idLicencia, aliasEquipo: aliasEquipo, referencia: referencia });
  }

  // MODO PRUEBAS
  public cambiarModoPruebas(): Observable<any> {
    return this.http.get(this.urlCRM + '/modoPruebas/cambiarEstado');
  }

  // MUESTRAS
  public nuevaMuestra(muestra: Muestra): Observable<any> {
    return this.http.post(this.urlCRM + '/muestra/nuevaMuestra', { muestra: muestra });
  }
  public dameMuestras(): Observable<any> {
    return this.http.post(this.urlCRM + '/muestra/dameTodas', {});
  }
  public updateMuestra(muestra: Muestra): Observable<any> {
    return this.http.post(this.urlCRM + '/muestra/update', { muestra: muestra });
  }
  public subirImagenMuestra(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post(this.urlCRM + '/muestra/subirImagen', formData);
  }

  // PERSONA
  public subirFicherosPersona(files, nombres): Observable<any> {
    const formData: FormData = new FormData();

    let i = 0;
    for (const file of files) {
      formData.append('file', file, nombres[i]);
      i++;
    }

    return this.http.post(this.urlCRM + '/personas/subirFichero', formData, { reportProgress: true });
  }

  // REGISTRO TIEMPO
  public getRegistrosTiempoUsuario(userId): Observable<any> {
    return this.http.get(this.urlCRM + '/registrosTiempo/getRegistrosTiempoUsuario/' + userId);
  }

  // USERS
  public login(nombreUsuario: string, password: string, recuerdame: boolean): Observable<any> {
    return this.http.post(this.urlCRM + '/users/loginCRM', { nombreUsuario: nombreUsuario, password: password, recuerdame: recuerdame });
  }
  public setUltimaConexion(idUser: string): Observable<any> {
    return this.http.post(this.urlCRM + '/users/setUltimaConexion', { idUser: idUser });
  }
  public getUser(_id: string): Observable<any> {
    return this.http.post(this.urlCRM + '/users/getUsuario', { 'id': _id });
  }
  public newUser(usuarioEditar: Usuario): Observable<any> {
    return this.http.post(this.urlCRM + '/users/newUser', { nuevo: usuarioEditar });
  }
  public updateUser(usuarioEditar: Usuario): Observable<any> {
    return this.http.post(this.urlCRM + '/users/updateUser', { usuario: usuarioEditar });
  }
  public updatePassword(nombreUsuario: string, password: string): Observable<any> {
    return this.http.post(this.urlCRM + '/users/updatePassword', { nombreUsuario: nombreUsuario, password: password });
  }
  public updateImagenUser(nombreUsuario: string, imgPath: string): Observable<any> {
    return this.http.post(this.urlCRM + '/users/updateImagen', { nombreUsuario: nombreUsuario, imgPath: imgPath });
  }
  public subirImagenUsuario(fichero: File, nombre: string): Observable<any> {
    const fd = new FormData();
    fd.append('image', fichero, nombre);

    return this.http.post(this.urlCRM + '/users/fileUsers', fd);
  }
  public modificarEstadoUsuario(nombreUsuario: string, estado: boolean): Observable<any> {
    return this.http.post(this.urlCRM + '/users/modificarEstadoUsuario', { nombreUsuario: nombreUsuario, estado: estado });
  }

  // TCLA
  public getDireccionesPerfilTCLA(perfilTCLA: string): Observable<any> {
    return this.http.get(this.urlCRM + '/direcciones/getDireccionesPerfilTCLA/' + perfilTCLA);
  }
  public actualizarPerfilTecla(datos: any): Observable<any> {
    // Actualiza la información de la dirección al activar el perfil TECLA.
    return this.http.post(this.urlCRM + '/direcciones/actualizarPerfilTCLA_N', { datos: datos });
  }
  public activarUsuarioTECLA(usuario: string, puntoCompra: number): Observable<any> {
    return this.http.post(this.urlTCLA + '/activarUsuario', [usuario, puntoCompra, true, 'otro']);
  }
  public enviarMailActivacionTECLA(usuario: string, email: string, puntoCompra: number): Observable<any> {
    return this.http.get(this.urlTCLA + '/correo/' + usuario + '/' + email + '/' + puntoCompra);
  }
  public getFactorConversion(perfilTCLA: string) {
    return this.http.get(this.urlTCLA + '/getUsuario/' + perfilTCLA);
  }
  public modificarEstadoUsuarioTECLA(perfilTECLA: string, estado: boolean): Observable<any> {
    return this.http.put(this.urlTCLA + '/modificarestadousuario/' + perfilTECLA + '/' + estado, {});
  }
  public getUsuariosDistribuidores(provincia: string | null = null): Observable<any> {
    const url = provincia ? ('/dameUsuariosDistribuidoresCRM/' + provincia) : '/dameUsuariosDistribuidoresCRM';
    return this.http.get(this.urlTCLA + url);
  }
  public getUsuarioTeclaParaInfluencersComercialesCRM(cif: string): Observable<any> {
    return this.http.post(this.urlTCLA + '/getUsuarioTeclaParaInfluencersComercialesCRM', {cif: cif});
  }
  public asignarCPTCLA(codigosPostales: any, perfilTCLA: string): Observable<any> {
    return this.http.post(this.urlTCLA + '/asignarCPTCLA', [codigosPostales, perfilTCLA]);
  }
  public desasignarCPTCLA(codigosPostales: any, perfilTCLA: string): Observable<any> {
    return this.http.post(this.urlTCLA + '/desasignarCPTCLA', [codigosPostales, perfilTCLA]);
  }
  public activarAmueblando(perfilTCLA: string): Observable<any> {
    return this.http.get(this.urlTCLA + '/activarAmueblando/' + perfilTCLA);
  }
  public activarPrevirtual(perfilTCLA: string): Observable<any> {
    return this.http.get(this.urlTCLA + '/activarPrevirtual/' + perfilTCLA);
  }
  public desactivarAmueblando(perfilTCLA: string): Observable<any> {
    return this.http.get(this.urlTCLA + '/desactivarAmueblando/' + perfilTCLA);
  }
  public activar3D(perfilTCLA: string): Observable<any> {
    return this.http.get(this.urlTCLA + '/activar3D/' + perfilTCLA);
  }
  public desactivar3D(perfilTCLA: string): Observable<any> {
    return this.http.get(this.urlTCLA + '/desactivar3D/' + perfilTCLA);
  }
  public crearValeVIP(promocionVIP: any, perfilTECLA: any): Observable<any> {
    return this.http.post(this.urlTCLA + '/usuario/crearPromocionVipDV', { promocionVIP: promocionVIP, perfilTECLA: perfilTECLA });
  }
  public borrarValeVIP(promocionVIP: any, perfilTECLA: any): Observable<any> {
    return this.http.post(this.urlTCLA + '/usuario/borrarPromocionVipDV', { promocionVIP: promocionVIP, perfilTECLA: perfilTECLA });
  }

  // BALDER
  public balder_nuevoUsuario(usuario): Observable<any> {
    return this.http.post(this.urlBalder + '/usuarios/nuevoUsuario', { usuario: usuario });
  }
  public balder_getAllUsuarios(): Observable<any> {
    return this.http.get(this.urlBalder + '/usuarios/getAllUsuarios');
  }
  public balder_updateUsuario(usuario, updatePass): Observable<any> {
    return this.http.post(this.urlBalder + '/usuarios/updateUsuario', { usuario: usuario, updatePass: updatePass });
  }

  public getPPM(): Observable<any> {
    return this.http.get(this.urlCRM + '/ppm');
  }

  // OTRAS
  public popUpConfirmacion(config: ConfigPopUpConfirmacion): MatDialogRef<DialogConfirmacionComponent> {
    return this.dialog.open(DialogConfirmacionComponent, {
      width: '500px',
      maxWidth: '100%',
      panelClass: 'custom-dialog-container',
      data: config
    });
  }

  //Encargos

  public getTodosPresupuestosPedidosDV(): Observable<any> {
    return this.http.get(this.urlCRM + '/pedidos/getTodosPresupuestosPedidosDV');
        }

  

  public cambiarEncargoDeNoComisionadoAComisionado(pBody): Observable<any> {
    return this.http.put(this.urlCRM + '/pedidos/cambiarEncargoDeNoComisionadoAComisionado', {pedido: pBody});
  }



  public updateHitos(accion: Accion, direccion: Direccion) {
    if (accion.estado === '0' && accion.resultado === '0' || accion.resultado === '-1') {
      direccion.ultimaAccion = accion.fechaInicio;
      if (accion.resultado === '0') {
        switch (accion.hito) {
          case '0':
            if (accion.objetivo === '0') {
              direccion.hitosObtenidos.push({
                hito: 'TCLA',
                obtenido: true,
                usuario: accion.de[0],
                fecha: accion.fechaInicio
              });
            }
            if (accion.objetivo === '8') {
              direccion.hitosObtenidos.push({
                hito: 'TCLA',
                obtenido: false,
                usuario: accion.de[0],
                fecha: accion.fechaInicio
              });
            }
            break;
          case '1':
            if (accion.objetivo === '0') {
              direccion.hitosObtenidos.push({
                hito: 'CLASS',
                obtenido: true,
                usuario: accion.de[0],
                fecha: accion.fechaInicio
              });
            }
            if (accion.objetivo === '8') {
              direccion.hitosObtenidos.push({
                hito: 'CLASS',
                obtenido: false,
                usuario: accion.de[0],
                fecha: accion.fechaInicio
              });
            }
            break;
          case '2':
            if (accion.objetivo === '0' || accion.objetivo === '6' || accion.objetivo === '11') {
              direccion.hitosObtenidos.push({
                hito: 'WV',
                obtenido: true,
                usuario: accion.de[0],
                fecha: accion.fechaInicio
              });
            }
            if (accion.objetivo === '8') {
              direccion.hitosObtenidos.push({
                hito: 'WV',
                obtenido: false,
                usuario: accion.de[0],
                fecha: accion.fechaInicio
              });
            }
            break;
          case '3':
            if (accion.objetivo === '11') {
              direccion.hitosObtenidos.push({
                hito: 'IDEHABITA',
                obtenido: true,
                usuario: accion.de[0],
                fecha: accion.fechaInicio
              });
            }
            if (accion.objetivo === '8') {
              direccion.hitosObtenidos.push({
                hito: 'IDEHABITA',
                obtenido: false,
                usuario: accion.de[0],
                fecha: accion.fechaInicio
              });
            }
            break;
          case '4':
            if (accion.objetivo === '0' || accion.objetivo === '6' || accion.objetivo === '11') {
              direccion.hitosObtenidos.push({
                hito: 'AMUEBLANDO',
                obtenido: true,
                usuario: accion.de[0],
                fecha: accion.fechaInicio
              });
              this.activarAmueblando(direccion.perfilTCLA).subscribe();
              this.correoHitoAMUEBLANDO(direccion.email).subscribe();
            }
            if (accion.objetivo === '8') {
              direccion.hitosObtenidos.push({
                hito: 'AMUEBLANDO',
                obtenido: false,
                usuario: accion.de[0],
                fecha: accion.fechaInicio
              });
              this.desactivarAmueblando(direccion.perfilTCLA).subscribe();
            }
            break;
          case '5':
            if (accion.objetivo === '6') {
              direccion.hitosObtenidos.push({
                hito: 'REVISTAS',
                obtenido: true,
                usuario: accion.de[0],
                fecha: accion.fechaInicio
              });
            }
            if (accion.objetivo === '8') {
              direccion.hitosObtenidos.push({
                hito: 'REVISTAS',
                obtenido: false,
                usuario: accion.de[0],
                fecha: accion.fechaInicio
              });
            }
            break;
          case '6':
            if (accion.objetivo === '0') {
              direccion.hitosObtenidos.push({
                hito: '3D',
                obtenido: true,
                usuario: accion.de[0],
                fecha: accion.fechaInicio
              });
              this.activar3D(direccion.perfilTCLA).subscribe(value => {
                console.log(value);
              }, error => {
                console.error(error);
              });
            }
            if (accion.objetivo === '8') {
              direccion.hitosObtenidos.push({
                hito: '3D',
                obtenido: false,
                usuario: accion.de[0],
                fecha: accion.fechaInicio
              });
              this.desactivar3D(direccion.perfilTCLA).subscribe(value => {
                console.log(value);
              }, error => {
                console.error(error);
              });
            }
            break;
          case '7':
            if (accion.objetivo === '1') {
              direccion.hitosObtenidos.push({
                hito: 'PRE_DVIRTUAL',
                obtenido: true,
                usuario: accion.de[0],
                fecha: accion.fechaInicio
              });
              this.activarPrevirtual(direccion.perfilTCLA).subscribe();
            }
            if (accion.objetivo === '0' || accion.objetivo === '6' || accion.objetivo === '11') {
              direccion.hitosObtenidos.push({
                hito: 'DVIRTUAL',
                obtenido: true,
                usuario: accion.de[0],
                fecha: accion.fechaInicio
              });
              this.activarAmueblando(direccion.perfilTCLA).subscribe();
            }
            if (accion.objetivo === '8') {
              direccion.hitosObtenidos.push({
                hito: 'DVIRTUAL',
                obtenido: false,
                usuario: accion.de[0],
                fecha: accion.fechaInicio
              });
              this.desactivarAmueblando(direccion.perfilTCLA).subscribe();
            }
            break;
          case '8':
            if (accion.objetivo === '6') {
              direccion.hitosObtenidos.push({
                hito: 'MUESTRAS',
                obtenido: true,
                usuario: accion.de[0],
                fecha: accion.fechaInicio
              });
            }
            if (accion.objetivo === '8') {
              direccion.hitosObtenidos.push({
                hito: 'MUESTRAS',
                obtenido: false,
                usuario: accion.de[0],
                fecha: accion.fechaInicio
              });
            }
            break;
          case '10':
            if (accion.objetivo === '0' || accion.objetivo === '6' || accion.objetivo === '11') {
              direccion.hitosObtenidos.push({
                hito: 'COLABORADOR',
                obtenido: true,
                usuario: accion.de[0],
                fecha: accion.fechaInicio
              });
              this.activarAmueblando(direccion.perfilTCLA).subscribe();
            }
            if (accion.objetivo === '8') {
              direccion.hitosObtenidos.push({
                hito: 'COLABORADOR',
                obtenido: false,
                usuario: accion.de[0],
                fecha: accion.fechaInicio
              });
              this.desactivarAmueblando(direccion.perfilTCLA).subscribe();
            }
            break;
            case '11':
              if (accion.objetivo === '0' || accion.objetivo === '6') {
                direccion.hitosObtenidos.push({
                  hito: 'BannerCreamostumueble',
                  obtenido: true,
                  usuario: accion.de[0],
                  fecha: accion.fechaInicio
                });
              }
              if (accion.objetivo === '8') {
                direccion.hitosObtenidos.push({
                  hito: 'BannerCreamostumueble',
                  obtenido: false,
                  usuario: accion.de[0],
                  fecha: accion.fechaInicio
                });
              }
              break;
            case '12':
              if (accion.objetivo === '1' || accion.objetivo === '6') {
                direccion.hitosObtenidos.push({
                  hito: 'DOSIER_PRESENTACION',
                  obtenido: true,
                  usuario: accion.de[0],
                  fecha: accion.fechaInicio
                });
              }
              if (accion.objetivo === '8') {
                direccion.hitosObtenidos.push({
                  hito: 'DOSIER_PRESENTACION',
                  obtenido: false,
                  usuario: accion.de[0],
                  fecha: accion.fechaInicio
                });
              }
              break;
        }
      }
    }
  }
  public recalcularLicenciasDisponibles(cliente_id: string): Observable<any> {
    return this.http.post(this.urlCRM + '/tareasProgramadas/recalcularLicenciasDisponibles', { cliente_id: cliente_id });
  }
  public getElementosFacturados(): Observable<any> {
    switch (this.rankingMostrar) {
      case 0:
        return this.getAllElementosRanking();
      case 1:
        return this.getElementosAutom();
      case 2:
        return this.getElementosManual();
      default:
        return this.getAllElementosRanking();
    }
  }
  public FormatNumberLength(num, length) {
    let r = '' + num;
    while (r.length < length) {
      r = '0' + r;
    }
    return r;
  }
  public ordenarUsuarios() {
    this.usuarios.sort(function (usuarioA: Usuario, usuarioB: Usuario) {
      if (usuarioA.nombre.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        < usuarioB.nombre.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) {
        return -1;

      } else if (usuarioA.nombre.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        > usuarioB.nombre.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) {
        return 1;

      }
      if (usuarioA.nombre.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        === usuarioB.nombre.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) {
        if (usuarioA.apellidos.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
          < usuarioB.apellidos.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) {
          return -1;

        } else if (usuarioA.apellidos.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
          > usuarioB.apellidos.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) {
          return 1;

        }
      }
      return 0;
    });
  }

  public tieneAlertas(idCliente: string, idDireccion: string): boolean {
    return (this.alertasClientes.filter(e => e.cliente === idCliente && e.direccion === idDireccion)).length > 0;
  }
  public getNumAlertasGraves(): number {
    return this.alertasClientes.length;
  }

  public rol(rol: 'admin' | 'supervisor' | 'pedidos' | 'asesor' | 'comercial' | 'test' | 'influencer'): boolean {
    if (this.userLogin != null) {
      return this.userLogin.rol === rol;
    } else {
      return false;
    }
  }
}
