import { Component, OnInit } from '@angular/core';

class Vec2 {
  public x: number;
  public y: number;

  constructor(nX: number, nY: number) {
    this.x = nX;
    this.y = nY;
  }

  public equal(v: Vec2): boolean {
    return this.x === v.x && this.y === v.y;
  }
}
class Cell {
  public p: Vec2;
  public state: 'hidden' | 'revealed' | 'flagged' | 'dead';
  public content: number;
  constructor(nP: Vec2) {
    this.p = nP;
    this.state = 'hidden';
    this.content = 0;
  }
  get color(): string {
    switch (this.content) {
      case -1:
        return 'black';
      case 0:
        return 'gray';
      case 1:
        return 'one';
      case 2:
        return 'two';
      case 3:
        return 'three';
      case 4:
        return 'four';
      case 5:
        return 'five';
      case 6:
        return 'six';
      case 7:
        return 'seven';
      case 8:
        return 'eight';
      default:
        return 'white';
    }
  }
  get bgColor(): string {
    switch (this.state) {
      case 'hidden':
      case 'flagged':
        return 'bg-white';
      case 'revealed':
        return 'bg-revealed';
      case 'dead':
        return 'bg-danger';
    }
  }
}
class Table {
  public x_size: number;
  public y_size: number;
  public nDmg: number;
  public matrix: Cell[][];

  private static _randNumber(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  constructor(x: number, y: number, ratio: number = 0.15) {
    this.x_size = x;
    this.y_size = y;

    this.nDmg = Math.round(this.x_size * this.y_size * ratio);
  }

  public initialize(): void {
    this._iniMatrix();
  }
  public setContent(click: Cell): void {
    this._iniDmgs(click);
    this._iniNumbers();
  }
  public reveal(cell: Cell): void {
    cell.state = 'revealed';
    if (cell.content === 0) {
      for (let i = cell.p.x - 1; i <= cell.p.x + 1; i++) {
        for (let j = cell.p.y - 1; j <= cell.p.y + 1; j++) {
          if (this.getCell(i, j) != null && !cell.p.equal(this.getCell(i, j).p) && this.getCell(i, j).state === 'hidden') {
            this.reveal(this.getCell(i, j));
          }
        }
      }
    }
  }
  public getCell(x: number, y: number): Cell|null {
    if (x >= 0 && x < this.x_size && y >= 0 && y < this.y_size) {
      return this.matrix[x][y];
    } else {
      return null;
    }
  }
  public get win(): boolean {
    return this.matrix
      .reduce((acc, row) => acc.concat(row), [])
      .reduce((acc, cell) => acc + ((cell.state === 'hidden' || cell.state === 'flagged') ? 1 : 0), 0) === this.nDmg;
  }
  public lose(cell: Cell): void {
    this.matrix.reduce((acc, row) => acc.concat(row), []).filter(c => c.content === -1).forEach(c => c.state = 'revealed');
    cell.state = 'dead';
  }
  public log(): void {
    console.clear();
    for (let i = 0; i < this.x_size; i++) {
      let line = '';
      for (let j = 0; j < this.y_size; j++) {
        line += this.matrix[i][j].state === 'hidden' ? '? ' : (this.matrix[i][j].content !== -1 ? (this.matrix[i][j].content + ' ') : '* ');
      }
      console.log(line);
    }
  }

  private _iniMatrix(): void {
    if (this.x_size != null && this.x_size > 0 && this.y_size != null && this.y_size > 0) {
      this.matrix = [];
      for (let i = 0; i < this.x_size; i++) {
        this.matrix[i] = [];
        for (let j = 0; j < this.y_size; j++) {
          this.matrix[i][j] = new Cell(new Vec2(i, j));
        }
      }
    }
  }
  private _iniDmgs(safe: Cell): void {
    const safeZone: Cell[] = [];
    for (let i = safe.p.x - 1; i <= safe.p.x + 1; i++) {
      for (let j = safe.p.y - 1; j <= safe.p.y + 1; j++) {
        if (this.getCell(i, j)) {
          safeZone.push(this.getCell(i, j));
        }
      }
    }
    let gen = 0;
    while (gen < this.nDmg) {
      const randX = Table._randNumber(0, this.x_size);
      const randY = Table._randNumber(0, this.y_size);
      if (!safeZone.some(c => c.p.equal(new Vec2(randX, randY))) && this.matrix[randX][randY].content !== -1) {
        this.matrix[randX][randY].content = -1;
        gen++;
      }
    }
  }
  private _iniNumbers(): void {
    for (const row of this.matrix) {
      for (const cell of row) {
        if (cell.content !== -1) {
          let found = 0;
          for (let i = cell.p.x - 1; i <= cell.p.x + 1; i++) {
            for (let j = cell.p.y - 1; j <= cell.p.y + 1; j++) {
              if (this.getCell(i, j) && this.getCell(i, j).content === -1) {
                found++;
              }
            }
          }
          cell.content = found;
        }
      }
    }
  }
}

@Component({
  selector: 'app-thing2',
  templateUrl: './thing2.component.html',
  styleUrls: ['./thing2.component.css']
})
export class Thing2Component implements OnInit {

  public state: 'initialized' | 'started' | 'endedOK' | 'endedKO';
  public table: Table;

  public x: number;
  public y: number;
  public ratio: number;

  constructor() {
    this.x = 20;
    this.y = 20;
    this.ratio = 15;
    this.ini();
  }

  public ini(): void {
    this.table = new Table(this.x, this.y, this.ratio / 100);
    this.table.initialize();
    this.state = 'initialized';
  }

  public leftClick(cell: Cell): void {
    if (cell.state === 'hidden') {
      if (this.state === 'initialized') {
        this.table.setContent(cell);
        this.state = 'started';
      }

      if (this.state === 'started') {
        if (cell.content === -1) {
          this.table.lose(cell);
          this.state = 'endedKO';
        } else {
          this.table.reveal(cell);
          if (this.table.win) {
            this.state = 'endedOK';
          }
        }
      }
    }
  }
  public rightClick($event, cell: Cell): void {
    $event.preventDefault();
    if (this.state === 'initialized' || this.state === 'started') {
      if (cell.state === 'hidden') {
        cell.state = 'flagged';
      } else if (cell.state === 'flagged') {
        cell.state = 'hidden';
      }
    }
  }

  public get nDmg(): number {
    return Math.round(this.x * this.y * this.ratio / 100);
  }

  ngOnInit(): void {
  }

}
