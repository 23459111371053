import { Component, OnInit } from '@angular/core';
import {Muestra} from '../../../../modelos/Muestra';
import {CrmService} from '../../../../servicios/crm.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-nueva-muestra',
  templateUrl: './nueva-muestra.component.html',
  styleUrls: ['./nueva-muestra.component.css']
})
export class NuevaMuestraComponent implements OnInit {

  public muestra: Muestra;
  public mensajeError: string;

  public nombre: string;
  public tipoMuestra: string;
  public imagen: File;

  constructor(public crmservice: CrmService,
              private snackBar: MatSnackBar,
              private dialogRef: MatDialogRef<NuevaMuestraComponent>) {

  }

  ngOnInit() {
  }

  public guardar(): void {
    if (this.comprobarFormulario()) {
      this.crmservice.subirImagenMuestra(this.imagen).subscribe(
        () => {
          this.muestra = new Muestra();

          this.muestra.nombre = this.nombre;
          this.muestra.tipo = this.tipoMuestra;
          this.muestra.imagen = this.imagen.name;
          this.muestra.descontinuada = false;

          this.crmservice.nuevaMuestra(this.muestra).subscribe(
            valueMuestra => {
              this.dialogRef.close(valueMuestra);
            }, errorMuestra => {
              console.error(errorMuestra);
              this.snackBar.open('Error al crear la muestra', 'OK', {duration: 3000});
            });
        }, error => {
          console.error(error);
          this.snackBar.open('Error al subir la imagen', 'OK', {duration: 3000});
        });
    }
  }
  public comprobarFormulario(): boolean {

    if (this.nombre == null || !this.nombre.trim().length) {
      this.mensajeError = 'Indica el nombre de la muestra.';
      return false;
    }

    if (this.tipoMuestra == null) {
      this.mensajeError = 'Indica el tipo de la muestra';
      return false;
    }

    if (this.imagen == null) {
      this.mensajeError = 'Debes escoger una imagen para la muestra.';
      return false;
    }

    const extension = this.imagen.name.split('.')[this.imagen.name.split('.').length - 1].toLowerCase();
    if (extension !== 'png' && extension !== 'jpg' && extension !== 'jpeg') {
      this.mensajeError = 'La imagen debe ser PNG o JPG';
      return false;
    }

    this.mensajeError = null;
    return true;
  }

}
