import {Component, Inject, OnInit} from '@angular/core';
import {CrmService} from '../../../servicios/crm.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {IpdormaItemList} from '../../../modelos/Interfaces';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BusquedaClienteComponent} from './busqueda-cliente/busqueda-cliente.component';
import {Router} from '@angular/router';

@Component({
  selector: 'app-clientes-asignados-ipdorma',
  templateUrl: './clientes-asignados-ipdorma.component.html',
  styleUrls: ['./clientes-asignados-ipdorma.component.css']
})
export class ClientesAsignadosIpdormaComponent implements OnInit {

  public cargando: boolean;
  public ipdormaItem: IpdormaItemList;
  public direcciones = [];

  constructor(public crmservice: CrmService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private snackbar: MatSnackBar,
              private dialog: MatDialog,
              private router: Router,
              private dialogRef: MatDialogRef<ClientesAsignadosIpdormaComponent>) {
    this.ipdormaItem = data.ipdorma;
    this.ipdormaItem.clientes.forEach(cliente => {
      const direccion = this.crmservice.direcciones.find(d => d._idCli === cliente);
      if (direccion != null) {
        this.direcciones.push(direccion);
      }
    });

    this.cargando = false;
  }

  ngOnInit(): void {
  }

  public btn_notificar(): void {
    if (!this.ipdormaItem.ignorar) {
      this.ignorar();
    } else {
      this.noIgnorar();
    }
  }
  public abrirDireccion(direccion: string): void {
    this.router.navigate(['/direccion/' + direccion]).then(() => this.dialogRef.close());
  }

  private ignorar(): void {
    const popUpRef = this.crmservice.popUpConfirmacion({
      textoPrincipal: '¿Ignorar ' + this.ipdormaItem.razonSocial + '?',
      textoSecundario: [{texto: 'Este cliente no se volverá a mostrar en el listado.'}]
    });
    popUpRef.afterClosed().subscribe(value => {
      if (value) {
        this.crmservice.countCliente_Ipdorma().subscribe(count => this.crmservice.numIpdormas = count || 0);
        this.cargando = true;
        this.crmservice.setCliente_IpdormaIgnorado(this.ipdormaItem._id, true).subscribe(() => {
          this.cargando = false;
          this.ipdormaItem.ignorar = true;
        }, error => {
          this.cargando = false;
          console.error(error);
        });
      }
    });
  }
  private noIgnorar(): void {
    const popUpRef = this.crmservice.popUpConfirmacion({textoPrincipal: '¿Dejar de ignorar ' + this.ipdormaItem.razonSocial + '?'});
    popUpRef.afterClosed().subscribe(value => {
      if (value) {
        this.crmservice.countCliente_Ipdorma().subscribe(count => this.crmservice.numIpdormas = count || 0);
        this.cargando = true;
        this.crmservice.setCliente_IpdormaIgnorado(this.ipdormaItem._id, false).subscribe(() => {
          this.cargando = false;
          this.ipdormaItem.ignorar = false;
        }, error => {
          this.cargando = false;
          console.error(error);
        });
      }
    });
  }

  public desvincularCliente(direccion): void {
    const popUpRef = this.crmservice.popUpConfirmacion({textoPrincipal: '¿Quieres desvincular la ficha de este cliente?', textoSecundario: [direccion.razonSocial]});

    popUpRef.afterClosed().subscribe(value => {
      if (value) {
        this.cargando = true;
        this.crmservice.unlinkIpdormaCliente(direccion._idCli, this.ipdormaItem._id).subscribe(() => {
          this.cargando = false;

          let pos = this.direcciones.findIndex(d => d._idCli === direccion._idCli);
          if (pos !== -1) {
            this.direcciones.splice(pos, 1);
          }

          pos = this.ipdormaItem.clientes.findIndex(c => c === direccion._idCli);
          if (pos !== -1) {
            this.ipdormaItem.clientes.splice(pos, 1);
          }

          this.crmservice.countCliente_Ipdorma().subscribe(count => this.crmservice.numIpdormas = count || 0);
          this.snackbar.open('Se ha desvinculado ' + direccion.razonSocial, 'Ok');

        }, error => {
          this.cargando = false;
          console.error(error);
        });
      }
    });
  }
  public vincularCliente(): void {
    const buscarClienteRef = this.dialog.open(BusquedaClienteComponent, {
      width: '80%',
      height: '80%',
      panelClass: 'custom-dialog-container'
    });

    buscarClienteRef.afterClosed().subscribe(value => {
      if (value) {
        this.cargando = true;
        this.crmservice.linkIpdormaCliente(value._idCli, this.ipdormaItem._id).subscribe(() => {
          this.ipdormaItem.clientes.push(value._idCli);
          this.direcciones.push(value);
          this.cargando = false;
          this.crmservice.countCliente_Ipdorma().subscribe(count => this.crmservice.numIpdormas = count || 0);
          this.snackbar.open('Cliente asignado.', 'Ok');
        }, error => {
          console.error(error);
          this.cargando = false;
        });
      }
    });
  }

  public getClasificacion(clasificacion: string): string {
    return (clasificacion === 'B1' || clasificacion === 'B2') ? 'B' : clasificacion;
  }
  public getColorClasificacion(clasificacion: string): string {
    if (clasificacion === 'A') {
      return 'limegreen';
    }

    if (clasificacion === 'B1' || clasificacion === 'B2') {
      return 'gold';
    }

    if (clasificacion === 'C') {
      return 'brown';
    }

    return 'pink';
  }
}
