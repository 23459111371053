import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { CrmService } from "../../../../servicios/crm.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UsuariosComponent } from "../../../usuarios/usuarios.component";
import { Direccion } from "../../../../modelos/Direccion";
import { Accion } from "../../../../modelos/Accion";
import { MediaMatcher } from "@angular/cdk/layout";
import { Persona } from "../../../../modelos/Persona";

@Component({
  selector: "app-nueva-accion-formulario",
  templateUrl: "./nueva-accion-formulario.component.html",
  styleUrls: ["./nueva-accion-formulario.component.css"],
})
export class NuevaAccionFormularioComponent implements OnInit {
  mobileQuery: MediaQueryList;
  private readonly _mobileQueryListener: () => void;

  public guardandoDatos = false;
  public step = 0;
  public mensajeError = "";
  public direcciones: Direccion[];
  public acciones: Accion[];
  public personas: Persona[];

  public accion1: Accion; // Acción inicial, para cuando se crea y se marca como finalizada/prevista.
  public accion2: Accion; // Acción que se crea cuando se Traslada/Envía a otros asesores.

  public fechaPrevistaA1: Date;
  public horaPrevistaA1: string;
  public fechaInicioA1: Date;
  public horaInicioA1: string;
  public horaFinA1: string;

  public fechaPrevistaA2: Date;
  public horaPrevistaA2: string;

  public formacionA1 = {
    bloque: null,
    tipo: null,
    superado: false,
  };
  public formacionA2 = {
    bloque: null,
    tipo: null,
    superado: false,
  };

  public asesores: [string];
  public pedidos: [string];
  public comerciales = [];
  public supervisores: [string];

  public asesoresEnviar: [string];
  public pedidosEnviar: [string];
  public comercialesEnviar = [];
  public supervisoresEnviar: [string];

  constructor(
    public crmservice: CrmService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<UsuariosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher
  ) {
    this.mobileQuery = media.matchMedia("(max-width: 750px)");
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.direcciones = this.data.direcciones;
    this.acciones = this.data.acciones;
    this.personas = this.data.personas;

    this.accion1 = new Accion();
    this.accion2 = new Accion();

    if (this.crmservice.acciones == null || !this.crmservice.acciones.length) {
      this.guardandoDatos = true;
      this.crmservice.getAccionesUser(this.crmservice.userLogin._id).subscribe(
        (valueAcciones) => {
          this.crmservice.acciones = valueAcciones;
          this.guardandoDatos = false;
        },
        (error1) => {
          console.error(error1);
          this.guardandoDatos = false;
        }
      );
    }
  }

  ngOnInit() {}

  /** FUNCIONES GENERALES **/
  transformaFechas(fecha, hora) {
    if (hora == null) {
      hora = "00:00";
    }

    fecha = new Date(fecha);

    fecha.setHours(parseInt(hora.split(":")[0], 10));
    fecha.setMinutes(parseInt(hora.split(":")[1], 10));

    return fecha;
  }
  dameEquipo() {
    if (this.accion1.direccion != null) {
      const pos = this.direcciones.findIndex((e) => e._id === this.accion1.direccion);
      return this.direcciones[pos].equipo;
    } else {
      return null;
    }
  }
  // Indica si se debe mostrar el Hito. En principio es útil para mostra o no el class.
  // Las condiciones de mostrar el class son: que la dirección se apta para CLASS y que,
  // el usuario no sea asesor ni comercial.
  public mostrarHito(hito: { nombre: string; id: string }): boolean {
    const pos = this.direcciones.findIndex((e) => e._id === this.accion1.direccion);
    if (pos !== -1) {
      const direccion = this.direcciones[pos];

      // Los hitos CLASS, #YomequedoencasaAMUEBLANDO y Colaborador#AMUEBLANDO dejan de estar disponible para seleccionarlo.
      if (hito.id === "1" || hito.id === "4" || hito.id === "10") {
        return false;
      }

      // Las direcciones virtuales y gancho tienen disponibles todos los hitos sin excepción.
      if (direccion.tipoDir === "7" || direccion.tipoDir === "8") {
        return true;
      }

      // CreamosTuMueble solo se concede si se tienen los hitos TCLA, 3D, Muestras y perfilTecla indicado en la dirección,
      if (hito.id === "3") {
        const TCLA = this.hitoObtenido(direccion, "TCLA", true);
        const tresD = this.hitoObtenido(direccion, "3D", true);
        const muestras = this.hitoObtenido(direccion, "MUESTRAS", true);

        return TCLA && tresD && muestras && direccion.perfilTCLA != null && direccion.perfilTCLA.trim().length > 0;
      }

      if (hito.id === "6") {
        return direccion.perfilTCLA != null && direccion.perfilTCLA.trim().length > 0;
      }

      // Para el Idehabitasolo se concede si se tienen los hitos TCLA, 3D, Muestras y perfilTecla indicado en la dirección.
      if (hito.id === "7") {
        const TCLA = this.hitoObtenido(direccion, "TCLA", true);
        const tresD = this.hitoObtenido(direccion, "3D", true);
        const muestras = this.hitoObtenido(direccion, "MUESTRAS", true);

        return TCLA && tresD && muestras && direccion.perfilTCLA != null && direccion.perfilTCLA.trim().length > 0;
      }

      return true;
    } else {
      return false;
    }
  }
  hitoObtenido(direccion: Direccion, hito: string, obtenido: boolean): boolean {
    const hitos = direccion.hitosObtenidos.filter((e) => e.hito === hito);
    if (!hitos.length) {
      return false;
    } else {
      hitos.sort((a, b) => {
        const aDate = new Date(a.fecha);
        const bDate = new Date(b.fecha);

        if (aDate.getTime() > bDate.getTime()) {
          return 1;
        } else if (aDate.getTime() < bDate.getTime()) {
          return -1;
        }
        return 0;
      });
      const masReciente = hitos[hitos.length - 1];
      return masReciente.obtenido === obtenido;
    }
  }

  nextStep(siguiente) {
    if (this.comprobarPaso(this.step)) {
      this.step = siguiente;
    }
  }
  prevStep(paso) {
    this.mensajeError = "";
    this.step = paso;
  }
  comprobarPaso(paso): boolean {
    switch (paso) {
      case 0:
        if (this.accion1.hito == null) {
          this.mensajeError = "Indica el Hito.";
          return false;
        }

        if (this.accion1.objetivo == null) {
          this.mensajeError = "Indica el Objetivo.";
          return false;
        }

        if (this.accion1.medio == null || !this.accion1.medio.length) {
          this.mensajeError = "Indica el Medio.";
          return false;
        }

        if (this.accion1.hito === "6" && this.accion1.objetivo === "4") {
          if (this.formacionA1.bloque == null) {
            this.mensajeError = "Indica el Bloque de Formación 3D.";
            return false;
          }

          if (this.formacionA1.tipo == null) {
            this.mensajeError = "Indica el Tipo de Formación 3D.";
            return false;
          }
        }

        if (this.accion1.direccion == null) {
          this.mensajeError = "Indica la Dirección.";
          return false;
        }

        if (this.accion1.contactos == null || !this.accion1.contactos.length) {
          this.mensajeError = "Indica el Contacto.";
          return false;
        }
        break;
      case 1:
        if (this.fechaPrevistaA1 == null) {
          this.mensajeError = "Indica la Fecha Prevista de la acción.";
          return false;
        }

        if (this.horaPrevistaA1 == null) {
          this.mensajeError = "Indica la hora prevista de la acción.";
          return false;
        }

        if (this.accion1.observaciones == null || !this.accion1.observaciones.trim().length) {
          this.mensajeError = "Indica Observaciones.";
          return false;
        }
        break;
      case 2:
        if (this.fechaInicioA1 == null) {
          this.mensajeError = "Indica la Fecha de Realización de la acción.";
          return false;
        }

        if (this.horaInicioA1 == null) {
          this.mensajeError = "Indica la Hora de Inicio de la acción.";
          return false;
        }

        if (this.horaFinA1 == null) {
          this.mensajeError = "Indica la Hora de Finalización de la acción.";
          return false;
        }

        if (!this.calcularHoras()) {
          this.mensajeError = "La Hora de Fin no puede ser menor que la Hora de Inicio.";
          return false;
        }
        break;
      case 3:
        if (this.accion1.resultado == null) {
          this.mensajeError = "Indica el Resultado de la acción.";
          return false;
        }

        if (this.accion1.observaciones == null || !this.accion1.observaciones.trim().length) {
          this.mensajeError = "Indica las Observaciones de la acción.";
          return false;
        }
        break;
      case 4:
        if (this.accion2.objetivo == null) {
          this.mensajeError = "Indica el Objetivo.";
          return false;
        }

        if (this.accion2.medio == null || !this.accion2.medio.length) {
          this.mensajeError = "Indica el Medio.";
          return false;
        }

        if (this.accion2.contactos == null || !this.accion2.contactos.length) {
          this.mensajeError = "Indica el Contacto.";
          return false;
        }

        if (this.accion1.hito === "6" && this.accion2.objetivo === "4") {
          if (this.formacionA2.bloque == null) {
            this.mensajeError = "Indica el Bloque de Formación 3D.";
            return false;
          }

          if (this.formacionA2.tipo == null) {
            this.mensajeError = "Indica el Tipo de Formación 3D.";
            return false;
          }
        }

        if (this.fechaPrevistaA2 == null) {
          this.mensajeError = "Indica la Fecha Prevista de la acción.";
          return false;
        }

        if (this.horaPrevistaA2 == null) {
          this.mensajeError = "Indica la Hora Prevista de la acción.";
          return false;
        }
        break;
      case 5:
        if (!this.comerciales.length) {
          this.mensajeError = "Indica el Comercial.";
          return false;
        }

        if (this.accion2.observaciones == null || !this.accion2.observaciones.trim().length) {
          this.mensajeError = "Indica la Descripción de la siguiente acción";
          return false;
        }
        break;
      case 6:
        if (!this.comercialesEnviar.length) {
          this.mensajeError = "Indica el Comercial.";
          return false;
        }
        break;
    }

    this.mensajeError = "";
    return true;
  }

  usuariosPara() {
    this.accion2.para = [];

    try {
      for (const item of this.asesores) {
        this.accion2.para.push(item);
      }
    } catch (e) {
      console.log("La lista de asesores está vacía");
    }

    try {
      for (const item of this.pedidos) {
        this.accion2.para.push(item);
      }
    } catch (e) {
      console.log("La lista de pedidos está vacía");
    }

    try {
      for (const item of this.comerciales) {
        this.accion2.para.push(item);
      }
    } catch (e) {
      console.log("La lista de comerciales está vacía");
    }

    try {
      for (const item of this.supervisores) {
        this.accion2.para.push(item);
      }
    } catch (e) {
      console.log("La lista de supervisores está vacía");
    }
  }
  usuariosParaEnviar() {
    this.accion1.para = [];

    try {
      for (const item of this.asesoresEnviar) {
        this.accion1.para.push(item);
      }
    } catch (e) {
      console.log("La lista de asesores está vacía");
    }

    try {
      for (const item of this.pedidosEnviar) {
        this.accion1.para.push(item);
      }
    } catch (e) {
      console.log("La lista de pedidos está vacía");
    }

    try {
      for (const item of this.comercialesEnviar) {
        this.accion1.para.push(item);
      }
    } catch (e) {
      console.log("La lista de comerciales está vacía");
    }

    try {
      for (const item of this.supervisoresEnviar) {
        this.accion1.para.push(item);
      }
    } catch (e) {
      console.log("La lista de supervisores está vacía");
    }
  }

  calcularHoras() {
    let resultado = true;

    if (this.horaInicioA1 != null && this.horaFinA1 != null) {
      const horas = [parseInt(this.horaInicioA1.split(":")[0], 10), parseInt(this.horaFinA1.split(":")[0], 10)];
      const minutos = [parseInt(this.horaInicioA1.split(":")[1], 10), parseInt(this.horaFinA1.split(":")[1], 10)];

      if (horas[0] > horas[1]) {
        resultado = false;
      }
      if (horas[0] === horas[1]) {
        if (minutos[0] >= minutos[1]) {
          resultado = false;
        }
      }
    }

    return resultado;
  }

  mostrarFinalizar() {
    /** 25/04/2020 **/
    // POR ORDEN DE JESÚS, AHORA CUALQUIERA PUEDE FINALIZAR ACCIONES INDEPENDIENTEMENTE DEL ROL Y DEL TIPO DE ACCIÓN.
    return true;

    /*const pos = this.direcciones.findIndex(e => e._id === this.accion1.direccion);
    if (pos !== -1) {
      const direccion = this.direcciones[pos];
      if (direccion.tipoDir === '7') {
        return true;
      }
    }

    if (this.crmservice.userLogin.rol != 'comercial' && this.crmservice.userLogin.rol != 'asesor') {
      return true;
    }
    if (this.crmservice.userLogin.nombreUsuario == 'rconca') {
      return true;
    }
    if (this.accion1.hito == '6' && this.accion1.objetivo == '4') {
      return true;
    }
    if (this.accion1.hito == '4') {
      return true;
    }
    return false;*/
  }
  mostrarPersona(persona: Persona, direccion: string): boolean {
    return persona.direccion.indexOf(direccion) !== -1;
  }

  /** GUARDAR-CREAR ACCIÓN **/
  guardarPrevista() {
    if (this.comprobarPaso(this.step)) {
      this.guardandoDatos = true;
      this.accion1.estado = "2";
      this.accion1.de = [this.crmservice.userLogin._id];
      this.accion1.cliente = this.direcciones[0].cliente;
      this.accion1.fechaPrevista = this.transformaFechas(this.fechaPrevistaA1, this.horaPrevistaA1);
      this.accion1.id = Math.random().toString(36).substr(2);

      // Si es Formación 3D
      if (this.accion1.hito === "6" && this.accion1.objetivo === "4") {
        this.accion1.formacion = this.formacionA1;
      }

      this.crmservice.setAccion(this.accion1).subscribe(
        (value) => {
          const pos = this.direcciones.findIndex((e) => e._id === this.accion1.direccion);
          this.direcciones[pos].acciones.push(value._id);
          this.accion1 = value;
        },
        (error1) => {
          this.guardandoDatos = false;
          this.snackBar.open("Error al guardar la acción", "OK", { duration: 2000 });
          console.log(error1);
        },
        () => {
          const pos = this.direcciones.findIndex((e) => e._id === this.accion1.direccion);

          this.crmservice.updateDireccion(this.direcciones[pos]).subscribe(
            () => {
              this.crmservice.acciones.push(this.accion1);
              this.acciones.push(this.accion1);
            },
            (error1) => {
              this.guardandoDatos = false;
              this.snackBar.open("Error al guardar la acción", "OK", { duration: 2000 });
              console.error(error1);
            },
            () => {
              this.snackBar.open("Acción guardada correctamente", "OK", { duration: 2000 });
              this.dialogRef.close();
            }
          );
        }
      );
    }
  }
  guardarFinalizada() {
    if (this.comprobarPaso(this.step)) {
      this.guardandoDatos = true;
      this.accion1.estado = "0";
      this.accion1.de = [this.crmservice.userLogin._id];
      this.accion1.cliente = this.direcciones[0].cliente;
      this.accion1.fechaInicio = this.transformaFechas(this.fechaInicioA1, this.horaInicioA1);
      this.accion1.fechaFin = this.transformaFechas(this.fechaInicioA1, this.horaFinA1);
      this.accion1.id = Math.random().toString(36).substr(2);

      if (this.accion1.hito === "6" && this.accion1.objetivo === "4") {
        this.accion1.formacion = this.formacionA1;
        this.accion1.formacion.superado = this.accion1.resultado === "0";
      }

      this.crmservice.setAccion(this.accion1).subscribe(
        (value) => {
          const pos = this.direcciones.findIndex((e) => e._id === this.accion1.direccion);
          this.direcciones[pos].acciones.push(value._id);
          this.crmservice.updateHitos(this.accion1, this.direcciones[pos]);
        },
        (error1) => {
          this.guardandoDatos = false;
          this.snackBar.open("Error al guardar la acción", "OK", { duration: 2000 });
          console.error(error1);
        },
        () => {
          const pos = this.direcciones.findIndex((e) => e._id === this.accion1.direccion);
          this.crmservice.updateDireccion(this.direcciones[pos]).subscribe(
            () => {
              this.crmservice.acciones.push(this.accion1);
              this.acciones.push(this.accion1);
            },
            (error1) => {
              this.guardandoDatos = false;
              this.snackBar.open("Error al guardar la acción", "OK", { duration: 2000 });
              console.error(error1);
            },
            () => {
              this.snackBar.open("Acción guardada correctamente", "OK", { duration: 2000 });
              this.dialogRef.close();
            }
          );
        }
      );
    }
  }
  guardarEnviadaA() {
    this.usuariosParaEnviar();
    if (this.comprobarPaso(this.step)) {
      this.guardandoDatos = true;

      // Igual que cuando se pulsa en Prevista
      this.accion1.estado = "2"; // Se presume que está asignada
      this.accion1.de = [this.crmservice.userLogin._id]; // Se le asigna al creador
      this.accion1.cliente = this.direcciones[0].cliente;
      this.accion1.fechaPrevista = this.transformaFechas(this.fechaPrevistaA1, this.horaPrevistaA1);
      this.accion1.id = Math.random().toString(36).substring(2);

      // Si es Formación 3D
      if (this.accion1.hito === "6" && this.accion1.objetivo === "4") {
        this.accion1.formacion = this.formacionA1;
      }

      if (this.accion1.para.length > 1) {
        // Como mínimo se le ha enviado a más de una persona
        if (this.asesoresEnviar != null && this.asesoresEnviar.length === 1) {
          // Si sólo hay un asesor, se le asigna a este directamente
          this.accion1.de = this.asesoresEnviar;
          this.accion1.estado = "2";
        } else {
          this.accion1.de = undefined; // Si no, se les envía a todos para que uno la adquiera
          this.accion1.estado = "1";
        }
      } else {
        this.accion1.de = this.accion1.para;
        this.accion1.estado = "2";
      }

      this.crmservice.setAccion(this.accion1).subscribe(
        (value) => {
          const pos = this.direcciones.findIndex((e) => e._id === this.accion1.direccion);
          this.direcciones[pos].acciones.push(value._id);
          this.accion1 = value;
        },
        (error1) => {
          this.guardandoDatos = false;
          this.snackBar.open("Error al guardar la acción", "OK", { duration: 2000 });
          console.log(error1);
        },
        () => {
          const pos = this.direcciones.findIndex((e) => e._id === this.accion1.direccion);
          this.crmservice.updateDireccion(this.direcciones[pos]).subscribe(
            () => {
              this.crmservice.acciones.push(this.accion1);
              this.acciones.push(this.accion1);
            },
            (error1) => {
              this.guardandoDatos = false;
              this.snackBar.open("Error al guardar la accion", "OK", { duration: 2000 });
              console.error(error1);
            },
            () => {
              this.snackBar.open("Accion guardada correctamente", "OK", { duration: 2000 });
              this.dialogRef.close();
            }
          );
        }
      );
    }
  }
  guardarTrasladada() {
    this.usuariosPara();
    if (this.comprobarPaso(this.step)) {
      this.guardandoDatos = true;

      this.accion1.estado = "0";
      this.accion1.de = [this.crmservice.userLogin._id];
      this.accion1.cliente = this.direcciones[0].cliente;
      this.accion1.fechaInicio = this.transformaFechas(this.fechaInicioA1, this.horaInicioA1);
      this.accion1.fechaFin = this.transformaFechas(this.fechaInicioA1, this.horaFinA1);
      this.accion1.id = Math.random().toString(36).substr(2);

      if (this.accion1.hito === "6" && this.accion1.objetivo === "4") {
        this.accion1.formacion = this.formacionA1;
        this.accion1.formacion.superado = this.accion1.resultado === "0";
      }

      if (this.accion2.para.length > 1) {
        if (this.asesores !== undefined && this.asesores.length === 1) {
          this.accion2.de = this.asesores;
          this.accion2.estado = "2";
        } else {
          this.accion2.de = undefined;
          this.accion2.estado = "1";
        }
      } else {
        this.accion2.de = this.accion2.para;
        this.accion2.estado = "2";
      }

      this.accion2.cliente = this.direcciones[0].cliente;
      this.accion2.hito = this.accion1.hito;
      this.accion2.direccion = this.accion1.direccion;
      this.accion2.fechaPrevista = this.transformaFechas(this.fechaPrevistaA2, this.horaPrevistaA2);
      this.accion2.id = Math.random().toString(36).substr(2);

      // Si es Formación 3D
      if (this.accion2.hito === "6" && this.accion2.objetivo === "4") {
        this.accion2.formacion = this.formacionA2;
      }

      this.crmservice.setAccion(this.accion1).subscribe(
        (value) => {
          const pos = this.direcciones.findIndex((e) => e._id === this.accion1.direccion);
          this.direcciones[pos].acciones.push(value._id);
          this.crmservice.updateHitos(this.accion1, this.direcciones[pos]);
        },
        (error1) => {
          this.guardandoDatos = false;
          this.snackBar.open("Error al guardar la acción", "OK", { duration: 2000 });
          console.error(error1);
        },
        () => {
          this.crmservice.setAccion(this.accion2).subscribe(
            (value) => {
              const pos = this.direcciones.findIndex((e) => e._id === this.accion2.direccion);
              this.direcciones[pos].acciones.push(value._id);
              this.accion2 = value;
            },
            (error1) => {
              this.guardandoDatos = false;
              this.snackBar.open("Error al guardar la acción", "OK", { duration: 2000 });
              console.error(error1);
            },
            () => {
              const pos = this.direcciones.findIndex((e) => e._id === this.accion1.direccion);
              this.crmservice.updateDireccion(this.direcciones[pos]).subscribe(
                () => {
                  this.crmservice.acciones.push(this.accion1);
                  this.crmservice.acciones.push(this.accion2);
                  this.acciones.push(this.accion1);
                  this.acciones.push(this.accion2);
                },
                (error1) => {
                  this.guardandoDatos = false;
                  this.snackBar.open("Error al guardar la acción", "OK", { duration: 2000 });
                  console.error(error1);
                },
                () => {
                  this.snackBar.open("Acción guardada correctamente", "OK", { duration: 2000 });
                  this.dialogRef.close();
                }
              );
            }
          );
        }
      );
    }
  }
}
