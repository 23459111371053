import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {Direccion} from '../../../../modelos/Direccion';
import {Accion} from '../../../../modelos/Accion';
import {CrmService} from '../../../../servicios/crm.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {UsuariosComponent} from '../../../usuarios/usuarios.component';
import {MediaMatcher} from '@angular/cdk/layout';
import {Persona} from '../../../../modelos/Persona';

@Component({
  selector: 'app-realizar-accion-formulario',
  templateUrl: './realizar-accion-formulario.component.html',
  styleUrls: ['./realizar-accion-formulario.component.css']
})
export class RealizarAccionFormularioComponent implements OnInit {

  mobileQuery: MediaQueryList;
  private readonly _mobileQueryListener: () => void;

  public guardandoDatos = false;
  public step = 0;
  public mensajeError = '';
  public direcciones: Direccion[];
  public acciones: Accion[];
  public personas: Persona[];

  public accion1: Accion;
  public accion2: Accion;

  public accion: Accion;

  public fechaInicioA1: Date;
  public horaInicioA1: string;
  public horaFinA1: string;

  public fechaPrevistaA2: Date;
  public horaPrevistaA2: string;

  public formacionA2 = {
    bloque: null,
    tipo: null,
    superado: false
  };

  public asesores: string[];
  public pedidos: string[];
  public comerciales = [];
  public supervisores: string[];

  constructor(public crmservice: CrmService,
              public snackBar: MatSnackBar,
              public dialogRef: MatDialogRef<UsuariosComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              changeDetectorRef: ChangeDetectorRef,
              media: MediaMatcher) {

    this.mobileQuery = media.matchMedia('(max-width: 750px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.direcciones = this.data.direcciones;
    this.acciones = this.data.acciones;
    this.personas = this.data.personas;

    this.accion = this.data.accion;
    this.accion1 = JSON.parse(JSON.stringify(this.data.accion));
    this.accion2 = new Accion;

    if (this.crmservice.acciones == null || !this.crmservice.acciones.length) {
      this.guardandoDatos = true;
      this.crmservice.getAccionesUser(this.crmservice.userLogin._id).subscribe(
        valueAcciones => {
          this.crmservice.acciones = valueAcciones;
          this.guardandoDatos = false;
        }, error1 => {
          console.error(error1);
          this.guardandoDatos = false;
        });
    }

  }

  ngOnInit() {}

  /** FUNCIONES GENERALES **/

  public nextStep(siguiente: number): void {
    if (this.comprobarPaso(this.step)) {
      this.step = siguiente;
    }
  }
  public prevStep(paso: number): void {
    this.mensajeError = '';
    this.step = paso;
  }
  public comprobarPaso(paso: number): boolean {
    switch (paso) {
      case 0:
        break;
      case 1:
        break;
      case 2:
        if (this.fechaInicioA1 == null) {
          this.mensajeError = 'Indica la Fecha de Realización de la acción.';
          return false;
        }

        if (this.horaInicioA1 == null) {
          this.mensajeError = 'Indica la Hora de Inicio de la acción.';
          return false;
        }

        if (this.horaFinA1 == null) {
          this.mensajeError = 'Indica la Hora de Finalización de la acción.';
          return false;
        }

        if (!this.calcularHoras()) {
          this.mensajeError = 'La Hora de Fin no puede ser menor que la Hora de Inicio.';
          return false;
        }
        break;
      case 3:
        if (this.accion1.resultado == null) {
          this.mensajeError = 'Indica el Resultado de la acción.';
          return false;
        }

        if (this.accion1.observaciones == null || !this.accion1.observaciones.trim().length) {
          this.mensajeError = 'Indica las Observaciones de la acción.';
          return false;
        }
        break;
      case 4:
        if (this.accion2.objetivo == null) {
          this.mensajeError = 'Indica el Objetivo.';
          return false;
        }

        if (this.accion2.medio == null || !this.accion2.medio.length) {
          this.mensajeError = 'Indica el Medio.';
          return false;
        }

        if (this.accion2.contactos == null || !this.accion2.contactos.length) {
          this.mensajeError = 'Indica el Contacto.';
          return false;
        }

        if (this.accion1.hito === '6' && this.accion2.objetivo === '4') {
          if (this.formacionA2.bloque == null) {
            this.mensajeError = 'Indica el Bloque de Formación 3D.';
            return false;
          }

          if (this.formacionA2.tipo == null) {
            this.mensajeError = 'Indica el Tipo de Formación 3D.';
            return false;
          }
        }

        if (this.fechaPrevistaA2 == null) {
          this.mensajeError = 'Indica la Fecha Prevista de la acción.';
          return false;
        }

        if (this.horaPrevistaA2 == null) {
          this.mensajeError = 'Indica la Hora Prevista de la acción.';
          return false;
        }
        break;
      case 5:
        if (!this.comerciales.length) {
          this.mensajeError = 'Indica el Comercial.';
          console.log(this.mensajeError);
          return false;
        }

        if (this.accion2.observaciones == null || !this.accion2.observaciones.trim().length) {
          this.mensajeError = 'Indica la Descripción de la siguiente acción';
          return false;
        }
        break;
    }
    this.mensajeError = '';
    return true;
  }

  public transformaFechas(fecha: Date, hora: string): Date {
    if (hora == null) {
      hora = '00:00';
    }

    fecha = new Date(fecha);

    fecha.setHours(parseInt(hora.split(':')[0], 10));
    fecha.setMinutes(parseInt(hora.split(':')[1], 10));

    return fecha;
  }
  public usuariosPara(): void {
    this.accion2.para = [];

    try {
      for (const item of this.asesores) {
        this.accion2.para.push(item);
      }
    } catch (e) {
      console.log('La lista de asesores está vacía');
    }

    try {
      for (const item of this.pedidos) {
        this.accion2.para.push(item);
      }
    } catch (e) {
      console.log('La lista de pedidos está vacía');
    }

    try {
      for (const item of this.comerciales) {
        this.accion2.para.push(item);
      }
    } catch (e) {
      console.log('La lista de comerciales está vacía');
    }

    try {
      for (const item of this.supervisores) {
        this.accion2.para.push(item);
      }
    } catch (e) {
      console.log('La lista de supervisores está vacía');
    }
  }
  public calcularHoras(): boolean {
    let resultado = true;
    if (this.horaInicioA1 != null && this.horaFinA1 != null) {
      const horas = [parseInt(this.horaInicioA1.split(':')[0], 10), parseInt(this.horaFinA1.split(':')[0], 10)];
      const minutos = [parseInt(this.horaInicioA1.split(':')[1], 10), parseInt(this.horaFinA1.split(':')[1], 10)];

      if (horas[0] > horas[1]) {
        resultado = false;
      }
      if (horas[0] === horas[1]) {
        if (minutos[0] >= minutos[1]) {
          resultado = false;
        }
      }
    }

    return resultado;
  }
  public dameEquipo(): string {
    if (this.accion1.direccion != null) {
      const pos = this.direcciones.findIndex(e => e._id === this.accion1.direccion);
      return this.direcciones[pos].equipo;
    } else {
      return null;
    }
  }

  public mostrarFinalizar(): boolean {
    return true;
  }
  public mostrarPersona(persona: Persona, direccion: string): boolean {
    return persona.direccion.indexOf(direccion) !== -1;
  }


  /** FUNCIONES DE GUARDADO **/
  public guardarFinalizada(): void {
    if (this.comprobarPaso(this.step)) {

      this.guardandoDatos = true;
      this.accion1.estado = '0';
      this.accion1.fechaInicio = this.transformaFechas(this.fechaInicioA1, this.horaInicioA1);
      this.accion1.fechaFin = this.transformaFechas(this.fechaInicioA1, this.horaFinA1);

      // Si es una acción de formación 3D
      if (this.accion1.hito === '6' && this.accion1.objetivo === '4' && this.accion1.formacion != null) {
        this.accion1.formacion.superado = this.accion1.resultado === '0';
      }

      this.crmservice.updateAccion(this.accion1).subscribe(
        () => {
          const pos = this.direcciones.findIndex(e => e._id === this.accion1.direccion);
          this.crmservice.updateHitos(this.accion1, this.direcciones[pos]);

        }, error1 => {
          this.guardandoDatos = false;
          this.snackBar.open('Error al guardar la acción', 'OK', {duration: 2000});
          console.error(error1);

        }, () => {
          const pos = this.direcciones.findIndex(e => e._id === this.accion1.direccion);
          this.crmservice.updateDireccion(this.direcciones[pos]).subscribe(
            () => {
            }, error1 => {
              this.guardandoDatos = false;
              this.snackBar.open('Error al guardar la acción', 'OK', {duration: 2000});
              console.error(error1);

            }, () => {
              this.snackBar.open('Acción guardada correctamente', 'OK', {duration: 2000});
              this.dialogRef.close(this.accion1);

            });
        });
    }
  }
  public guardarTrasladada(): void {
    this.usuariosPara();
    if (this.comprobarPaso(this.step)) {
      this.guardandoDatos = true;

      this.accion1.estado = '0';
      this.accion1.fechaInicio = this.transformaFechas(this.fechaInicioA1, this.horaInicioA1);
      this.accion1.fechaFin = this.transformaFechas(this.fechaInicioA1, this.horaFinA1);

      if (this.accion1.hito === '6' && this.accion1.objetivo === '4' && this.accion1.formacion != null) {
        this.accion1.formacion.superado = this.accion1.resultado === '0';
      }

      if (this.accion2.para.length > 1) {
        if (this.asesores != null && this.asesores.length === 1) {
          this.accion2.de = this.asesores;
          this.accion2.estado = '2';
        } else {
          this.accion2.de = undefined;
          this.accion2.estado = '1';
        }
      } else {
        this.accion2.de = this.accion2.para;
        this.accion2.estado = '2';
      }

      this.accion2.cliente = this.direcciones[0].cliente;
      this.accion2.hito = this.accion1.hito;
      this.accion2.direccion = this.accion1.direccion;
      this.accion2.fechaPrevista = this.transformaFechas(this.fechaPrevistaA2, this.horaPrevistaA2);
      this.accion2.id = Math.random().toString(36).substr(2);

      // Si es Formación 3D
      if (this.accion2.hito === '6' && this.accion2.objetivo === '4') {
        this.accion2.formacion = this.formacionA2;
      }

      this.crmservice.updateAccion(this.accion1).subscribe(
        () => {
          const pos = this.direcciones.findIndex(e => e._id === this.accion1.direccion);
          this.crmservice.updateHitos(this.accion1, this.direcciones[pos]);

        }, error1 => {
          this.guardandoDatos = false;
          this.snackBar.open('Error al guardar la acción', 'OK', {duration: 2000});
          console.error(error1);

        }, () => {
          this.crmservice.setAccion(this.accion2).subscribe(
            value => {
              const pos = this.direcciones.findIndex(e => e._id === this.accion1.direccion);
              this.direcciones[pos].acciones.push(value._id);
              this.accion2 = value;

            }, error1 => {
              this.guardandoDatos = false;
              this.snackBar.open('Error al guardar la acción', 'OK', {duration: 2000});
              console.error(error1);

            }, () => {
              const pos = this.direcciones.findIndex(e => e._id === this.accion1.direccion);
              this.crmservice.updateDireccion(this.direcciones[pos]).subscribe(
                () => {
                  this.crmservice.acciones.push(this.accion2);
                  this.acciones.push(this.accion2);

                }, error1 => {
                  this.guardandoDatos = false;
                  this.snackBar.open('Error al guardar la acción', 'OK', {duration: 2000});
                  console.error(error1);

                }, () => {
                  this.snackBar.open('Acción guardada correctamente', 'OK', {duration: 2000});
                  this.dialogRef.close(this.accion1);
                });
            });
        });
    }
  }
}
