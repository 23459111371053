<div>

  <nav class="navbar sticky-top navbar-light bg-light" style="padding: 0">
    <div style="width: 100%; background-color: slategray" class="d-flex justify-content-between">
      <mat-icon class="p-2" style="color: whitesmoke">report</mat-icon>
      <span class="p-2" style="color: whitesmoke">
        <strong>Excepciones de '{{config.nombre}}'</strong>
      </span>
      <button style="color: whitesmoke" [mat-dialog-close] mat-icon-button>
        <mat-icon class="btn-close-dialog">close</mat-icon>
      </button>
    </div>
  </nav>

  <div style="width: 95%; margin: 0 auto" class="mt-5">

    <label>
      <input [(ngModel)]="filtroExcepciones" (ngModelChange)="applyFilter()" placeholder="Buscar...">
    </label>

    <table class="table table-sm table-bordered mt-3">
      <tbody>
      <tr *ngFor="let excepcion of dataSourceExcepciones.filteredData">
        <td class="text-center">
          <span style="font-size: xx-large; text-shadow: 2px 0 0 #000, -2px 0 0 #000, 0 2px 0 #000, 0 -2px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000; padding: 5px"
                [ngStyle]="{'color': getColorClasificacion(getClasificacion(excepcion))}">
            {{getClasificacion(excepcion)}}
          </span>
        </td>
        <td class="text-center" style="vertical-align: middle">
          <img [src]="getIcono(excepcion)" alt="">
        </td>
        <td style="vertical-align: middle">
          <div>{{excepcion.razonSocial}}</div>
          <div *ngIf="config.ambito === 'direccion'">{{excepcion.nombreComercial}}</div>
        </td>
        <td class="text-center">
          <button mat-icon-button [matMenuTriggerFor]="menuExcepciones" [matMenuTriggerData]="{excepcion: excepcion}">
            <mat-icon>chevron_right</mat-icon>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

</div>

<mat-menu #menuExcepciones="matMenu">
  <ng-template matMenuContent let-excepcion="excepcion">
    <button mat-menu-item (click)="abrirCliente(excepcion)">ABRIR CLIENTE</button>
    <button mat-menu-item (click)="removeExcepcion(excepcion)">QUITAR DE EXCEPCIONES</button>
  </ng-template>
</mat-menu>
