<div id="cabecera">
  <div id="texto-cabecera"><strong>{{textoPrincipal}}</strong></div>
  <div id="btn-cabecera-container"><mat-icon id="btn-cabecera" [matDialogClose]="">close</mat-icon></div>
</div>

<div class="p-4">
  <div class="w-100">
    <div *ngIf="textoSecundario.length" class="mb-4">
      <div *ngFor="let texto of textoSecundario" [ngClass]="texto.css">{{texto.texto}}</div>
    </div>
    <div *ngIf="controls.length" class="mb-4" [formGroup]="formGroup">
      <div *ngFor="let control of controls">
        <mat-form-field *ngIf="isInput(control)" class="w-100">
          <mat-label>{{control.placeholder}}</mat-label>
          <input type="{{control.type}}" [formControlName]="control.id" placeholder="{{control.placeholder}}"
                 matInput required="{{control.required}}" autocomplete="off">
        </mat-form-field>

        <mat-form-field *ngIf="isSelect(control)" class="w-100">
          <mat-label>{{control.placeholder}}</mat-label>
          <mat-select [formControlName]="control.id" placeholder="{{control.placeholder}}" required="{{control.required}}">
            <mat-option *ngFor="let option of control.options" [value]="option.value">{{option.text}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex justify-content-between">
      <button class="btn btn-primary-crm" style="width: 140px" (click)="confirmar()">{{btnConfirm}}</button>
      <button class="btn btn-outline-secondary" style="width: 140px" [matDialogClose]="">{{btnCancel}}</button>
    </div>
  </div>
</div>
