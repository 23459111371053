import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatDialogRef} from '@angular/material/dialog';
import {CrmService} from '../../../../servicios/crm.service';

@Component({
  selector: 'app-busqueda-cliente',
  templateUrl: './busqueda-cliente.component.html',
  styleUrls: ['./busqueda-cliente.component.css']
})
export class BusquedaClienteComponent implements OnInit, AfterViewInit {

  public cargando: boolean;
  public textoBusqueda: string;
  public displayedColumns: string[];
  public dataSource: MatTableDataSource<any> = new MatTableDataSource();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.data = [];
  }

  constructor(public crmservice: CrmService,
              private dialogRef: MatDialogRef<BusquedaClienteComponent>) {
    this.cargando = false;
    this.textoBusqueda = '';
    this.displayedColumns = ['estado', 'cif', 'razonSocial', 'nombreComercial', 'provincia', 'poblacion'];
  }

  ngOnInit(): void {
  }

  public buscar(): void {
    this.cargando = true;
    setTimeout(() => {
      this.dataSource.data = this.crmservice.direcciones.filter(d => this.objContainsVal(d, this.textoBusqueda));
      this.cargando = false;
    }, 650);
  }
  public seleccionarCliente(direccion): void {
    const popUpRef = this.crmservice.popUpConfirmacion({textoPrincipal: 'Asignar Cliente', textoSecundario: [direccion.razonSocial]});
    popUpRef.afterClosed().subscribe(value => {
      if (value) {
        this.dialogRef.close(direccion);
      }
    });
  }

  private objContainsVal (obj, val): boolean {
    for (const i in obj) {
      if (!obj.hasOwnProperty(i)) { continue; }
      if (typeof obj[i] === 'object') {
        if (this.objContainsVal(obj[i], val)) {
          return true;
        }
      } else if (obj[i].toString().toUpperCase().includes(val.toString().toUpperCase())) {
        return true;
      }
    }
    return false;
  }

}
