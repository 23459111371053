import {Component, Inject, Input, OnInit} from '@angular/core';
import {CrmService} from '../../../servicios/crm.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Usuario} from '../../../modelos/Usuario';
import {EditarUsuarioComponent} from '../../editar-usuario/editar-usuario.component';

@Component({
  selector: 'app-usuarios-equipo',
  templateUrl: './usuarios-equipo.component.html',
  styleUrls: ['./usuarios-equipo.component.css']
})
export class UsuariosEquipoComponent implements OnInit {

  public equipo: string;
  public usuarios: Usuario[];

  constructor(private crmservice: CrmService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<UsuariosEquipoComponent>,
              private dialog: MatDialog) {
    this.equipo = data.equipo || 'A';
    this.usuarios = this.crmservice.usuarios.filter(u => u.team === this.equipo && u.desactivado !== true);

    if (this.usuarios.length === 1) {
      this.dialogRef.close();
      this.verUsuario(this.usuarios[0]);
    }
  }

  ngOnInit(): void {}

  public verUsuario(usuario: Usuario): void {
    const dialogRef = this.dialog.open(EditarUsuarioComponent, {
      height: '700px',
      width: '800px',
      maxWidth: '100%',
      maxHeight: '100%',
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      data: {
        userID: usuario._id
      }
    });
    dialogRef.afterClosed().subscribe(usuarioEditar => {
      console.log(usuarioEditar);
      if (usuarioEditar != null) {
        const pos = this.crmservice.usuarios.findIndex(e => e._id === usuarioEditar._id);

        if (pos !== -1) {
          // Actualizar información más relevante (la que se vé en la tabla de usuarios).
          this.crmservice.usuarios[pos].nombre = usuarioEditar.nombre;
          this.crmservice.usuarios[pos].apellidos = usuarioEditar.apellidos;
          this.crmservice.usuarios[pos].poblacion = usuarioEditar.poblacion;
          this.crmservice.usuarios[pos].provincia = usuarioEditar.provincia;
          this.crmservice.usuarios[pos].telefono = usuarioEditar.telefono;
          this.crmservice.usuarios[pos].email = usuarioEditar.email;
          this.crmservice.usuarios[pos].team = usuarioEditar.team;
          this.crmservice.usuarios[pos].rol = usuarioEditar.rol;

          if (this.crmservice.usuarios[pos]._id === this.crmservice.userLogin._id) {
            // Si he editado mi propio perfil, actualizar también el userLogin del crmservice.
            this.crmservice.userLogin.nombre = usuarioEditar.nombre;
            this.crmservice.userLogin.apellidos = usuarioEditar.apellidos;
            this.crmservice.userLogin.poblacion = usuarioEditar.poblacion;
            this.crmservice.userLogin.provincia = usuarioEditar.provincia;
            this.crmservice.userLogin.telefono = usuarioEditar.telefono;
            this.crmservice.userLogin.email = usuarioEditar.email;
            this.crmservice.userLogin.team = usuarioEditar.team;
            this.crmservice.userLogin.rol = usuarioEditar.rol;
          }
        }
      }
    });
  }
}
